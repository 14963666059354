import { useCallback, useEffect, useState } from 'react'
import { ApplicationQuestionnaire } from './ScoringQuestion.tsx'

const useScoringConclusion = (questionnaire?: ApplicationQuestionnaire) => {
  const [conclusion, setConclusion] = useState('')

  useEffect(() => {
    if (questionnaire?.conclusion) {
      setConclusion(questionnaire.conclusion)
    }
  }, [questionnaire?.conclusion])

  const generateConclusion = useCallback(
    (data: any) => {
      let conclusionRaw: string

      if (data['судебные_и_лизинговые__были_ли_проверки_за_последние_12_месяцев']?.value) {
        conclusionRaw = `Были проверки со стороны: ${
          data['судебные_и_лизинговые__были_ли_проверки_за_последние_12_месяцев']?.['с_чем_связаны_проверки'] || ' '
        }, Результат: ${
          data['судебные_и_лизинговые__были_ли_проверки_за_последние_12_месяцев']?.['результат_проверки'] || ' '
        }.`
      } else {
        conclusionRaw = 'Проверок не было.'
      }

      if (data['судебные_и_лизинговые__применялись_ли_обеспечительные_меры']?.value) {
        conclusionRaw = `${conclusionRaw} Имеются действующие обеспечительные меры: ${
          data['судебные_и_лизинговые__применялись_ли_обеспечительные_меры']?.[
            'есть_действующие_обеспечительные_меры'
          ] || ' '
        }. ${
          data['судебные_и_лизинговые__применялись_ли_обеспечительные_меры']?.['какие_обеспечительные_меры'] || ' '
        }.`
      } else {
        conclusionRaw = `${conclusionRaw} Обеспечительные меры не применялись.`
      }

      if (data['налоговые__налоговые_правонарушения']?.value) {
        conclusionRaw = `${conclusionRaw} Были блокировки счета, связанные с: ${
          data['налоговые__налоговые_правонарушения']?.['с_чем_связаны_блокировки'] || ' '
        }.`
      } else {
        conclusionRaw = `${conclusionRaw} Блокировок счета не было.`
      }

      if (data['судебные_и_лизинговые__есть_сообщения_о_получении_лизинга']?.value) {
        conclusionRaw = `${conclusionRaw} Заключены ДЛ: ${
          data['судебные_и_лизинговые__есть_сообщения_о_получении_лизинга']?.['сколько_раз_компания_брала_лизинг'] ||
          ' '
        }. Предметы лизинга: ${
          data['судебные_и_лизинговые__есть_сообщения_о_получении_лизинга']?.['какие_предметы_лизинга'] || ' '
        }. Последний заключен: ${
          data['судебные_и_лизинговые__есть_сообщения_о_получении_лизинга']?.['когда_был_получен_последний_лизинг'] ||
          ' '
        }.`
      } else {
        conclusionRaw = `${conclusionRaw} ДЛ не заключались.`
      }

      if (data['судебные_и_лизинговые__есть_исполнительные_производства']?.value) {
        conclusionRaw = `${conclusionRaw} Открыты исполнительные производства: ${
          data['судебные_и_лизинговые__есть_исполнительные_производства']?.['количество_претензий'] || ' '
        } на ${
          data['судебные_и_лизинговые__есть_исполнительные_производства']?.['сумма_открытых_претензий'] || ' '
        } руб. Связанные с: ${
          data['судебные_и_лизинговые__есть_исполнительные_производства']?.['причины_претензий'] || ' '
        }.`
      } else {
        conclusionRaw = `${conclusionRaw} Исполнительные производства отсутствуют.`
      }

      if (data['судебные_и_лизинговые__есть_арбитражные_производства']?.value) {
        if (data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_ответчиком']?.value) {
          conclusionRaw = `${conclusionRaw} В арбитражах выступал ответчиком: ${
            data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_ответчиком']?.[
              'сколько_раз_выступало_ответчиком'
            ] || ' '
          } (${
            data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_ответчиком']?.[
              'с_чем_связаны_претензии'
            ] || ' '
          }), сумма ответственности по открытым арбитражам ${
            data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_ответчиком']?.[
              'макс.сумма_на_рассмотрении'
            ] || ' '
          } руб. Максимальная сумма ответственности по закрытым арбитражам ${
            data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_ответчиком']?.[
              'макс.сумма_завершенных'
            ] || ' '
          } руб.`
        } else {
          conclusionRaw = `${conclusionRaw}`
        }

        if (data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_истцом']?.value) {
          conclusionRaw = `${conclusionRaw} В арбитражах выступал истцом: ${
            data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_истцом']?.[
              'сколько_раз_выступало_истцом'
            ] || ' '
          } (${
            data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_истцом']?.[
              'с_чем_связаны_претензии'
            ] || ' '
          }), сумма ответственности по открытым арбитражам ${
            data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_истцом']?.[
              'макс.сумма_на_рассмотрении'
            ] || ' '
          } руб. Максимальная сумма ответственности по закрытым арбитражам ${
            data['судебные_и_лизинговые__есть_арбитражные_производства__юл_отвечало_истцом']?.[
              'макс.сумма_завершенных'
            ] || ' '
          } руб.`
        } else {
          conclusionRaw = `${conclusionRaw}`
        }
      } else {
        conclusionRaw = `${conclusionRaw} Арбитражей нет.`
      }

      if (data['налоговые__сведения_о_суммах_недоимки_по_пеням_и_штрафам']?.value) {
        conclusionRaw = `${conclusionRaw} Сведения о суммах недоимки и задолженности по пеням и штрафам ${
          data['налоговые__сведения_о_суммах_недоимки_по_пеням_и_штрафам']?.[''] || ' '
        } руб.`
      }

      if (data['налоговые__указать_среднесписочное_количество_сотрудников_организации']?.value) {
        conclusionRaw = `${conclusionRaw} Среднесписочное количество работников организации ${
          data['налоговые__указать_среднесписочное_количество_сотрудников_организации']?.[''] || ' '
        } человек.`
      }

      if (data['госконтракты__были_ли_госконтракты']?.value) {
        conclusionRaw = `${conclusionRaw} Были заключены госконтакты на общую сумму ${data['госконтракты__были_ли_госконтракты']?.['общая_сумма_госконтрактов'] || ''} руб., из которых активно на ${data['госконтракты__были_ли_госконтракты']?.['сумма_активных_госконтрактов'] || ''} руб., последний гос контракт от ${data['госконтракты__были_ли_госконтракты']?.['дата_последнего_заключения'] || ''}.`
        if (data['госконтракты__были_ли_пени/штрафы_во_время_исполнения']?.value) {
          conclusionRaw = `${conclusionRaw} Были допущены пени.`
        } else {
          conclusionRaw = `${conclusionRaw} Пеней не было.`
        }
      } else {
        conclusionRaw = `${conclusionRaw} Госконтрактов не заключалось.`
      }

      if (data['налоговые__специальный_налоговый_режим_не_применяется']?.value) {
        conclusionRaw = `${conclusionRaw} Упрощенная система налогообложения.`
      }

      setConclusion(conclusionRaw)
      return conclusionRaw
    },
    [setConclusion]
  )

  return [conclusion, setConclusion, generateConclusion] as const
}

export default useScoringConclusion
