import { FC } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useGuarantorByIdQuery } from '../../graphql/schema'
import CopyInn from '../../components/CopyInn'
import BoNalogLink from '../../components/BoNalogLink'
import {
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader'
import { EntityPage } from '../../components/EntityPage/EntityPage'

const Guarantor: FC = () => {
  const { id } = useParams<'id'>()
  const { data, loading, refetch } = useGuarantorByIdQuery({ variables: { id: `${id}` }, skip: !id })
  const guarantor = data?.guarantor

  if (!guarantor) return null

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='flex justify-between items-start mb-6'>
          <div>
            <EntityInfo>Компания-поручитель</EntityInfo>
            <div className='flex items-center mb-6'>
              <EntityName value={guarantor?.guarantorCompany?.shortWithOpf} loading={loading} />
              <CopyInn inn={guarantor?.guarantorCompany?.inn} />
            </div>
          </div>
          <div className='flex flex-none gap-x-4'>
            <BoNalogLink inn={guarantor?.guarantorCompany?.inn} />
          </div>
        </div>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Документы' to='docs' />
        </EntityNav>
      </EntityHeader>

      <EntityPage>
        <Outlet context={[data, refetch]} />
      </EntityPage>
    </div>
  )
}

export default Guarantor
