import { FC, useMemo, useState } from 'react'
import {
  CustomerAccountKind,
  useBankOperationPaymentInfoSuspenseQuery,
  useFeraBankAccountsSuspenseQuery
} from '../../graphql/schema'
import TransactionAmount from '../../components/TransactionAmount'
import { dateFormatter, dateTimeFormatter } from '../../utils/dateFormatter'
import c from 'clsx'
import { RadioGroup } from '@headlessui/react'
import useNodes from '../../hooks/useNodes'
import UserName from '../../components/UserName'
import { Link } from 'react-router-dom'

interface PaymentInfoProps {
  id: string
  onDone?: () => void
}

const PaymentInfo: FC<PaymentInfoProps> = ({ onDone, id }) => {
  const { data: readMoreData } = useBankOperationPaymentInfoSuspenseQuery({ variables: { id: id } })

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)

  const feraBankAccountKey = readMoreData.bankOperation.bankKey
  const feraBankAccountName = useMemo(
    () => feraBankAccounts?.find((account) => account?.bankKey?.toString() === feraBankAccountKey)?.name,
    [feraBankAccounts, feraBankAccountKey]
  )

  const options = ['Детали', 'Реквизиты']
  const [selected, setSelected] = useState(options[0])

  const isPenalty = readMoreData.bankOperation.customerAccount?.kind === CustomerAccountKind.Penalty

  return (
    <div className='md: w-[600px] p-12 flex flex-col gap-8'>
      <div className='flex justify-between leading-[23px]'>
        <div className='font-display text-h200'>Входящий платеж №{readMoreData.bankOperation.documentNumber}</div>
        <div className='text-grayscale-150'>{dateFormatter.format(new Date(readMoreData.bankOperation.date))}</div>
      </div>
      <div className='flex justify-center text-sm leading-6 text-grayscale-250'>
        Проведено по счету № {readMoreData.bankOperation.accountNumber}
      </div>
      <div className='flex justify-center mx-7 text-grayscale-150'>{readMoreData.bankOperation.payerName}</div>
      <TransactionAmount
        className='flex justify-center text-h100 mx-7 text-green-600'
        amount={readMoreData.bankOperation.amount}
        isIncome
        currency='₽'
      />
      <RadioGroup
        value={selected}
        onChange={setSelected}
        className='bg-grayscale-450 rounded-xl p-1 grid grid-flow-col auto-cols-fr'
      >
        <RadioGroup.Option value={options[0]}>
          <div
            className={c(
              'cursor-pointer text-center m-1 py-5 px-5 border-1 border-solid border-grayscale-450 rounded-xl',
              selected == options[0] ? 'bg-white-0 border-grayscale-350' : ''
            )}
          >
            Детали
          </div>
        </RadioGroup.Option>
        <RadioGroup.Option value={options[1]}>
          <div
            className={c(
              'cursor-pointer text-center m-1 py-5 px-5 border-1 border-solid border-grayscale-450 rounded-xl',
              selected == options[1] ? 'bg-white-0 border-grayscale-350' : ''
            )}
          >
            Платежи
          </div>
        </RadioGroup.Option>
      </RadioGroup>
      {selected == options[0] ? (
        <>
          <div className='leading-7 border-b-1 border-grayscale-350'>
            <div className='font-medium'>Назначение платежа</div>
            <div className='mb-4 text-grayscale-150'>{readMoreData.bankOperation.paymentPurpose}</div>
          </div>
          <table className='w-full'>
            <tbody>
              <tr className='group'>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-l-xl text-grayscale-150'>На балансе</td>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-r-xl text-right'>
                  <Link
                    to={`/customers/${readMoreData.bankOperation.customerAccount?.customerCompany.id}`}
                    className='hover:text-red-100'
                  >
                    {readMoreData.bankOperation.customerAccount?.customerCompany.shortWithOpf}
                  </Link>
                </td>
              </tr>
              <tr className='group'>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-l-xl text-grayscale-150'>Банк Fera</td>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-r-xl text-right'>{feraBankAccountName}</td>
              </tr>
              <tr className='group'>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-l-xl text-grayscale-150'>Разнесено</td>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-r-xl text-right'>
                  <UserName userId={parseInt(readMoreData.bankOperation.processedByUserId!)} />
                </td>
              </tr>
              <tr className='group'>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-l-xl text-grayscale-150'>
                  Дата разнесения
                </td>
                <td className='py-6 px-8  group-odd:bg-grayscale-450 rounded-r-xl text-right'>
                  {dateTimeFormatter.format(new Date(readMoreData.bankOperation.updatedAt))}
                </td>
              </tr>
              <tr className='group'>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-l-xl text-grayscale-150'>Вид платежа</td>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-r-xl text-right'>
                  {isPenalty ? 'Пени' : 'Лизинговый платеж'}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <>
          <div className='font-medium'>Реквизиты плательщика</div>
          <table className='w-full'>
            <tbody>
              <tr className='group'>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-l-xl text-grayscale-150'>Компания</td>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-r-xl text-right'>
                  {readMoreData.bankOperation.payerName}
                </td>
              </tr>
              <tr className='group'>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-l-xl text-grayscale-150'>ИНН/КПП</td>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-r-xl text-right'>
                  {readMoreData.bankOperation.payerInn} / {readMoreData.bankOperation.payerKpp}
                </td>
              </tr>
              <tr className='group'>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-l-xl text-grayscale-150'>Банк</td>
                <td className='py-6 px-8  group-odd:bg-grayscale-450 rounded-r-xl text-right'>
                  {readMoreData.bankOperation.payerBankName}
                </td>
              </tr>
              <tr className='group'>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-l-xl text-grayscale-150'>Счёт</td>
                <td className='py-6 px-8 group-odd:bg-grayscale-450 rounded-r-xl text-right'>
                  {readMoreData.bankOperation.payerAccountNumber}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      <button className='h-25 bg-grayscale-250 text-white-0 font-semibold rounded-lg hover:bg-red-150' onClick={onDone}>
        Закрыть
      </button>
    </div>
  )
}

export default PaymentInfo
