import * as React from 'react'
import { ReactComponent as YesIcon } from '../svg/icons/circleCheck.svg'
import { ReactComponent as NoIcon } from '../svg/icons/circleCross.svg'

interface ConfirmationProps {
  children: React.ReactNode
  onDone: () => void
  onDismiss: () => void
  title?: string
  yesText?: string
  noText?: string
}

const ConfirmationForm: React.FC<ConfirmationProps> = ({
  children,
  onDone = () => {},
  onDismiss = () => {},
  title,
  yesText,
  noText
}) => {
  return (
    <section className='md: w-[448px] p-12'>
      {title && <h1 className='font-display text-h200 mb-10'>{title}</h1>}
      <h1 className='text-p100 mb-18'>{children}</h1>
      <div className='flex gap-6'>
        <button
          onClick={() => onDone()}
          className='h-27 bg-grayscale-400 active:bg-grayscale-300 rounded-xl text-p200 font-semibold leading-full w-full overflow-hidden relative'
        >
          <YesIcon className='mr-5 inline' height='18px' width='18px' title='Да' />
          {yesText || 'Да'}
        </button>
        <button
          onClick={() => onDismiss()}
          className='h-27 bg-red-100 hover:bg-red-50 active:bg-red-150 rounded-xl text-white-0 text-p200 font-semibold leading-full w-full overflow-hidden relative'
        >
          <NoIcon className='mr-5 inline' height='18px' width='18px' title='Нет' />
          {noText || 'Нет'}
        </button>
      </div>
    </section>
  )
}

export default ConfirmationForm
