import {
  PaymentKind,
  PaymentTargetType,
  useCreatePaymentMutation,
  useDealPenaltyAmountSuspenseQuery,
  usePaymentByIdSuspenseQuery
} from '../../../graphql/schema.tsx'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import Input from '../../../components/Input.tsx'
import { SubmitHandler, useForm } from 'react-hook-form'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import { ApolloError } from '@apollo/client'
import MaskedInput from '../../../components/MaskedInput.tsx'
import parseDecimal from '../../../utils/parseDecimal.ts'
import SubmitButton from '../../../components/SubmitButton'
import { FC, useEffect } from 'react'
import { formatZero } from '../../../utils/contractUtils.ts'
import { formatDecimal } from '../../../utils/formatNumber.ts'

type Inputs = {
  date: string
  amount: string
  kind: PaymentKind
  targetId: string
}

interface PaymentFormProps {
  paymentId: string
  onDone?: () => void
}

const PenaltyForm: FC<PaymentFormProps> = ({ paymentId, onDone }) => {
  const [createPayment, { loading }] = useCreatePaymentMutation()
  const { data } = usePaymentByIdSuspenseQuery({
    variables: {
      id: paymentId
    }
  })

  const payment = data.payment

  const { data: penaltyData } = useDealPenaltyAmountSuspenseQuery({ variables: { paymentId: paymentId } })
  const penaltyAmount = penaltyData.penaltyAmountForPayment.amount

  const { register, handleSubmit, setError, watch, setValue } = useForm<Inputs>()
  const date = watch('date')

  useEffect(() => {
    if (!payment) return

    const targetDate = new Date(payment.date) > new Date() ? new Date(payment.date) : new Date()

    setValue('amount', formatDecimal(penaltyAmount * 100))
    if (!date) {
      setValue(
        'date',
        `${formatZero(targetDate.getFullYear())}-${formatZero(targetDate.getMonth() + 1)}-${formatZero(
          targetDate.getDate()
        )}`
      )
    }
  }, [payment, date])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (loading) return

    await createPayment({
      variables: {
        input: {
          date: data.date,
          amount: parseDecimal(data.amount.replace(/\s/g, '')),
          kind: PaymentKind.Penalty,
          targetType: PaymentTargetType.Payment,
          targetId: payment.id
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Новый платеж</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <Input label='Дата платежа' type='date' {...register('date', { required: true })} />
          <MaskedInput
            label='Сумма'
            placeholder='15000'
            inputMode='decimal'
            mask='number'
            onAccept={(value) => setValue('amount', value)}
            {...register('amount', { required: true, min: 0 })}
          />
        </div>
        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Создать
        </SubmitButton>
      </form>
    </section>
  )
}

export default PenaltyForm
