import {
  Kind,
  ReconciliationCustomerStatus,
  ReconciliationFeraStatus,
  useAddDealMutation,
  useAddDealApplicationDataQuery
} from '../../graphql/schema'
import { useCallback } from 'react'
import { REGULAR_VAT_RATE } from '../../utils/constants'
import useNodes from '../../hooks/useNodes'
import limitFloat from '../../utils/limitFloat'

export interface AddDealReconciliation {
  _id: number
  amount: number
  advanceRate: number
  comissionRate: number
  durationMonths: number
  customerStatus: ReconciliationCustomerStatus
  feraStatus: ReconciliationFeraStatus
  insuranceRate: number
  interestRate: number
  kind: Kind
  vatRate: number
}

const useAddDeal = (applicationId?: number, userId?: number) => {
  const { data: reconciliationsData } = useAddDealApplicationDataQuery({
    variables: { id: applicationId?.toString() as string },
    skip: !applicationId
  })

  const reconciliations = useNodes(reconciliationsData?.application?.reconciliations?.edges)

  const acceptedRecs = reconciliations.filter(
    (rec) =>
      rec?.feraStatus === ReconciliationFeraStatus.Accepted &&
      rec?.customerStatus === ReconciliationCustomerStatus.Accepted &&
      rec?.feraAcceptExpiresAt &&
      new Date() <= new Date(rec?.feraAcceptExpiresAt)
  )

  const [addDeal] = useAddDealMutation()

  const addDealFromRec = useCallback(
    async (rec: AddDealReconciliation) => {
      if (!rec || !applicationId) return

      return await addDeal({
        variables: {
          input: {
            amount: rec?.amount,
            advanceRate: limitFloat(rec?.advanceRate),
            application: applicationId.toString(),
            comissionRate: limitFloat(rec?.comissionRate),
            customerCompany: reconciliationsData?.application?.customerCompany?._id.toString() || '',
            durationMonths: rec?.durationMonths,
            insuranceAmount: Math.round(
              (rec?.insuranceRate / 100) * rec?.amount * Math.max(rec?.durationMonths / 12, 1)
            ),
            interestRate: limitFloat(rec?.interestRate),
            leasingSubjectCategories: reconciliationsData?.application?.leasingSubjectCategories?.edges?.map((a) =>
              a?.node?._id.toString()
            ),
            kind: rec?.kind,
            vatRate: rec?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
            user: userId?.toString(),
            sourceReconciliation: rec._id.toString()
          }
        }
      })
    },
    [applicationId, userId, addDeal, reconciliationsData]
  )

  return { addDealFromRec, acceptedRecs } as const
}

export default useAddDeal
