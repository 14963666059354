import { FC, useMemo, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as DealIcon } from '../../svg/icons/deal.svg'
import { ReactComponent as AmoCrmIcon } from '../../svg/icons/amocrm.svg'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as WarningIcon } from '../../svg/icons/warning.svg'
import {
  ApplicationReconciliationsStatus,
  useApplicationByIdQuery,
  useDealByIdLazyQuery,
  useUpdateApplicationMutation
} from '../../graphql/schema'
import confetti from '../../utils/confetti'
import { showPopup } from '../../components/Toaster/showPopup.tsx'
import Modal from '../../components/Modal'
import Tooltip from '../../components/Tooltip'
import ApplicationForm from '../../components/Forms/ApplicationForm'
import {
  AppStatus,
  ApplicationStatusCircle,
  statusColorDict,
  statusTextColorDict
} from '../../components/ApplicationStatus'
import { env } from '../../env'
import getFullName from '../../utils/getFullName'
import type { AddDealReconciliation } from './useAddDeal'
import useAddDeal from './useAddDeal'
import RecSelectForm from './RecSelectForm'
import CopyInn from '../../components/CopyInn'
import Placeholder from '../../components/Placeholder'
import NestedSelectInput, { NestedSelectOption } from '../../components/NestedSelectInput'
import c from 'clsx'
import useAccessCheck from '../../hooks/useAccessCheck.ts'
import { LOSS_REASON_GROUP } from '../../utils/constants.ts'
import PhoneNumber from '../../components/PhoneNumber.tsx'
import {
  EntityBar,
  EntityBarItem,
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader.tsx'
import Avatar from '../../components/Avatar/index.tsx'
import PrimaryButtonLink from '../../components/UI/PrimaryButtonLink.tsx'
import PrimaryButton from '../../components/UI/PrimaryButton.tsx'
import { EntityPage } from '../../components/EntityPage/EntityPage.tsx'
// import { AccreditationStatus } from '../../graphql/schema'

const API_URL = env.REACT_APP_API_URL
if (!API_URL) throw new Error('REACT_APP_API_URL is not defined')

const DEAL_ALLOWED_STATUSES: string[] = [
  'Скоринг',
  'Подготовка к сделке',
  'Подписание договора (Backoffice)',
  'Активация договора (Backoffice)',
  'Оплата платежей (Backoffice)'
]

const LIST_STATUSES: AppStatus[] = Object.values(AppStatus)

const LOSS_REASON_OPTIONS = LOSS_REASON_GROUP.flatMap((g) => g.children)

const LossReason: FC<{ reason: string; active: boolean }> = ({ active, reason }) => (
  <div className='flex cursor-pointer items-center gap-5 px-9 py-5'>
    <div>
      <div
        className={c('h-10 w-10 rounded-full border-1 border-grayscale-300', active && 'border-[7px] border-red-100')}
      />
    </div>
    <div className='text-left font-medium'>{reason}</div>
  </div>
)

const Application: FC = () => {
  const navigate = useNavigate()

  const { id } = useParams<'id'>()
  const [editAppId, setEditAppId] = useState<number | undefined>()
  const formOpen = editAppId !== undefined
  const [recSelectFormOpen, setRecSelectFormOpen] = useState(false)

  const hasAccessScoringView = useAccessCheck('application.scoring.view')
  const hasAcessApplicationStatusFromClosedManage = useAccessCheck('application.status_closed.manage')
  console.log(hasAcessApplicationStatusFromClosedManage)

  const {
    data,
    refetch,
    loading: appLoading
  } = useApplicationByIdQuery({
    variables: { id: id?.toString() as string },
    skip: !id,
    returnPartialData: true
  })
  const [loadDeal] = useDealByIdLazyQuery({ variables: { id: id?.toString() as string } })

  const app = data?.application
  const user = app?.user

  const { addDealFromRec: addDeal, acceptedRecs } = useAddDeal(app?._id, app?.user?._id)

  const [updateApplication, { loading }] = useUpdateApplicationMutation()

  const addLossReason = async (lossReason: string) => {
    await updateApplication({
      variables: {
        input: {
          id: app?._id.toString() as string,
          lossReason
        }
      }
    }).then(
      () => {
        changeStatus(AppStatus.Closed)
      },
      () => {
        showPopup({
          title: 'Ошибка',
          subtitle: 'Не удалось добавить причину закрытия заявки'
        })
      }
    )
  }

  const changeStatus = async (status: string) => {
    if (!app) return
    await updateApplication({
      variables: {
        input: {
          id: app?._id.toString(),
          status
        }
      }
    }).then(
      () => {
        refetch()
      },
      () => {
        showPopup({
          title: 'Ошибка',
          subtitle: 'Не удалось изменить статус заявки'
        })
      }
    )
  }

  if (!app) return null

  const statusAllowsCreation = DEAL_ALLOWED_STATUSES.includes(app?.status || '')
  const hasAcceptedReconciliations = acceptedRecs?.length > 0
  const customer = data.application?.customerCompany
  const edoSpecified =
    customer?.edoStatus === false ||
    (customer?.edoStatus === true && (!!customer?.edoTypes?.totalCount || !!customer?.edoCustomValue))
  const canCreateDeal = statusAllowsCreation && hasAcceptedReconciliations && edoSpecified
  const existingDealId = data.application?.deal?._id
  const notReadyScoringStatus = app?.reconciliationsStatus !== ApplicationReconciliationsStatus.Negotiations
  const oldAppWithAmo = data.application?.created && new Date('2023-04-28') > new Date(data.application.created)
  // const supplierCompanies = useNodes(app?.supplierCompanies?.edges)
  // const notAcceptedSupplierCompany = Boolean(
  //   supplierCompanies.find((o) => o.accreditationStatus !== AccreditationStatus.Accepted)
  // )
  // const notExistSupplierCompanies = !supplierCompanies.length
  // const notReadyDealStatus = !notReadyScoringStatus && (notAcceptedSupplierCompany || notExistSupplierCompanies)

  const options: NestedSelectOption[] = useMemo(
    () =>
      LIST_STATUSES.map((status) =>
        status === AppStatus.Closed
          ? {
              name: status,
              node: (
                <div
                  className={c(
                    'flex h-16 cursor-pointer items-center gap-5 px-5',
                    status === app?.status && 'text-base-red',
                    existingDealId && 'pointer-events-none opacity-30'
                  )}
                >
                  <ApplicationStatusCircle status={status} />
                  <div className='whitespace-nowrap'>{status}</div>
                </div>
              ),
              disabled: !!existingDealId,
              children: LOSS_REASON_GROUP.map((group) => ({
                name: group.name,
                node: (
                  <div
                    className={c(
                      'flex h-16 cursor-pointer items-center gap-5 px-5',
                      app.status === AppStatus.Closed &&
                        app?.lossReason &&
                        group.children.includes(app.lossReason) &&
                        'text-base-red'
                    )}
                  >
                    {group.name}
                  </div>
                ),
                children: group.children.map((lossReason) => ({
                  name: lossReason,
                  node: (
                    <LossReason
                      reason={lossReason}
                      active={app.status === AppStatus.Closed && app?.lossReason === lossReason}
                    />
                  )
                }))
              }))
            }
          : {
              name: status,
              node: (
                <div
                  className={c(
                    'flex h-16 cursor-pointer items-center gap-5 px-5',
                    status === app?.status && 'pointer-events-none text-base-red',
                    status === AppStatus.Scoring && notReadyScoringStatus && 'cursor-auto hover:bg-white-0',
                    status === AppStatus.DealPreparation && notReadyScoringStatus && 'pointer-events-none opacity-30',
                    status === AppStatus.Deal && notReadyScoringStatus && 'pointer-events-none opacity-30',
                    status !== AppStatus.Deal && existingDealId && 'pointer-events-none opacity-30',
                    app.status === AppStatus.Closed &&
                      !hasAcessApplicationStatusFromClosedManage &&
                      'pointer-events-none opacity-30'
                    // status === AppStatus.Deal && notReadyDealStatus && 'cursor-auto hover:bg-white-0'
                  )}
                >
                  <ApplicationStatusCircle status={status} />
                  <div className='whitespace-nowrap'>{status}</div>
                  {status === AppStatus.Scoring && notReadyScoringStatus && (
                    <Tooltip className='ml-auto' target={<WarningIcon className='text-base-red' />}>
                      <div className='mb-2 font-display font-medium'>Чтобы продолжить, нужно:</div>
                      <ul className='list-inside list-disc'>
                        {notReadyScoringStatus && <li>Добавить согласования в работу</li>}
                      </ul>
                    </Tooltip>
                  )}
                  {status === AppStatus.Deal &&
                    app?.status !== AppStatus.Deal &&
                    !notReadyScoringStatus &&
                    !canCreateDeal && (
                      <Tooltip className='ml-auto' target={<WarningIcon className='text-base-red' />}>
                        <div className='mb-2 font-display font-medium'>Чтобы продолжить, нужно:</div>
                        <ul className='list-inside list-disc'>
                          {!hasAcceptedReconciliations && <li>Согласовать условия</li>}
                          {!edoSpecified && <li>Указать ЭДО</li>}
                          {!statusAllowsCreation && <li>Повысить статус</li>}
                        </ul>
                      </Tooltip>
                    )}
                </div>
              ),
              disabled:
                (status !== AppStatus.Deal && Boolean(existingDealId)) ||
                status === app?.status ||
                (status === AppStatus.Scoring && notReadyScoringStatus) ||
                (status === AppStatus.DealPreparation && notReadyScoringStatus) ||
                (status === AppStatus.Deal && (notReadyScoringStatus || !canCreateDeal)) ||
                (app.status === AppStatus.Closed && !hasAcessApplicationStatusFromClosedManage)
            }
      ),
    [
      app?.status,
      app?.lossReason,
      canCreateDeal,
      notReadyScoringStatus,
      // notReadyDealStatus,
      // notAcceptedSupplierCompany,
      // notExistSupplierCompanies,
      LOSS_REASON_GROUP
    ]
  )

  const handleRecSelect = async (rec: AddDealReconciliation) => {
    const result = await addDeal(rec)
    const dealId = result?.data?.addDeal?.deal?._id
    await changeStatus(AppStatus.Deal)

    // preload deal before route change for immediate render
    await loadDeal()
    navigate(`/deals/${dealId}`)
    await confetti()
  }

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='flex items-start justify-between'>
          <div>
            <EntityInfo>
              <div className='mr-6'>Заявка №{id}</div>
              {user && (
                <>
                  <Avatar width='w-8' height='h-8' className='mr-3' userId={user._id} />
                  {getFullName(user)}
                </>
              )}
            </EntityInfo>
            <div className='mb-6 flex items-center'>
              <EntityName
                value={app?.customerCompany?.shortWithOpf}
                loading={appLoading}
                to={`/customers/${app?.customerCompany?._id}`}
              />
              <CopyInn inn={app?.customerCompany?.inn} />
            </div>
          </div>
          <div className='flex flex-none gap-x-4'>
            {!!app && (
              <PrimaryButton onClick={() => setEditAppId(app._id)}>
                <EditIcon title='Редактировать' />
                Редактировать
              </PrimaryButton>
            )}
            {existingDealId && (
              <PrimaryButtonLink to={`/deals/${existingDealId}`}>
                <DealIcon />
                Открыть сделку №{existingDealId}
              </PrimaryButtonLink>
            )}
          </div>
        </div>
        <div className='mb-12 flex gap-x-4'>
          <NestedSelectInput
            className={c(
              'flex min-h-16 items-center gap-x-3 rounded-md px-5 py-3',
              app?.status ? statusColorDict[app?.status as AppStatus] : 'bg-surface-secondary',
              app?.status ? statusTextColorDict[app?.status as AppStatus] : 'text-labels-secondary'
            )}
            loading={loading}
            options={options}
            onSelect={async (value: string) => {
              if (LOSS_REASON_OPTIONS.includes(value)) {
                await addLossReason(value)
              } else if (value === AppStatus.Deal && !existingDealId) {
                if (acceptedRecs?.length === 1) {
                  // short path for single accepted reconciliation
                  handleRecSelect(acceptedRecs[0])
                } else {
                  // long path where user selects reconciliation
                  setRecSelectFormOpen(true)
                }
              } else {
                await changeStatus(value)
              }
            }}
          >
            <ApplicationStatusCircle status={app?.status || 'Укажите статус'} bg={true} />
            <div className='whitespace-nowrap'>
              {appLoading && !app?.status ? <Placeholder text='Какой-то статус' /> : app?.status || 'Укажите статус'}
            </div>
          </NestedSelectInput>
          {app.status === AppStatus.Closed && (
            <div className='rounded-md bg-tr-red px-5 py-3 font-medium text-dark-red'>{app.lossReason}</div>
          )}
        </div>
        <EntityBar>
          <EntityBarItem title='Имя' value={app?.fio} loading={appLoading} />
          <EntityBarItem title='Email' value={app?.email} loading={appLoading} />
          <EntityBarItem title='Телефон' loading={appLoading}>
            <PhoneNumber number={app?.phone} />
          </EntityBarItem>
        </EntityBar>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Поставщики' to='suppliers' />
          {hasAccessScoringView && <EntityNavItem title='Скоринг' to='scoring' />}
          <EntityNavItem title='Согласования' to='reconciliations' />
          <EntityNavItem title='Документы' to='docs' />
          <EntityNavItem title='Контакты' to='contacts' />
          {oldAppWithAmo && (
            <a
              className='flex h-full cursor-pointer items-center pb-8 text-body-m font-medium hover:text-base-red aria-current:border-b-2 aria-current:text-base-red'
              target='_blank'
              rel='noreferrer'
              href={`https://fera.amocrm.ru/leads/detail/${app.amoCrmId}`}
            >
              <AmoCrmIcon />
              <span className='ml-4 font-medium'>Открыть в AmoCRM</span>
            </a>
          )}
        </EntityNav>
      </EntityHeader>

      <EntityPage>
        <Outlet context={[data, refetch, setEditAppId]} />
      </EntityPage>

      <Modal open={recSelectFormOpen} setOpen={setRecSelectFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <RecSelectForm recs={acceptedRecs} onDone={handleRecSelect} />
        </div>
      </Modal>

      <Modal open={formOpen} setOpen={() => setEditAppId(undefined)}>
        <div className='absolute top-50 z-10 rounded-xl bg-white-0'>
          <ApplicationForm
            onDone={() => {
              refetch()
              setEditAppId(undefined)
            }}
            id={editAppId}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Application
