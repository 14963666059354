import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import SubmitButton from '../../../components/SubmitButton'
import { UserRoles, useUpdateScoringProcessMutation } from '../../../graphql/schema.tsx'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import ManagerInput from '../../../components/Forms/Inputs/ManagerInput.tsx'

type Inputs = {
  applicationId: number
  risksUserId: number
}

interface ScoringProcessRisksUserFormProps {
  applicationId: number
  userId?: number
  onDone?: () => void
}

const ScoringProcessRisksUserForm: FC<ScoringProcessRisksUserFormProps> = ({ applicationId, userId, onDone }) => {
  const [updateScoringProcess, { loading }] = useUpdateScoringProcessMutation()

  const { handleSubmit, control, setError, formState } = useForm<Inputs>({
    defaultValues: {
      risksUserId: userId
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await updateScoringProcess({
      variables: {
        input: {
          applicationId,
          risksUserId: data?.risksUserId
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Менеджеры Fera</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <Controller
            name='risksUserId'
            control={control}
            render={({ field }) => (
              <ManagerInput
                selected={field.value ? [field.value] : []}
                onChange={(selected) =>
                  selected.length ? field.onChange(selected[selected.length - 1]) : field.onChange(null)
                }
                label='Менеджер отдела скоринга'
                placeholder='Выберите менеджера'
                error={formState.errors?.risksUserId}
                role={UserRoles.RoleRisks}
              />
            )}
          />
        </div>
        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default ScoringProcessRisksUserForm
