// format date with short day, month and year
export const shortDateTimeFormatter = Intl.DateTimeFormat('ru-RU', {
  day: 'numeric',
  month: '2-digit',
  year: '2-digit',
  hour: 'numeric',
  minute: 'numeric'
})
export const dateTimeFormatter = Intl.DateTimeFormat('ru-RU', {
  dateStyle: 'short',
  timeStyle: 'short'
})
export const dateFormatter = Intl.DateTimeFormat('ru-RU', {
  dateStyle: 'short'
})
export const timeFormatter = Intl.DateTimeFormat('ru-RU', {
  timeStyle: 'short'
})
export const momFormatter = Intl.DateTimeFormat('ru-RU', {
  timeStyle: 'short'
})
// format date for date input
export const getCalendarFormatDate = (date?: Date) => {
  return date?.toISOString().split('T').shift() || ''
}
const _dashedDateFormatter = Intl.DateTimeFormat('de', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
})
export const dashedDateFormat = (date: Date) => {
  return _dashedDateFormatter.format(date).replace(/\./g, '-')
}

export const ISODateToCalendar = (date?: string) => {
  return date?.split('T').shift() || ''
}

const ruCardinalRules = new Intl.PluralRules('ru-RU')
export const addMonthSuffix = (months: number) => {
  switch (ruCardinalRules.select(months)) {
    case 'one':
      return months + ' месяц'
    case 'few':
      return months + ' месяца'
    case 'many':
      return months + ' месяцев'
    default:
      return months
  }
}
export const addYearSuffix = (years: number) => {
  switch (ruCardinalRules.select(years)) {
    case 'one':
      return years + ' год'
    case 'few':
      return years + ' года'
    case 'many':
      return years + ' лет'
    default:
      return years
  }
}
