import { FC, useEffect, useMemo, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import { ReactComponent as WarningIcon } from '../../../svg/ui/warning.svg'
import { ReactComponent as RefreshIcon } from '../../../svg/ui/refresh.svg'
import {
  useBoVerificationsQuery,
  AttachmentCategory,
  useInnByPassportQuery,
  useDataProcessingConsentDateQuery,
  useDocumentsQuery,
  DocumentTypeEntity,
  useRefreshInnMutation
} from '../../../graphql/schema'
import { downloadFile } from '../../../utils/downloadFile'
import { APIVerificationRoot } from '../../../utils/APIRoot'
import { formatPassportNumber } from '../../../utils/formatPassportNumber'
import { getDateFormattedString } from '../../../utils/contractUtils'
import { formatPassportCode } from '../../../utils/formatPassportCode'
import { educationDict, maritalStatusDict } from '../../../utils/verification'
import Modal from '../../../components/Modal'
import EditPersonInfoForm from './EditPersonInfoForm'
import EditAddressForm from './EditAddressForm'
import EditQuestionnaireForm from './EditQuestionnaireForm'
import CheckResult from './CheckResult'
import PersonCheckFinmon from '../../../components/Finmon/Person/PersonCheckFinmon'
import Tooltip from '../../../components/Tooltip'
import Document, { IDocument } from '../../../components/DocumentManager/Document'
import { formatMoney } from '../../../utils/formatNumber'
import DataProcessingConsent from './DataProcessingConsent.tsx'
import useNodes from '../../../hooks/useNodes.ts'
import Geolocation from './Geolocation.tsx'
import DuplicateContactsTable from './DuplicateContactsTable.tsx'
import useCheckPassportExpiry from '../../../hooks/useCheckPassportExpiry.ts'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import c from 'clsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../../components/Card.tsx'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'

const ContactKYC: FC = () => {
  const { id } = useParams<'id'>()
  const context = useOutletContext<[() => void]>()

  const [selfieUrl, updateSelfieUrl] = useState<string>()
  const [personInfoFormOpen, setPersonInfoFormOpen] = useState(false)
  const [addressFormOpen, setAddressFormOpen] = useState(false)
  const [questionnaireFormOpen, setQuestionnaireFormOpen] = useState(false)

  const hasAccessEditContactAfterAccepted = useAccessCheck('contact.edit_after_accepted')

  const { data, refetch } = useBoVerificationsQuery({
    variables: { contactId: parseInt(id!), states: ['completed', 'rejected', 'accepted'] },
    skip: !id
  })
  const boVerification = data?.boVerifications[0]

  const checkPassportExpiryResult = useCheckPassportExpiry(
    boVerification?.personInfo?.birthDate,
    boVerification?.personInfo?.passportIssueDate
  )

  const {
    data: innData,
    loading: loadingInn,
    error: errorInn,
    refetch: refetchInn
  } = useInnByPassportQuery({
    variables: {
      input: {
        firstName: boVerification?.personInfo?.firstName || '',
        lastName: boVerification?.personInfo?.lastName || '',
        middleName: boVerification?.personInfo?.middleName || '',
        birthDate: getDateFormattedString(new Date(boVerification?.personInfo?.birthDate)) || '',
        passport: boVerification?.personInfo?.passportSeriesAndNumber || '',
        passportIssueDate: getDateFormattedString(new Date(boVerification?.personInfo?.passportIssueDate)) || ''
      }
    },
    skip: !boVerification
  })
  const inn = innData?.inn

  const { data: contactData } = useDataProcessingConsentDateQuery({
    variables: { id: id?.toString() as string },
    skip: !id
  })
  const dataProcessingConsentDate = contactData?.contact?.dataProcessingConsentDate

  const { data: documentsData } = useDocumentsQuery({
    variables: {
      filter: {
        entityId: id as string,
        entityType: DocumentTypeEntity.Contact,
        type: 'Согласие на обработку персональных данных'
      }
    },
    skip: !id
  })
  const documents = useNodes(documentsData?.documents?.edges)
  const canAccept = !!dataProcessingConsentDate && !!documents?.length
  const ageDiff = Math.abs(new Date().getTime() - new Date(boVerification?.personInfo?.birthDate).getTime())
  const contactAge = Math.floor(ageDiff / (1000 * 3600 * 24 * 365))

  useEffect(() => {
    let selfieObjectUrl: string
    const selfie = boVerification?.attachments?.find((i) => i.category === AttachmentCategory.Selfie)

    if (selfie) {
      downloadFile(APIVerificationRoot + `/verifications/attachments/${selfie?.id}/get-file`).then((blob) => {
        selfieObjectUrl = URL.createObjectURL(blob)
        updateSelfieUrl(selfieObjectUrl)
      })
    }

    return function cleanup() {
      if (selfieObjectUrl) {
        URL.revokeObjectURL(selfieObjectUrl)
      }
    }
  }, [boVerification?.attachments])

  const attachments: (IDocument & { category?: AttachmentCategory })[] = useMemo(
    () =>
      boVerification?.attachments?.map((a) => ({
        _id: parseInt(a.id),
        created: a.createdDate,
        filePath: `/verifications/attachments/${a.id}/get-file`,
        originalName: a.filename,
        mimetype: a.mimetype,
        loading: false,
        category: a.category
      })) || [],
    [boVerification?.attachments]
  )

  const [refreshInnMutation, { loading: refreshInnLoading }] = useRefreshInnMutation()

  const refreshInn = async () => {
    await refreshInnMutation({
      variables: {
        input: {
          firstName: boVerification?.personInfo?.firstName || '',
          lastName: boVerification?.personInfo?.lastName || '',
          middleName: boVerification?.personInfo?.middleName || '',
          birthDate: getDateFormattedString(new Date(boVerification?.personInfo?.birthDate)) || '',
          passport: boVerification?.personInfo?.passportSeriesAndNumber || '',
          passportIssueDate: getDateFormattedString(new Date(boVerification?.personInfo?.passportIssueDate)) || ''
        }
      }
    })
    refetchInn()
  }

  const canEdit = boVerification?.state === 'ACCEPTED' ? hasAccessEditContactAfterAccepted : true

  if (!id || !boVerification) return null

  return (
    <div className='grid grid-cols-2 gap-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Паспорт РФ</CardTitle>
            {canEdit && (
              <CardMenu>
                <CardIconButton onClick={() => setPersonInfoFormOpen(true)}>
                  <EditIcon />
                </CardIconButton>
              </CardMenu>
            )}
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <div className='mb-8 flex px-4 pt-10'>
                <div className='ml-10 h-120 w-95 bg-surface-secondary'>
                  {selfieUrl && <img className='h-full w-full object-cover' alt='Селфи' src={selfieUrl} />}
                </div>
                <div className='ml-10 grid flex-grow grid-cols-2 gap-y-4'>
                  <div>
                    <div className='text-sm text-labels-secondary'>Фамилия</div>
                    <div>{boVerification?.personInfo?.lastName}</div>
                  </div>
                  <div>
                    <div className='text-sm text-labels-secondary'>Серия / Номер</div>
                    {boVerification?.personInfo?.passportSeriesAndNumber && (
                      <div>{formatPassportNumber(boVerification?.personInfo?.passportSeriesAndNumber || '')}</div>
                    )}
                  </div>
                  <div>
                    <div className='text-sm text-labels-secondary'>Имя</div>
                    <div>{boVerification?.personInfo?.firstName}</div>
                  </div>
                  <div>
                    <div className='text-sm text-labels-secondary'>Пол</div>
                    <div>{boVerification?.personInfo?.gender && boVerification?.personInfo.gender[0]}</div>
                  </div>
                  <div>
                    <div className='text-sm text-labels-secondary'>Отчество</div>
                    <div>{boVerification?.personInfo?.middleName}</div>
                  </div>
                  <div>
                    <div className='text-sm text-labels-secondary'>Дата рождения</div>
                    <div>
                      {getDateFormattedString(new Date(boVerification?.personInfo?.birthDate))} ({contactAge})
                    </div>
                  </div>
                  <div>
                    <div className='text-sm text-labels-secondary'>Код подразделения</div>
                    <div>{formatPassportCode(boVerification?.personInfo?.passportIssueId || '')}</div>
                  </div>
                  <div>
                    <div className='text-sm text-labels-secondary'>Дата выдачи</div>
                    <div className='flex items-center gap-3'>
                      <div>{getDateFormattedString(new Date(boVerification?.personInfo?.passportIssueDate))}</div>
                      {checkPassportExpiryResult && (
                        <Tooltip target={<WarningIcon className='text-red-100' />}>{checkPassportExpiryResult}</Tooltip>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className='flex items-center gap-2'>
                      <div className='text-sm text-labels-secondary'>ИНН</div>
                      <button
                        disabled={loadingInn || refreshInnLoading}
                        className='text-grayscale-200 hover:text-red-100/70'
                        onClick={refreshInn}
                      >
                        <RefreshIcon className={c((loadingInn || refreshInnLoading) && 'animate-spin-reverse')} />
                      </button>
                    </div>
                    <div className='flex justify-start'>
                      {loadingInn ? (
                        <Tooltip target={<div className='h-7 w-7 rounded-full bg-yellow-400' />}>Идет проверка</Tooltip>
                      ) : errorInn ? (
                        <Tooltip target={<WarningIcon className='text-red-100' height='20px' width='20px' />}>
                          Недостаточно данных
                        </Tooltip>
                      ) : (
                        inn?.inn || (
                          <Tooltip target={<div className='h-7 w-7 rounded-full bg-red-100' />}>ИНН не найден</Tooltip>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-8 px-14'>
                <div className='text-sm text-labels-secondary'>Кем выдан</div>
                <div>{boVerification?.personInfo?.passportIssuedBy}</div>
              </div>
              <div className='mb-8 px-14'>
                <div className='text-sm text-labels-secondary'>Место рождения</div>
                <div>{boVerification?.personInfo?.birthPlace}</div>
              </div>
              {!!attachments?.length && (
                <div className='grid grid-cols-2 gap-x-20 gap-y-12 border-t-1 border-grayscale-400 px-14 py-12 tabular-nums'>
                  {attachments.map((doc) => (
                    <Document
                      key={doc._id}
                      doc={doc}
                      apiRoot={APIVerificationRoot}
                      previewRequestType='GET'
                      downloadRequestType='GET'
                      mimetype={doc.mimetype}
                      canDelete={false}
                      canDownload={true}
                      canPreview={true}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Адреса</CardTitle>
            {canEdit && (
              <CardMenu>
                <CardIconButton onClick={() => setAddressFormOpen(true)}>
                  <EditIcon />
                </CardIconButton>
              </CardMenu>
            )}
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>
                      Адрес регистрации
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {boVerification?.personInfo?.regAddress}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>
                      Адрес проживания
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {boVerification?.personInfo?.regAddressSameAsFact
                        ? boVerification?.personInfo?.regAddress
                        : boVerification?.personInfo?.factAdress}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        <Geolocation coordinates={boVerification?.geolocationData} />

        <DuplicateContactsTable id={id} />
      </div>

      <div className='col-span-1 flex flex-col gap-6'>
        <CheckResult
          boVerification={boVerification}
          canAccept={canAccept}
          onDone={() => {
            context[0]()
            refetch()
          }}
        />

        <Card>
          <CardHeader>
            <CardTitle>Данные из верификации</CardTitle>
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>Email</td>
                    <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {boVerification?.questionnaire?.email}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>Телефон</td>
                    <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {boVerification?.confirmedPhone}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Опросник для поручительства</CardTitle>
            {canEdit && (
              <CardMenu>
                <CardIconButton onClick={() => setQuestionnaireFormOpen(true)}>
                  <EditIcon />
                </CardIconButton>
              </CardMenu>
            )}
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>
                      Согласие поручителя
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {boVerification?.questionnaire?.isGuarantee ? 'Да' : 'Нет'}
                    </td>
                  </tr>
                  {boVerification?.questionnaire?.isGuarantee && (
                    <>
                      <tr className='group border-t-1 border-grayscale-400'>
                        <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>
                          Биометрический загранпаспорт и NFC
                        </td>
                        <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                          {boVerification?.questionnaire?.hasNFC ? 'Да' : 'Нет'}
                        </td>
                      </tr>
                      <tr className='group border-t-1 border-grayscale-400'>
                        <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>Доходы</td>
                        <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                          {boVerification?.questionnaire?.income && formatMoney(boVerification?.questionnaire?.income)}
                        </td>
                      </tr>
                      <tr className='group border-t-1 border-grayscale-400'>
                        <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>
                          Образование
                        </td>
                        <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                          {boVerification?.questionnaire?.education &&
                            educationDict[boVerification?.questionnaire?.education]}
                        </td>
                      </tr>
                      <tr className='group border-t-1 border-grayscale-400'>
                        <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>
                          Семейное положение
                        </td>
                        <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                          {boVerification?.questionnaire?.maritalStatus &&
                            maritalStatusDict[boVerification?.questionnaire?.maritalStatus]}
                        </td>
                      </tr>
                      <tr className='group border-t-1 border-grayscale-400'>
                        <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>
                          Несовершеннолетних детей
                        </td>
                        <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                          {boVerification?.questionnaire?.infantsCount}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>
                      Должностное лицо
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {boVerification?.questionnaire?.isExecutive ? 'Да' : 'Нет'}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-8 py-6 text-labels-secondary first:rounded-l-md last:rounded-r-md'>
                      Родственник должностного лица
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {boVerification?.questionnaire?.isRelativeToExecutive ? 'Да' : 'Нет'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        <DataProcessingConsent
          contactId={id}
          canEdit={canEdit}
          date={dataProcessingConsentDate}
          documents={documents}
        />

        <PersonCheckFinmon boVerification={boVerification} />
      </div>

      <SuspenseModal open={personInfoFormOpen} setOpen={setPersonInfoFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <EditPersonInfoForm
            uuid={boVerification.uuid}
            personInfo={boVerification?.personInfo!}
            onDone={() => {
              setPersonInfoFormOpen(false)
              refetch()
            }}
            attachments={attachments}
          />
        </div>
      </SuspenseModal>
      <Modal open={addressFormOpen} setOpen={setAddressFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <EditAddressForm
            uuid={boVerification.uuid}
            personInfo={boVerification?.personInfo!}
            onDone={() => {
              setAddressFormOpen(false)
              refetch()
            }}
          />
        </div>
      </Modal>
      <Modal open={questionnaireFormOpen} setOpen={setQuestionnaireFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <EditQuestionnaireForm
            uuid={boVerification.uuid}
            questionnaire={boVerification?.questionnaire!}
            onDone={() => {
              setQuestionnaireFormOpen(false)
              refetch()
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default ContactKYC
