import { FC } from 'react'
import { formatDecimal } from '../../utils/formatNumber'
import {
  CustomerAccountKind,
  useCustomerAccountQuery,
  usePayCustomerInvoicesMutation,
  usePayCustomerPenaltiesMutation
} from '../../graphql/schema'
import useAccessCheck from '../../hooks/useAccessCheck'

import { ReactComponent as LoadingIcon } from '../../svg/ui/refresh.svg'
import { ReactComponent as ApplyPaymentIcon } from '../../svg/ui/payment.svg'

interface CompanyInfoTabProps {
  customerId?: number | string
  title?: string
  kind?: CustomerAccountKind
  onDone?: () => void
}

const CustomerCompanyBalance: FC<CompanyInfoTabProps> = ({ customerId, title, kind, onDone }) => {
  const { data: customerAccountData } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${customerId}`, kind: kind },
    skip: !customerId,
    fetchPolicy: 'cache-and-network'
  })
  const customerAccount = customerAccountData?.customerAccount
  const [payCustomerInvoices, { loading: loadingPayCustomerInvoices }] = usePayCustomerInvoicesMutation()
  const [payCustomerPenalties, { loading: loadingPayCustomerPenalties }] = usePayCustomerPenaltiesMutation()

  const hasAccessRedeem = useAccessCheck('customer_account.redeem')

  async function payInvoices() {
    if (!customerId || !customerAccount?.id) return
    payCustomerInvoices({
      variables: {
        input: {
          customerAccountId: customerAccount.id
        }
      }
    }).then(() => {
      if (onDone) onDone()
    })
  }

  async function payPenalties() {
    if (!customerId || !customerAccount?.id) return
    payCustomerPenalties({
      variables: {
        input: {
          customerAccountId: customerAccount.id
        }
      }
    }).then(() => {
      if (onDone) onDone()
    })
  }

  const loading = loadingPayCustomerPenalties || loadingPayCustomerInvoices

  return (
    <div className='rounded-md bg-surface-primary p-2 shadow-card'>
      <div className='my-6 ml-6 flex items-center gap-8'>
        <h2 className='font-display text-h200 font-normal'>{title || 'Баланс'}</h2>
        <div className=''>{customerAccount ? formatDecimal(customerAccount.amount * 100) : 0} ₽</div>
        {hasAccessRedeem && customerAccount && (
          <div
            className='ml-auto mr-6 flex flex-none cursor-pointer items-center text-red-100'
            onClick={() => {
              if (loading) return
              kind === CustomerAccountKind.Main ? payInvoices() : payPenalties()
            }}
          >
            {loading ? <LoadingIcon className='mr-5 animate-spin' /> : <ApplyPaymentIcon className='mr-5' />}
            Погасить начисления
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomerCompanyBalance
