import { FC, ComponentProps } from 'react'
import c from 'clsx'

const Card: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <div className={c('bg-surface-secondary rounded-xl', className)} {...other}>
      {children}
    </div>
  )
}

const CardHeader: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <div className={c('px-5 my-4 flex items-center justify-between', className)} {...other}>
      {children}
    </div>
  )
}

const CardTitle: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <h3 className={c('px-3 py-2 text-title-m font-semibold', className)} {...other}>
      {children}
    </h3>
  )
}

const CardMenu: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <div className={c('flex items-center gap-1', className)} {...other}>
      {children}
    </div>
  )
}

const CardButton: FC<ComponentProps<'button'>> = ({ children, className, ...other }) => {
  return (
    <button
      className={c(
        'px-4 py-2 rounded-md font-medium text-labels-tertiary hover:bg-surface-tertiary hover:text-labels-primary',
        className
      )}
      {...other}
    >
      {children}
    </button>
  )
}

const CardIconButton: FC<ComponentProps<'button'>> = ({ children, className, ...other }) => {
  return (
    <button
      className={c(
        'p-3 rounded-md font-medium text-labels-tertiary hover:bg-surface-tertiary hover:text-labels-primary',
        className
      )}
      {...other}
    >
      {children}
    </button>
  )
}

export { Card, CardHeader, CardTitle, CardMenu, CardButton, CardIconButton }
