import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  TaskTargetType,
  useApplicationByIdLazyQuery,
  useTaskDealLazyQuery,
  useSourceByIdLazyQuery,
  useSupplierByIdLazyQuery,
  useCustomerCompanyNameLazyQuery
} from '../../graphql/schema'
import Tooltip from '../Tooltip'
import { getEntityLink } from '../../utils/entityLink'

interface TaskIDProps {
  targetId: number
  targetType: TaskTargetType
}

const taskTargetTypeDict: Record<TaskTargetType, string> = {
  [TaskTargetType.Application]: 'Заявка',
  [TaskTargetType.Source]: 'Источник',
  [TaskTargetType.Deal]: 'Сделка',
  [TaskTargetType.Supplier]: 'Поставщик',
  [TaskTargetType.Customer]: 'Клиент',
  [TaskTargetType.ScoringProcess]: 'fixme'
}

const TaskTarget: FC<TaskIDProps> = ({ targetId, targetType }) => {
  const [name, setName] = useState<string>()

  const [getApplication] = useApplicationByIdLazyQuery()
  const [getSource] = useSourceByIdLazyQuery()
  const [getDeal] = useTaskDealLazyQuery()
  const [getSupplier] = useSupplierByIdLazyQuery()
  const [getCustomer] = useCustomerCompanyNameLazyQuery()

  useEffect(() => {
    switch (targetType) {
      case TaskTargetType.Application:
        getApplication({
          variables: {
            id: targetId.toString()
          }
        }).then((res) => {
          setName(`${res.data?.application?.customerCompany?.shortWithOpf}` || '')
        })
        break
      case TaskTargetType.Source:
        getSource({
          variables: {
            id: targetId.toString()
          }
        }).then((res) => {
          setName(res.data?.source?.name || '')
        })
        break
      case TaskTargetType.Deal:
        getDeal({
          variables: {
            id: targetId.toString()
          }
        }).then((res) => {
          setName(res.data?.deal?.customerCompany?.shortWithOpf || '')
        })
        break
      case TaskTargetType.Supplier:
        getSupplier({
          variables: {
            id: targetId.toString()
          }
        }).then((res) => {
          setName(`${res.data?.supplierCompany?.shortWithOpf}` || '')
        })
        break
      case TaskTargetType.Customer:
        getCustomer({
          variables: {
            id: targetId.toString()
          }
        }).then((res) => {
          setName(`${res.data?.customerCompany?.shortWithOpf}` || '')
        })
        break
    }
  }, [targetId, targetType, getApplication, getSource, getDeal, getSupplier, getCustomer])

  return (
    <Tooltip
      target={
        <Link className='text-blue-400 group-hover:text-blue-600' to={getEntityLink(targetType, targetId)}>
          {taskTargetTypeDict[targetType]} №{targetId}
        </Link>
      }
    >
      {name}
    </Tooltip>
  )
}

export default TaskTarget
