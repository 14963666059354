import { Menu, Transition } from '@headlessui/react'
import { FC, Fragment, Suspense } from 'react'
import { supplierStatusDict } from '../../utils/dictionaries'
import {
  EngagePartnerStatus,
  useSupplierStatusSelectQuery,
  useSupplierStatusSelectSuspenseQuery
} from '../../graphql/schema'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as ChevronDownSmall } from '../../svg/ui/chevron-down-small.svg'
import useStatusChange from './useChangeStatus'
import { SupplierStatusCircle, engageColorDict, engageTextColorDict } from './SupplierStatus.tsx'
import { dateFormatter } from '../../utils/dateFormatter.ts'
import c from 'clsx'

const listStatuses: EngagePartnerStatus[] = Object.keys(supplierStatusDict) as EngagePartnerStatus[]

interface SupplierStatusSwitcherProps {
  id: number
}

interface SupplierStatusSwitcherInnerProps {
  id: number
  changeStatus: (status: EngagePartnerStatus) => Promise<void>
}

const SupplierStatusSwitcherInner: FC<SupplierStatusSwitcherInnerProps> = ({ id, changeStatus }) => {
  const { data: supplierData } = useSupplierStatusSelectSuspenseQuery({ variables: { id: `${id}` }, skip: !id })
  const supplier = supplierData?.supplierCompany
  const supplierStatus = supplier?.engagePartnerStatus

  return (
    <>
      {listStatuses?.map((status) => (
        <Menu.Item key={status}>
          <div
            onClick={() => changeStatus(status)}
            className={c(
              'h-16 gap-5 px-5 cursor-pointer flex items-center whitespace-nowrap',
              status === supplierStatus && 'text-red-100 pointer-events-none'
            )}
          >
            <SupplierStatusCircle status={status} bg={false} />
            {supplierStatusDict[status]}
          </div>
        </Menu.Item>
      ))}
    </>
  )
}

// wrap SupplierStatusSwitcherInner with suspense
const SupplierStatusSwitcher: FC<SupplierStatusSwitcherProps> = ({ id }) => {
  const [changeStatus, loading] = useStatusChange(id)

  const { data: supplierData, refetch } = useSupplierStatusSelectQuery({ variables: { id: `${id}` }, skip: !id })
  const supplier = supplierData?.supplierCompany
  const status = supplier?.engagePartnerStatus

  return (
    <div className='relative flex gap-x-4'>
      <Menu>
        <Menu.Button
          className={c(
            'px-5 py-3 flex gap-x-3 font-medium items-center rounded-md min-h-16',
            status ? engageTextColorDict[status] : 'bg-surface-secondary',
            status ? engageColorDict[status] : 'text-labels-secondary'
          )}
        >
          {status && <SupplierStatusCircle status={status} />}
          {status && supplierStatusDict[status]}

          {loading ? (
            <LoadingIcon className='animate-spin w-8 h-8' />
          ) : (
            <ChevronDownSmall className='transition-transform duration-200 ui-open:-rotate-180' />
          )}
        </Menu.Button>

        <Suspense
          fallback={
            <Menu.Items
              unmount={true}
              className='absolute whitespace-nowrap left-0 bg-surface-primary rounded-lg shadow-dropdown min-w-[244px] w-fit mt-4 overflow-y-auto flex flex-col z-50 transition-transform p-2 gap-y-1'
            >
              <div className='py-10'>
                <LoadingIcon className='animate-spin' />
              </div>
            </Menu.Items>
          }
        >
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              unmount={true}
              className='absolute left-0 top-full bg-surface-primary rounded-lg shadow-dropdown w-fit mt-4 overflow-y-auto flex flex-col z-50 transition-transform p-2 gap-y-1'
            >
              <SupplierStatusSwitcherInner
                id={id}
                changeStatus={async (status) => {
                  await changeStatus(status)
                  await refetch()
                }}
              />
            </Menu.Items>
          </Transition>
        </Suspense>
      </Menu>

      {status === EngagePartnerStatus.Hold && supplier?.engagePartnerHoldStatusSetAt && (
        <div className='px-5 py-3 whitespace-nowrap rounded-md font-medium bg-tr-red text-dark-red'>
          c {dateFormatter.format(new Date(supplier?.engagePartnerHoldStatusSetAt))}
        </div>
      )}
    </div>
  )
}

export default SupplierStatusSwitcher
