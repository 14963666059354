import { FC, ReactNode, useEffect, useState } from 'react'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as ChevronDownSmall } from '../../svg/ui/chevron-down-small.svg'
import { ReactComponent as ChevronRight } from '../../svg/ui/chevron-right.svg'
import { Combobox, Transition } from '@headlessui/react'
import c from 'clsx'

export interface NestedSelectOption {
  name: string
  node?: ReactNode
  disabled?: boolean
  children?: NestedSelectOption[]
}

interface NestedSelectInputProps {
  children: ReactNode
  className: string
  loading: boolean
  options: NestedSelectOption[]
  onSelect: (value: string) => void
}

const NestedSelectInput: FC<NestedSelectInputProps> = ({ children, className, options, loading, onSelect }) => {
  const [selectedOptions, setSelectedOptions] = useState<{ parent?: ReactNode; value?: NestedSelectOption[] }[]>([])

  useEffect(() => {
    if (options?.length) {
      setSelectedOptions([{ value: options }])
    }
  }, [options])

  return (
    <Combobox as='div' className='relative z-20'>
      {({ open }) => (
        <>
          <Combobox.Button className={className}>
            {children}
            {loading ? (
              <LoadingIcon className='h-8 w-8 animate-spin' />
            ) : (
              <ChevronDownSmall className={c('transition-transform duration-200', open && '-rotate-180')} />
            )}
          </Combobox.Button>
          <Transition
            show={open}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
          >
            <Combobox.Options className='absolute left-0 mt-4 flex w-fit min-w-[244px] flex-col gap-y-1 overflow-y-auto rounded-lg bg-surface-primary p-2 shadow-dropdown transition-transform'>
              {selectedOptions.at(-1)?.parent && (
                <button
                  onClick={() => setSelectedOptions(selectedOptions.slice(0, -1))}
                  className='flex cursor-pointer items-center border-b-1 border-separators-primary hover:bg-surface-secondary'
                >
                  <div className='pl-5'>
                    <ChevronRight className='rotate-180 text-grayscale-250' />
                  </div>
                  <div className='w-full'>{selectedOptions.at(-1)?.parent}</div>
                </button>
              )}
              {selectedOptions.at(-1)?.value?.map((option, index) => (
                <Combobox.Option
                  className='flex items-center rounded-md hover:bg-surface-secondary'
                  key={index}
                  value={option}
                  disabled={option?.disabled}
                  onClick={(e) => {
                    if (option?.children) {
                      e.preventDefault()
                      setSelectedOptions((value) => [...value, { parent: option?.node, value: option.children }])
                    } else {
                      onSelect(option.name)
                    }
                  }}
                >
                  <div className='w-full'>{option?.node}</div>
                  {option?.children && (
                    <div className='pr-5'>
                      <ChevronRight className='text-grayscale-250' />
                    </div>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </>
      )}
    </Combobox>
  )
}

export default NestedSelectInput
