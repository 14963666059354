import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-md.svg'
import { ReactComponent as EventSmsSentIcon } from '../../../svg/icons/eventSmsSent.svg'
import { ReactComponent as EventSmsDeliveredIcon } from '../../../svg/icons/eventSmsDelivered.svg'
import { ReactComponent as EventSmsNotDeliveredIcon } from '../../../svg/icons/eventSmsNotDelivered.svg'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as PlusIcon } from '../../../svg/ui/plus.svg'
import {
  useAddContactToDealMutation,
  useContactsSeparateFiltersQuery,
  useDealNotificationsDealQuery,
  useDealContactsQuery,
  useNotificationsQuery,
  useRemoveContactFromDealMutation,
  useUpdateDealContactMutation,
  SmsState
} from '../../../graphql/schema'
import { dateFormatter, dateTimeFormatter } from '../../../utils/dateFormatter'
import CreateContactForm from '../../../components/Forms/CreateContactForm'
import c from 'clsx'
import Modal from '../../../components/Modal'
import EditContactForm from '../../../components/Forms/EditContactForm'
import ConfirmationForm from '../../../components/ConfirmationForm'
import { useForm } from 'react-hook-form'
import useThrottledState from '../../../hooks/useThrottledState'
import useNodes from '../../../hooks/useNodes'
import { Switch } from '@headlessui/react'
import { verificationRiskColor, VerificationState } from '../../../utils/verification'
import { smsErrorCodeDict } from '../../../utils/dictionaries'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import { showPopup } from '../../../components/Toaster/showPopup.tsx'
import PhoneNumber from '../../../components/PhoneNumber.tsx'
import { Card, CardHeader, CardTitle } from '../../../components/Card.tsx'

const defaultFilters = {
  phone: undefined,
  fio: undefined,
  email: undefined
}

type Inputs = {
  phone?: string
  fio?: string
  email?: string
}

const Notifications: FC = () => {
  const { id } = useParams<'id'>()
  const { data } = useDealNotificationsDealQuery({
    variables: { id: `${id}` },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  const deal = data?.deal

  const [formOpen, setFormOpen] = useState(false)
  const [editContactId, setEditContactId] = useState<number>()

  const [deletionFormOpenById, setDeletionFormOpenById] = useState<number>()

  const { data: notificationsData } = useNotificationsQuery({
    variables: { dealId: `${id}` },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  const smses = notificationsData?.notifications
    ?.map((n) => n.smses.map((sms) => ({ ...sms, message: n.message })))
    .flat()

  const { data: dealContactsData, refetch } = useDealContactsQuery({
    variables: { dealId: parseInt(id || '') },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  const dealContacts = dealContactsData?.dealContacts || []

  const formContext = useForm<Inputs>()

  const phone = formContext.watch('phone')
  const fio = formContext.watch('fio')
  const email = formContext.watch('email')

  const [contactFilters, setContactFilters] = useThrottledState<{ phone?: string; fio?: string; email?: string }>(
    defaultFilters,
    500
  )

  const suggestedContactsQuery = useContactsSeparateFiltersQuery()
  const refetchSuggestions = suggestedContactsQuery.refetch

  useEffect(() => {
    setContactFilters({
      phone,
      fio,
      email
    })
  }, [phone, fio, email, setContactFilters])

  useEffect(() => {
    refetchSuggestions({
      phone:
        contactFilters.phone && contactFilters.phone.length > 1 ? contactFilters.phone.replace(/\D/g, '') : undefined,
      fio: contactFilters.fio ? contactFilters.fio : undefined,
      email: contactFilters.email ? contactFilters.email : undefined,
      order: [{ fio: 'asc' }]
    })
  }, [contactFilters, refetchSuggestions])

  const suggestedContacts = useNodes(suggestedContactsQuery.data?.contacts?.edges)

  const addedContactIds = useMemo(() => {
    return new Set(dealContacts.map((node) => node.contact.id))
  }, [dealContacts])

  const [addContactToDealMutation] = useAddContactToDealMutation()
  const [updateDealContact] = useUpdateDealContactMutation()
  const [removeContactFromDeal] = useRemoveContactFromDealMutation({
    onCompleted: () => refetch()
  })

  const updateDealContactCallback = useCallback(
    async (id: number, isSmsActivated: boolean) => {
      await updateDealContact({
        variables: {
          id,
          input: {
            isSmsActivated
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDealContact: {
            __typename: 'DealContact',
            id: id.toString(),
            isSmsActivated
          }
        }
      })
    },
    [updateDealContact]
  )

  const addContactToDeal = useCallback(
    async (contactId: number, dealId: number) => {
      const result = await addContactToDealMutation({
        variables: {
          input: {
            contactId,
            dealId
          }
        }
      })
      if (!!result.data?.addContactToDeal?.id) {
        await updateDealContactCallback(parseInt(result.data.addContactToDeal.id), true)
      }
      await refetch()
    },
    [addContactToDealMutation, updateDealContactCallback]
  )

  function remove() {
    if (!deletionFormOpenById) {
      return
    }
    return removeContactFromDeal({
      variables: { id: deletionFormOpenById }
    })
  }

  return (
    <Card>
      <div className='flex flex-col gap-6 p-5'>
        <div className='grid auto-rows-fr grid-cols-3 gap-6'>
          {dealContacts?.map((dealContact) => (
            <div key={dealContact.id} className='col-span-1 flex flex-col rounded-md bg-surface-primary shadow-card'>
              <div className='flex h-full flex-col px-4 pb-4'>
                <h2 className='flex h-full items-center justify-between px-6 py-10 text-h200'>
                  {dealContact?.contact?.fio}
                  <EditIcon
                    className='cursor-pointer text-grayscale-150'
                    onClick={() => setEditContactId(parseInt(dealContact.contact.id))}
                  />
                </h2>
                <table className='conte w-full'>
                  <tbody>
                    <tr className='group'>
                      <td className='flex px-6 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        Добавлен
                      </td>
                      <td className='px-6 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        <div className='flex items-center justify-end'>
                          <div className='text-p200'>{dateFormatter.format(new Date(dealContact?.createdDate))}</div>
                        </div>
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='flex px-6 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        Телефон
                      </td>
                      <td className='px-6 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        <div className='flex items-center justify-end'>
                          <PhoneNumber number={dealContact.contact?.phone} />
                        </div>
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='flex px-6 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        Email
                      </td>
                      <td className='px-6 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        <div className='flex items-center justify-end'>
                          <div className='text-p200'>{dealContact.contact?.email || '—'}</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className='mt-2 border-t-1 border-grayscale-400 px-9'>
                <div className='flex items-center justify-between pt-8'>
                  <Switch.Group key={dealContact.id}>
                    <div className='flex items-center'>
                      <Switch
                        checked={dealContact.isSmsActivated}
                        onChange={(checked: boolean) =>
                          dealContacts.filter((deal) => deal.isSmsActivated === true).length === 1 && checked === false
                            ? showPopup({
                                title: 'Ошибка',
                                subtitle: 'Хотя бы один контакт должен получать уведомления'
                              })
                            : updateDealContactCallback(parseInt(dealContact.id), checked)
                        }
                        className={c(
                          'relative mr-5 inline-flex h-12 w-12 items-center justify-center overflow-hidden rounded-lg border-grayscale-300 transition-colors focus:outline-none focus:ring-2 focus:ring-grayscale-400 focus:ring-offset-2',
                          dealContact.isSmsActivated ? 'bg-red-100' : 'border-1 bg-white-0'
                        )}
                      >
                        <TickIcon
                          className={c(
                            'text-white-0 transition-transform',
                            dealContact.isSmsActivated ? 'translate-y-0' : 'translate-y-20'
                          )}
                        />
                      </Switch>
                      <Switch.Label className='mr-4'>SMS</Switch.Label>
                    </div>
                  </Switch.Group>
                </div>
                <div
                  className='flex cursor-pointer items-center px-2 pb-12 pt-14 text-red-100'
                  onClick={() =>
                    dealContacts.filter((deal) => deal.isSmsActivated === true).length === 1 &&
                    dealContact.isSmsActivated === true
                      ? showPopup({
                          title: 'Ошибка',
                          subtitle: 'Хотя бы один контакт должен получать уведомления'
                        })
                      : setDeletionFormOpenById(parseInt(dealContact.id))
                  }
                >
                  <TrashIcon className='mr-8' title='Удалить' />
                  Удалить контакт
                </div>
              </div>
            </div>
          ))}

          <div
            className='col-span-1 flex cursor-pointer flex-col items-center justify-center rounded-md border-1 border-dashed border-grayscale-300 py-25 text-grayscale-200'
            onClick={() => setFormOpen(true)}
          >
            <PlusIcon className='mb-7 h-15 w-15' />
            Новый контакт
          </div>
        </div>

        {!!smses?.length && (
          <div>
            <CardHeader>
              <CardTitle>События</CardTitle>
            </CardHeader>
            <div className='grid auto-rows-fr grid-cols-3 gap-6'>
              <div className='col-span-2 rounded-md bg-surface-primary shadow-card'>
                <div className='flex flex-col gap-6 p-10'>
                  {smses?.map((sms) => (
                    <div key={sms.id} className='flex gap-7'>
                      <div className='flex flex-col items-center gap-3'>
                        <div>
                          {sms.state === SmsState.Pending && <EventSmsSentIcon />}
                          {sms.state === SmsState.Sent && <EventSmsSentIcon />}
                          {sms.state === SmsState.Delivered && <EventSmsDeliveredIcon />}
                          {sms.state === SmsState.Failed && <EventSmsNotDeliveredIcon />}
                        </div>
                        <div className='h-full w-1 border-r-1 border-grayscale-400' />
                      </div>
                      <div>
                        <p>
                          {sms.phone} <span className='text-grayscale-200'>отправлено</span> SMS сообщение{' '}
                          <span className='text-grayscale-200'>
                            {dateTimeFormatter.format(new Date(sms.createdDate))}
                          </span>
                        </p>
                        <div className='mb-9 mt-5 rounded-xl px-7 py-6 text-grayscale-150 shadow-xs'>{sms.message}</div>
                        {sms.state === SmsState.Failed && sms.errorCode && (
                          <small className='inline-block rounded-full bg-red-200 px-6 py-3 text-red-100'>
                            {smsErrorCodeDict[sms.errorCode]}
                          </small>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        <SuspenseModal open={!!editContactId} setOpen={() => setEditContactId(undefined)}>
          <div className='z-10 rounded-xl bg-white-0'>
            <EditContactForm
              onDone={async () => {
                await refetch()
                setEditContactId(undefined)
              }}
              id={editContactId as number}
            />
          </div>
        </SuspenseModal>
        <Modal open={formOpen} setOpen={setFormOpen}>
          <div className='z-10 grid grid-cols-2 rounded-xl bg-white-0'>
            <div>
              {deal?.application && (
                <CreateContactForm
                  onDone={async (contactId) => {
                    setFormOpen(false)
                    if (contactId) {
                      await addContactToDeal(contactId, parseInt(id || ''))
                    }
                    formContext.reset()
                    await refetch()
                  }}
                  context={formContext}
                  suggestedValues={deal?.application}
                  requiredPhone={true}
                />
              )}
            </div>
            {(phone && phone.length > 3) || fio || email ? (
              <>
                <div className='flex flex-col justify-stretch py-12 pr-12'>
                  <h1 className='mb-12 font-display text-h200'>Выбрать имеющийся</h1>
                  <div className='flex h-200 min-h-0 flex-shrink flex-col gap-5 overflow-y-auto'>
                    {suggestedContacts.map((contact) => {
                      const added = addedContactIds.has(contact._id.toString())
                      const dealContact = dealContacts.find((c) => parseInt(c.contact.id) === contact._id)

                      return (
                        <div
                          key={contact._id}
                          onClick={
                            added
                              ? () => {
                                  dealContacts.filter((deal) => deal.isSmsActivated === true).length === 1 &&
                                  dealContact?.isSmsActivated === true
                                    ? showPopup({
                                        title: 'Ошибка',
                                        subtitle: 'Хотя бы один контакт должен получать уведомления'
                                      })
                                    : removeContactFromDeal({
                                        variables: {
                                          id: parseInt(dealContact?.id || '')
                                        }
                                      })
                                }
                              : () => addContactToDeal(contact._id, parseInt(id || ''))
                          }
                          className='relative flex h-41 flex-none cursor-pointer items-center rounded-xl border-1 border-grayscale-400 px-10 hover:border-red-100'
                        >
                          <PlusIcon
                            className={c(
                              'absolute right-5 top-5 h-10 w-10 transition-transform duration-300',
                              added ? 'rotate-[225deg] text-grayscale-250' : 'text-red-100'
                            )}
                          />
                          <div>
                            <div className='mb-3 flex items-center text-p100 font-medium text-grayscale-0'>
                              {contact.fio}
                              <div
                                className={c(
                                  'ml-5 h-6 w-6 rounded-full',
                                  verificationRiskColor[contact.verificationStatus as VerificationState]
                                )}
                              />
                            </div>
                            <div className='flex items-center text-grayscale-150'>
                              {!contact.email && !contact.phone && <div className='text-grayscale-300'>—</div>}
                              <div className='flex items-center'>{contact.email}</div>
                              {!!contact.email && <div className='mx-5 h-2 w-2 rounded-full bg-grayscale-150' />}
                              <div className='flex items-center'>{contact.phone}</div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </>
            ) : (
              <div className='flex items-center justify-center'>
                <div className='rounded-full bg-grayscale-450 px-13 py-8 text-grayscale-200'>
                  Начни вводить, чтобы выбрать имеющиеся
                </div>
              </div>
            )}
          </div>
        </Modal>
        <Modal open={!!deletionFormOpenById} setOpen={() => setDeletionFormOpenById(undefined)}>
          <div className='z-10 rounded-xl bg-white-0'>
            <ConfirmationForm
              title='Удаление контакта'
              onDone={() => {
                setDeletionFormOpenById(undefined)
                remove()
              }}
              onDismiss={() => setDeletionFormOpenById(undefined)}
            >
              Вы действительно хотите удалить контакт из уведомления?
            </ConfirmationForm>
          </div>
        </Modal>
      </div>
    </Card>
  )
}

export default Notifications
