import {
  useTransactionsByPaymentIdSuspenseQuery,
  useUpdateTransactionCreatedAtMutation
} from '../../../graphql/schema.tsx'
import Input from '../../../components/Input.tsx'
import { SubmitHandler, useForm } from 'react-hook-form'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../../components/SubmitButton'
import { FC, Fragment } from 'react'
import { formatMoneyDecimal } from '../../../utils/formatNumber.ts'
import { dateFormatter } from '../../../utils/dateFormatter.ts'

type Transaction = {
  id: string
  amount: number
  createdAt: string
}

type Inputs = {
  transactions: Transaction[]
}

interface TransactionsDateFormProps {
  paymentId: string
  onDone?: () => void
}

const TransactionsDateForm: FC<TransactionsDateFormProps> = ({ paymentId, onDone }) => {
  const [updateTransactionCreatedAt, { loading }] = useUpdateTransactionCreatedAtMutation()
  const { data } = useTransactionsByPaymentIdSuspenseQuery({ variables: { id: paymentId }, skip: !paymentId })
  const paymentTransactions = data.payment.transactions
  const transactionsMap = new Map<string, Transaction>(
    paymentTransactions.map((t) => [
      t.id,
      {
        id: t.id,
        amount: t.amount,
        createdAt: t.createdAt.split('T').shift()
      }
    ])
  )

  const { register, handleSubmit, setError } = useForm<Inputs>({
    defaultValues: {
      transactions: Array.from(transactionsMap.values())
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const changedDateTransactions = data.transactions.filter(
      (t) => transactionsMap.get(t.id)?.createdAt !== t.createdAt
    )

    if (changedDateTransactions.length) {
      await Promise.all(
        changedDateTransactions.map((t) =>
          updateTransactionCreatedAt({
            variables: {
              input: {
                transactionId: t.id,
                createdAt: new Date(t.createdAt)
              }
            }
          })
        )
      )
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    } else {
      if (onDone) onDone()
    }
  }

  return (
    <section className='md:w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Редактирование даты поступления</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-7 gap-y-10 items-center'>
          <div className='col-span-3'>Дата разнесения</div>
          <div className='col-span-1' />
          <div className='col-span-3'>Новая дата</div>

          {Array.from(transactionsMap.values()).map((t, index) => (
            <Fragment key={t.id}>
              <div className='col-span-3'>
                <div>{t.createdAt ? dateFormatter.format(new Date(t.createdAt)) : ''}</div>
                <div className='text-xs text-grayscale-250'>{formatMoneyDecimal(t.amount)}</div>
              </div>
              <div className='col-span-1'>{`->`}</div>
              <div className='col-span-3'>
                <Input type='date' {...register(`transactions.${index}.createdAt`, { required: true })} />
              </div>
            </Fragment>
          ))}
        </div>
        <SubmitButton loading={loading}>Применить изменения</SubmitButton>
      </form>
    </section>
  )
}

export default TransactionsDateForm
