import { FC, useEffect, useMemo } from 'react'
import { BankOperationState, BankOperationsQueryVariables, BankOperationKind } from '../../graphql/schema'
import { Popover, RadioGroup } from '@headlessui/react'
import { ReactComponent as CloseIcon } from '../../svg/icons/cross.svg'
import c from 'clsx'
import { bankOperationStateDict } from '../../utils/dictionaries'
import { makeVar, useReactiveVar } from '@apollo/client'
import { throttle } from 'throttle-debounce'

export interface FilterFormProps {
  state: BankOperationFilterState
  onChange: (state: BankOperationFilterState) => void
}

export interface BankOperationFilterState {
  state: BankOperationState
  query?: string
}

const emptyState: BankOperationFilterState = {
  state: BankOperationState.Pending
}

const bankOperationFilterStateVar = makeVar<BankOperationFilterState>(emptyState)
const bankOperationQueryVar = makeVar<string>('')

const options = [
  [bankOperationStateDict[BankOperationState.Pending], BankOperationState.Pending],
  [bankOperationStateDict[BankOperationState.Processed], BankOperationState.Processed]
]

const BankOperationsFilterForm: FC<FilterFormProps> = ({ state, onChange }) => {
  return (
    <section className='p-10 relative'>
      <h2 className='font-display text-h200 mb-10'>Фильтр платежей</h2>
      <Popover.Button className='absolute top-0 right-0 p-10'>
        <CloseIcon className='text-grayscale-200 hover:text-red-100' />
      </Popover.Button>

      <div className='relative group mb-10'>
        <RadioGroup
          className='flex gap-5 text-grayscale-150 text-p200 flex-wrap'
          value={state.state}
          onChange={(value) => {
            onChange({
              ...state,
              state: value
            })
          }}
        >
          {options.map((option, i) => (
            <RadioGroup.Option className='' value={option[1]} key={i}>
              {({ checked }) => (
                <div
                  className={c(
                    'cursor-pointer px-7 py-2 bg-grayscale-400 rounded-full',
                    checked && 'bg-red-200 text-red-100'
                  )}
                >
                  {option[0]}
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
    </section>
  )
}

const setQuery = throttle(500, (query: string) => {
  bankOperationFilterStateVar({
    ...bankOperationFilterStateVar(),
    query
  })
})

export const useBankOperationFilter = () => {
  const filterState = useReactiveVar(bankOperationFilterStateVar)
  const queryState = useReactiveVar(bankOperationQueryVar)

  const filterActive = Boolean(filterState.state !== emptyState.state)

  useEffect(() => {
    setQuery(queryState)
  }, [queryState])

  const variables = useMemo<BankOperationsQueryVariables>((): BankOperationsQueryVariables => {
    if (filterState?.query?.length) {
      return {
        search: filterState.query,
        states: filterState.state
      }
    }
    return { kinds: [BankOperationKind.Income], states: [filterState.state] }
  }, [filterState])

  return {
    filterState,
    setFilterState: bankOperationFilterStateVar,
    filterActive,
    query: queryState,
    setQuery: bankOperationQueryVar,
    variables
  }
}

export default BankOperationsFilterForm
