import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDealStatusSelectSuspenseQuery, useUpdateDealTerminationDateMutation } from '../../graphql/schema'
import Input from '../../components/Input'
import SubmitButton from '../../components/SubmitButton'

interface TerminationDateFormProps {
  dealId: number
  onDone: () => void
}

interface TerminationDateFormValues {
  terminationDate: string
}

const TerminationDateForm: FC<TerminationDateFormProps> = ({ dealId, onDone }) => {
  const [loading, setLoading] = useState(false)
  const { data: terminationDateData } = useDealStatusSelectSuspenseQuery({
    variables: { id: dealId.toString() }
  })
  const [updateTerminationDate] = useUpdateDealTerminationDateMutation()

  const { register, handleSubmit, formState } = useForm<TerminationDateFormValues>({
    defaultValues: {
      terminationDate: terminationDateData.deal?.terminationDate?.split('T').shift()
    }
  })

  const onSubmit: SubmitHandler<TerminationDateFormValues> = async (data) => {
    setLoading(true)

    await updateTerminationDate({
      variables: {
        id: dealId.toString(),
        date: new Date(data.terminationDate).toISOString()
      }
    }).then(() => onDone())
  }

  return (
    <div className='w-[300px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Дата расторжения</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-14 grid grid-cols-1 gap-6'>
          <Input
            type='date'
            {...register('terminationDate', { required: true })}
            error={formState.errors?.terminationDate}
            required={true}
          />
        </div>
        <SubmitButton loading={loading}>Сохранить</SubmitButton>
      </form>
    </div>
  )
}

export default TerminationDateForm
