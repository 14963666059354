import { ReactComponent as ChevronIcon } from '../../svg/icons/chevron.svg'
import { ReactComponent as ArrowUpIcon } from '../../svg/icons/arrowUpSort.svg'
import { Menu, Transition } from '@headlessui/react'
import { FC, Fragment } from 'react'
import c from 'clsx'

interface SortMenuProps {
  title: string
  sort?: string
  order: { [key: string]: string }
  setOrder: (order: { [key: string]: string }) => void
  className?: string
}

const SortMenu: FC<SortMenuProps> = ({ title, sort, order, setOrder, className }) => {
  return (
    <Menu as='div' className={c(className, 'relative')}>
      <Menu.Button className={'px-7 py-4 flex items-center gap-2 h-full w-full'}>
        <span className='text-grayscale-150 text-left'>{title}</span>
        {sort && (
          <span className='flex flex-col'>
            <ChevronIcon className={c('w-4 h-3 -rotate-180', order && order[`${sort}`] === 'asc' && 'text-red-100')} />
            <ChevronIcon className={c('w-4 h-3', order && order[`${sort}`] === 'desc' && 'text-red-100')} />
          </span>
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='rounded-xl border-[1px] border-grayscale-400 absolute top-full -left-1px text-p200 font-medium bg-white-0 w-100'>
          <Menu.Item>
            <div
              onClick={() => setOrder({ [`${sort}`]: 'asc' })}
              className='px-7 py-4 cursor-pointer hover:bg-grayscale-400 first:rounded-t-xl last:rounded-b-xl flex items-center gap-8'
            >
              <ArrowUpIcon />
              По возрастанию
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              onClick={() => setOrder({ [`${sort}`]: 'desc' })}
              className='px-7 py-4 cursor-pointer hover:bg-grayscale-400 first:rounded-t-xl last:rounded-b-xl flex items-center gap-8'
            >
              <ArrowUpIcon className='rotate-180' />
              По убыванию
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default SortMenu
