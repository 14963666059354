import { FC, useState } from 'react'
import { ReactComponent as LoadingIcon } from '../../../svg/ui/refresh.svg'
import { ReactComponent as CollectorPendingIcon } from '../../../svg/icons/appScoringStatusPending.svg'
import { ReactComponent as CollectorEditIcon } from '../../../svg/icons/appScoringRisksUserEdit.svg'
import { useUpdateCollectorMutation } from '../../../graphql/schema.tsx'
import Avatar from '../../../components/Avatar'
import UserName from '../../../components/UserName.tsx'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import useCurrentUser from '../../../hooks/useCurrentUser.ts'
import Modal from '../../../components/SuspenseModal.tsx'
import CollectorForm from './CollectorForm.tsx'
import c from 'clsx'

interface CollectorProps {
  dealId: number
  collectorId?: number
}

const Collector: FC<CollectorProps> = ({ dealId, collectorId }) => {
  const [collectorFormOpenById, setCollectorFormOpenById] = useState<number>()

  const currentUser = useCurrentUser()
  const hasAccessCollectorManage = useAccessCheck('deal.collection.responsible_collector_manage')

  const [updateCollector, { loading }] = useUpdateCollectorMutation()

  return (
    <div className='px-5 pt-5'>
      <button
        type='button'
        className={c(
          'flex h-16 max-w-max items-center gap-3 rounded-md bg-surface-primary px-5 py-3 text-grayscale-150 shadow-card',
          hasAccessCollectorManage && 'hover:bg-grayscale-450'
        )}
        onClick={async () =>
          collectorId
            ? setCollectorFormOpenById(collectorId)
            : currentUser?._id &&
              (await updateCollector({ variables: { id: `${dealId}`, collector: `${currentUser._id}` } }))
        }
        disabled={!hasAccessCollectorManage}
      >
        {collectorId ? (
          <>
            <Avatar userId={collectorId} height='h-10' width='w-10' />
            <UserName userId={collectorId} />
            {hasAccessCollectorManage && <CollectorEditIcon />}
          </>
        ) : (
          <>
            {hasAccessCollectorManage &&
              (loading ? <LoadingIcon className='w-8 animate-spin' /> : <CollectorPendingIcon />)}
            {hasAccessCollectorManage ? 'Взять в работу' : 'Ответственный не назначен'}
          </>
        )}
      </button>

      <Modal open={!!collectorFormOpenById} setOpen={() => setCollectorFormOpenById(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <CollectorForm dealId={dealId} userId={collectorId} onDone={() => setCollectorFormOpenById(undefined)} />
        </div>
      </Modal>
    </div>
  )
}

export default Collector
