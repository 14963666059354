import { FC, Fragment, Suspense } from 'react'
import { PaymentCollectionProcessStatus, PaymentCollectionProcessStatus as Status } from '../../../graphql/schema'
import { collectionStatusDict } from '../../../utils/dictionaries'
import { Menu, Transition } from '@headlessui/react'
import CollectionStatus from '../../../components/CollectionStatus'
import useChangeCollectionStatus from './useChangeCollectionStatus'
import { ReactComponent as LoadingIcon } from './../../../svg/icons/loading.svg'
import { ReactComponent as MoreIcon } from './../../../svg/icons/more.svg'

const listStatuses: Status[] = Object.keys(collectionStatusDict) as Status[]

interface CollectionStatusSwitcherProps {
  paymentId: number
  collectionProcessId: string
  status: PaymentCollectionProcessStatus
  onChangeStatus: (status: Status) => void
}

interface CollectionStatusSwitcherInnerProps {
  paymentId: number
  changeStatus: (status: Status) => Promise<void>
  onChangeStatus: (status: Status) => void
}

const CollectionStatusSwitcherInner: FC<CollectionStatusSwitcherInnerProps> = ({ changeStatus, onChangeStatus }) => {
  return (
    <>
      {listStatuses?.map((status) => {
        return (
          <Menu.Item key={status}>
            <div
              onClick={() => {
                changeStatus(status)
                onChangeStatus(status)
              }}
              className='py-5 px-8 cursor-pointer hover:bg-grayscale-400 first:rounded-t-xl last:rounded-b-xl flex items-center gap-8'
            >
              <CollectionStatus status={status} />
              {collectionStatusDict[status]}
            </div>
          </Menu.Item>
        )
      })}
    </>
  )
}

const CollectionStatusSwitcher: FC<CollectionStatusSwitcherProps> = ({
  paymentId,
  collectionProcessId,
  status,
  onChangeStatus
}) => {
  const [changeStatus, loading] = useChangeCollectionStatus(collectionProcessId)

  return (
    <div className='relative'>
      <Menu>
        <Menu.Button className='rounded-xl px-4 border-1 text-grayscale-150 border-grayscale-400 bg-white-0 font-medium flex items-center w-full'>
          <CollectionStatus status={status} />
          <div className='ml-6'>{collectionStatusDict[status]}</div>
          <div className='ml-4'>
            {loading ? (
              <LoadingIcon className='animate-spin text-red-100 w-7 h-7' />
            ) : (
              <MoreIcon className='rotate-90 text-grayscale-150 pointer-events-none' />
            )}
          </div>
        </Menu.Button>

        <Suspense
          fallback={
            <Menu.Items
              unmount={true}
              className='rounded-xl border-[1px] border-grayscale-400 absolute text-p200 font-medium mt-4 bg-white-0 w-full'
            >
              <div className='py-10'>
                <LoadingIcon className='animate-spin mx-auto text-red-100' />
              </div>
            </Menu.Items>
          }
        >
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              unmount={true}
              className='rounded-xl border-[1px] border-grayscale-400 absolute text-p200 font-medium mt-4 bg-white-0 w-fit z-50'
            >
              <CollectionStatusSwitcherInner
                paymentId={paymentId}
                changeStatus={changeStatus}
                onChangeStatus={onChangeStatus}
              />
            </Menu.Items>
          </Transition>
        </Suspense>
      </Menu>
    </div>
  )
}

export default CollectionStatusSwitcher
