import { FC } from 'react'
import { useOutletContext } from 'react-router-dom'
import { DocumentTypeEntity, GuarantorByIdQuery } from '../../graphql/schema'
import DocumentManager from '../../components/DocumentManager'
import { ApolloQueryResult } from '@apollo/client'

const Documents: FC = () => {
  const [data] = useOutletContext<[GuarantorByIdQuery, () => Promise<ApolloQueryResult<GuarantorByIdQuery>>]>()
  const guarantor = data?.guarantor

  if (!guarantor) return null
  return <DocumentManager entityId={guarantor._id} entityType={DocumentTypeEntity.Guarantor} />
}

export default Documents
