import { forwardRef, ComponentPropsWithoutRef, useRef, ChangeEvent } from 'react'
import { ReactComponent as CheckIcon } from '../../../svg/icons/slimCheck.svg'

interface ScoringInputProps {
  loading: boolean
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  label?: string
  onAccept?: () => void
}

const ScoringInput = forwardRef<HTMLTextAreaElement, ComponentPropsWithoutRef<'textarea'> & ScoringInputProps>(
  function Textarea({ loading, label, onAccept, onChange, children, ...props }, refEl: any) {
    const ref = useRef<HTMLTextAreaElement>()

    const setTextareaHeight = (element: HTMLTextAreaElement) => {
      if (element) {
        element.style.height = '50px'
        element.style.height = element.scrollHeight + 'px'
      }
    }

    if (loading) return <div className='rounded-xl h-21 w-full bg-grayscale-400' />

    return (
      <div>
        {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

        <label className='group flex relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-xl ring-1'>
          <textarea
            {...props}
            className='h-25 py-6 pl-10 pr-16 transition-opacity border-none outline-none bg-transparent focus:ring-0 w-full placeholder-grayscale-250 resize-none'
            ref={(el: HTMLTextAreaElement) => {
              ref.current = el
              refEl && refEl(el)
              setTextareaHeight(el)
            }}
            onChange={(e) => {
              onChange(e)
              setTextareaHeight(e.currentTarget)
            }}
            onKeyDown={(event) => {
              if (event.code === 'Enter') {
                event.preventDefault()
                onAccept && onAccept()
                event.currentTarget.blur()
              }
            }}
          />
          {onAccept && (
            <button
              className='absolute right-4 bottom-6 invisible group-focus-within:visible text-grayscale-250 hover:text-red-100 cursor-pointer'
              onClick={() => {
                ref.current?.focus()
                onAccept()
                ref.current?.blur()
              }}
            >
              <CheckIcon />
            </button>
          )}
          {children}
        </label>
      </div>
    )
  }
)

export default ScoringInput
