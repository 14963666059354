import { Menu, Transition } from '@headlessui/react'
import c from 'clsx'
import { Fragment, FC, useRef, useCallback, Children, ReactElement, cloneElement } from 'react'
import { ReactComponent as EllipsisIcon } from '../../svg/icons/ellipsis.svg'

interface DropdownProps {
  children: JSX.Element | JSX.Element[]
  className?: string
}

const Dropdown: FC<DropdownProps> = ({ children, className }) => {
  // это воркераунд чтобы закрыть меню, если пункт меню вызывает асинхронную задачу
  // временное решение, пока Headless UI не позволят програмно открывать/закрывать меню

  const closeRef = useRef<HTMLButtonElement>(null)
  const detectorRef = useRef<HTMLDivElement>(null)
  const closeMenu = useCallback(() => {
    // если detectorRef отрендерен, значит меню открыто
    if (detectorRef.current && closeRef.current) {
      closeRef.current.click()
    }
  }, [])

  return (
    <Menu as='div' className='relative'>
      <Menu.Button className={c('h-10 flex items-center justify-center hover:text-red-100', className)} as='button' ref={closeRef}>
        {({ open }) =>
          <div className='hover:text-red-100 text-grayscale-200 h-full flex items-center'>
            <EllipsisIcon className={c(open && 'text-red-100')} />
          </div>}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items as='div' className='absolute z-50 bg-white-0 p-2 right-0 mt-2 origin-top-right bg-white rounded-lg shadow-lg focus:outline-none'>
          <div ref={detectorRef}></div>

          {Children.map(children, (child: ReactElement) => {
            const childClone = cloneElement(child, {
              onClick: async (e: React.PointerEvent) => {
                e.preventDefault()
                await child.props?.onClick()
                closeMenu()
              }
            })
            return <Menu.Item as='div'>{childClone}</Menu.Item>
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown