import { useReactiveVar } from '@apollo/client'
import { jwtVar } from '../graphql/cache'
import { useCurrentUserQuery } from '../graphql/schema'
import parseJWT from '../utils/parseJWT'

const useCurrentUser = () => {
  const jwt = useReactiveVar(jwtVar)
  const userId = parseJWT(jwt)?.id
  const { data } = useCurrentUserQuery({
    variables: { id: userId?.toString() || '' },
    skip: !userId,
  })
  return data?.user
}

export default useCurrentUser