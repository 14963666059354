import { FC } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { CustomerCompany, useCustomerByIdQuery } from '../../graphql/schema'
import { companyColorDict, CompanyStatus, companyStatusDict, companyTextColorDict } from '../Supplier/models'
import CopyInn from '../../components/CopyInn'
import BoNalogLink from '../../components/BoNalogLink'
import c from 'clsx'
import {
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader'
import { EntityPage } from '../../components/EntityPage/EntityPage'

const Customer: FC = () => {
  const { id } = useParams<'id'>()
  const { data, loading } = useCustomerByIdQuery({
    variables: { id: `${id}` },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })

  const customer: CustomerCompany = data?.customerCompany as CustomerCompany
  const status = customer?.dadata?.data?.state?.status as CompanyStatus

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='flex justify-between items-start'>
          <div>
            <EntityInfo>Компания</EntityInfo>
            <div className='flex items-center mb-6'>
              <EntityName value={customer?.shortWithOpf} loading={loading} />
              <CopyInn inn={customer?.inn} />
            </div>
          </div>
          <div className='flex flex-none gap-x-4'>
            <BoNalogLink inn={customer?.inn} />
          </div>
        </div>
        <div className='flex gap-x-4 mb-12'>
          {!!companyStatusDict[status] && (
            <div
              className={c('px-5 py-3 rounded-md font-medium', companyColorDict[status], companyTextColorDict[status])}
            >
              {companyStatusDict[status]}
            </div>
          )}
        </div>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Движения средств' to='payments' />
          <EntityNavItem title='Документы' to='docs' />
          <EntityNavItem title='Заявки' to='applications' />
          <EntityNavItem title='Сделки' to='deals' />
        </EntityNav>
      </EntityHeader>
      <EntityPage>
        <Outlet />
      </EntityPage>
    </div>
  )
}

export default Customer
