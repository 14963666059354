import { showPopup } from '../components/Toaster/showPopup.tsx'

let inactivityTimer: NodeJS.Timeout

const INACTIVITY_TIMEOUT = 60 * 60 * 1000 // 1 час в миллисекундах

const resetInactivityTimer = () => {
  // Сброс таймера, если пользователь активен
  clearTimeout(inactivityTimer)
  startInactivityTimer()
}

const startInactivityTimer = () => {
  inactivityTimer = setTimeout(() => {
    showPopup({
      title: 'Сессия неактивна',
      subtitle: 'Вы были неактивны более часа, информация может быть устаревшей. Пожалуйста, обновите страницу',
      autoCloseTimeout: Infinity // Поп-ап никогда не исчезнет без обновления страницы
    })
  }, INACTIVITY_TIMEOUT)
}

// Функция для начала отслеживания активности
export const monitorUserActivity = () => {
  // Слушаем события, которые указывают на активность пользователя
  window.addEventListener('mousemove', resetInactivityTimer)
  window.addEventListener('keydown', resetInactivityTimer)
  window.addEventListener('scroll', resetInactivityTimer)
  window.addEventListener('focus', resetInactivityTimer)

  // Начинаем таймер
  startInactivityTimer()
}

// Останавливаем отслеживание (например, при выходе пользователя из системы)
export const stopMonitoringUserActivity = () => {
  clearTimeout(inactivityTimer)
  window.removeEventListener('mousemove', resetInactivityTimer)
  window.removeEventListener('keydown', resetInactivityTimer)
  window.removeEventListener('scroll', resetInactivityTimer)
  window.removeEventListener('focus', resetInactivityTimer)
}
