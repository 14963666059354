import { FC } from 'react'
import useCurrentUser from '../hooks/useCurrentUser'
import Tasks from '../components/Tasks'

import { ReactComponent as GrafanaIcon } from '../svg/icons/grafana.svg'
import { EntityName } from '../components/EntityPage/EntityHeader'
import PrimaryButtonLink from '../components/UI/PrimaryButtonLink'
import { EntityPage } from '../components/EntityPage/EntityPage'

const Main: FC = () => {
  const currentUser = useCurrentUser()
  const name = currentUser?.name || 'Коллега'

  return (
    <div className='flex-grow'>
      <div className='bg-white-0 pt-16 px-6 border-separators-primary'>
        <div className='container mx-auto'>
          <div className='flex justify-between items-start'>
            <EntityName value={`Привет, ${name}!`} />
            <PrimaryButtonLink href={`http://158.160.127.77:1487`}>
              <GrafanaIcon />
              Открыть Grafana
            </PrimaryButtonLink>
          </div>
        </div>
      </div>

      <EntityPage>
        <Tasks userId={currentUser?._id} main={true} />
      </EntityPage>
    </div>
  )
}

export default Main
