import { FC, useState } from 'react'
import { useApplicationsListQuery, ApplicationsListQuery, useApplicationsCountQuery } from '../../graphql/schema'
import Modal from '../../components/Modal'
import ApplicationForm from '../../components/Forms/ApplicationForm'
import FilterForm, { useApplicationFilter } from './FilterForm'
import { dateTimeFormatter } from '../../utils/dateFormatter'
import useScrollPagination from '../../hooks/useScrollPagination'
import SearchBar, { FilterButton } from '../../components/Search'
import AvatarTooltip from '../../components/Avatar/AvatarTooltip'
import { NodeType } from '../../types'
import AddButton from '../../components/AddButton'
import useNodes from '../../hooks/useNodes'
import GridView, { GridViewConfig } from '../../components/GridView'
import CurrentUserFilter from './CurrentUserFilter'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import SelectedFilters from './SelectedFilters'
import { AppStatus, ApplicationStatusBadge } from '../../components/ApplicationStatus'
import Tooltip from '../../components/Tooltip'

type Application = NodeType<ApplicationsListQuery['applications']>

const appTableConfig: GridViewConfig<Application> = {
  grid: 'grid-cols-application',
  columns: [
    { title: 'ID', value: '_id', numeric: true },
    {
      title: 'Компания',
      value: (a) => <span className='line-clamp-1'>{a?.customerCompany?.shortWithOpf}</span>
    },
    { title: 'Источник', value: (a) => <span className='line-clamp-1'>{a?.source?.name}</span> },
    { title: 'Cоздано', value: (a) => dateTimeFormatter.format(new Date(a.created)), numeric: true },
    {
      title: 'МЛ',
      titleClassName: 'text-center',
      padding: false,
      value: (a) => <AvatarTooltip className='mx-auto' userId={a?.user?._id} />
    },
    {
      title: 'Статус',
      padding: false,
      value: (a) => (
        <div className='flex px-[5px]'>
          <Tooltip target={<ApplicationStatusBadge status={a.status} />}>
            {a.status === AppStatus.Closed && a.lossReason}
          </Tooltip>
        </div>
      )
    }
  ],
  rows: {
    link: (a) => `/applications/${a?._id}`
  }
}

const Applications: FC = () => {
  const [addFormOpen, setAddFormOpen] = useState(false)

  //calculate how many applications created today
  const today = new Date()
  const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  const { data: counterData } = useApplicationsCountQuery({
    variables: {
      after: todayStart.toISOString()
    }
  })

  const { setFilterState, query, setQuery, filterActive, filterState, variables } = useApplicationFilter()

  const {
    data: applicationsData,
    refetch,
    fetchMore,
    loading,
    error
  } = useApplicationsListQuery({
    variables,
    // для первого запроса обращаемся к кэшу, а для последующих - и к серверу тоже
    // чтобы не слетала пагинация
    nextFetchPolicy: 'cache-and-network'
  })

  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const applicationsList = useNodes(applicationsData?.applications?.edges)

  function openAddModal() {
    setAddFormOpen(true)
  }

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(
    fetchMore,
    applicationsData?.applications?.pageInfo
  )

  return (
    <div className='py-15 px-6 flex-grow container'>
      <div className='bg-surface-secondary rounded-xl p-5 mb-8'>
        <div className='bg-white-0 shadow-xs rounded-md'>
          <div className='flex justify-between items-start p-10 border-grayscale-400 border-b-1'>
            <div>
              <h1 className='font-display text-h100'>Заявки</h1>
            </div>
          </div>
          <div className='flex p-10 gap-12'>
            <div>
              <div className='text-grayscale-150 mb-4'>Заявок за сегодня</div>
              <div>{counterData?.applications?.totalCount || '-'}</div>
            </div>
          </div>
        </div>
      </div>

      <SearchBar onChange={setQuery} query={query}>
        <CurrentUserFilter
          userId={filterState?.users[0]}
          onChange={(userId) => {
            setFilterState({
              ...filterState,
              users: userId ? [userId] : []
            })
          }}
        />
        <FilterButton active={filterActive}>
          <FilterForm state={filterState} onChange={setFilterState} />
        </FilterButton>
      </SearchBar>

      <SelectedFilters />

      <div className='bg-surface-secondary rounded-xl p-5' ref={triggerRef}>
        <AddButton className='mb-5' onClick={() => openAddModal()}>
          Новая заявка
        </AddButton>
        <GridView
          config={appTableConfig}
          data={applicationsList}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
      <Modal open={addFormOpen} setOpen={setAddFormOpen}>
        <div className='bg-white-0 rounded-xl z-10 absolute top-30'>
          <ApplicationForm
            onDone={() => {
              refetch()
              setAddFormOpen(false)
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Applications
