import { FC, useCallback } from 'react'
import {
  ApplicationQuestionnaireDocument,
  ApplicationQuestionnaireQuery,
  ApplicationQuestionnaireQueryVariables,
  useUpdateApplicationQuestionnaireMutation
} from '../../../graphql/schema.tsx'
import ScoringToggle from './ScoringToggle.tsx'
import cache from '../../../graphql/cache.ts'
import ScoringInput from './ScoringInput.tsx'

const formatKey = (value: string) => value.toLowerCase().replace(/[,?]/g, '').replace(/\s/g, '_')

const updateApplicationQuestionnaireCache = (
  applicationId: number,
  applicationQuestionnaire: ApplicationQuestionnaire
) => {
  cache.writeQuery<ApplicationQuestionnaireQuery, ApplicationQuestionnaireQueryVariables>({
    query: ApplicationQuestionnaireDocument,
    variables: {
      applicationId
    },
    data: {
      __typename: 'Query',
      applicationQuestionnaire
    }
  })
}

export type ApplicationQuestionnaire = {
  __typename: 'ApplicationQuestionnaire'
  id: string
  applicationId: number
  data?: any
  conclusion?: string
}

export interface ScoringQuestion {
  value: string
  fields?: string[]
  children?: ScoringQuestion[]
}

interface ScoringQuestionProps {
  loading: boolean
  chapter: string
  question: ScoringQuestion
  applicationId: number
  questionnaire?: ApplicationQuestionnaire
  children?: ScoringQuestion[]
  onDataSaved: (data: any) => void
}

const ScoringQuestion: FC<ScoringQuestionProps> = ({
  loading,
  chapter,
  question,
  applicationId,
  questionnaire,
  children,
  onDataSaved
}) => {
  const [updateApplicationQuestionnaireData] = useUpdateApplicationQuestionnaireMutation()

  const onValueChange = useCallback(
    async (type: string, key: string, value: boolean) => {
      const data = {
        ...questionnaire?.data,
        [`${type}__${formatKey(key)}`]: { ...questionnaire?.data?.[`${type}__${formatKey(key)}`], value }
      }

      await updateApplicationQuestionnaireData({
        variables: {
          input: {
            applicationId,
            data
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateApplicationQuestionnaire: {
            __typename: 'ApplicationQuestionnaire',
            id: `${questionnaire?.id}`,
            applicationId,
            data,
            conclusion: questionnaire?.conclusion || ''
          }
        },
        onCompleted: (data) => {
          const questionnaireUpdated = data?.updateApplicationQuestionnaire
          if (!questionnaireUpdated) return

          updateApplicationQuestionnaireCache(applicationId, questionnaireUpdated)
          onDataSaved(questionnaireUpdated.data)
        }
      })
    },
    [applicationId, questionnaire, updateApplicationQuestionnaireData]
  )

  const onQuestionAccept = useCallback(async () => {
    if (!questionnaire?.data) return

    await updateApplicationQuestionnaireData({
      variables: {
        input: {
          applicationId,
          data: questionnaire.data
        }
      }
    })
    onDataSaved(questionnaire.data)
  }, [applicationId, questionnaire, updateApplicationQuestionnaireData])

  const onQuestionChange = useCallback(
    (question: string, key: string, value: string) => {
      if (!questionnaire) return

      const applicationQuestionnaire = {
        ...questionnaire,
        data: {
          ...questionnaire?.data,
          [question]: { ...questionnaire?.data?.[question], [key]: value }
        }
      }
      updateApplicationQuestionnaireCache(applicationId, applicationQuestionnaire)
    },
    [applicationId, questionnaire, cache]
  )

  return (
    <>
      <div className='flex items-center justify-between gap-10'>
        <div>{question.value}</div>
        <div className='flex-none'>
          <ScoringToggle
            loading={loading}
            onChange={(value) => onValueChange(formatKey(chapter), question.value, value)}
            checkedValue={questionnaire?.data?.[`${formatKey(chapter)}__${formatKey(question.value)}`]?.value}
          />
        </div>
      </div>

      {questionnaire?.data?.[`${formatKey(chapter)}__${formatKey(question.value)}`]?.value &&
        question?.fields &&
        question.fields.map((j) => (
          <div key={j} className='flex flex-col gap-5'>
            {!!j.length && <div className='text-p200 font-medium'>{j}</div>}
            <ScoringInput
              loading={loading}
              autoComplete='off'
              placeholder='Введите данные'
              onChange={(e) =>
                onQuestionChange(
                  `${formatKey(chapter)}__${formatKey(question.value)}`,
                  formatKey(j),
                  e.currentTarget.value
                )
              }
              onAccept={onQuestionAccept}
              value={questionnaire?.data?.[`${formatKey(chapter)}__${formatKey(question.value)}`]?.[formatKey(j)]}
            />
          </div>
        ))}

      {questionnaire?.data?.[`${formatKey(chapter)}__${formatKey(question.value)}`]?.value &&
        children?.length &&
        children.map((childrenQuestion) => (
          <ScoringQuestion
            key={chapter + question.value + childrenQuestion.value}
            loading={loading}
            chapter={`${chapter}__${question.value}`}
            question={childrenQuestion}
            applicationId={applicationId}
            questionnaire={questionnaire}
            onDataSaved={onDataSaved}
          />
        ))}
    </>
  )
}

export default ScoringQuestion
