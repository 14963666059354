import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import SubmitButton from '../../../components/SubmitButton'
import Input from '../../../components/Input'
import {
  DealSupplyMode,
  DocumentTypeEntity,
  useCreateTransferActDocxMutation,
  useDealForTransferActSuspenseQuery,
  useDealSupplySuspenseQuery
} from '../../../graphql/schema.tsx'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  getFormattedContractNumber,
  isIP
} from '../../../utils/contractUtils.ts'
import downloadFile from '../../../utils/downloadFileServerless.ts'
import { getSpecTotals } from './parseSpec.ts'
import { formatDecimal } from '../../../utils/formatNumber.ts'
import useEntityDocuments from '../../../components/DocumentManager/useEntityDocuments.ts'
import { SPECIFICATIONS_FOLDER_NAME, SUPPLY_FOLDER_NAME } from '../../../config.ts'
import { dateFormatter } from '../../../utils/dateFormatter.ts'
import getFileNameWithoutExtension from '../../../utils/getFileNameWithoutExtension.ts'

interface TransferActFormProps {
  dealId: number
  supplyId: number
}

interface SupplyFormValues {
  transferDate: string
  supplyDoc: string
}

const TransferActForm: FC<TransferActFormProps> = ({ dealId, supplyId }) => {
  const [loading, setLoading] = useState(false)
  const [createTransferAct] = useCreateTransferActDocxMutation()

  const { data: dealData } = useDealForTransferActSuspenseQuery({ variables: { id: dealId.toString() } })
  const deal = dealData.deal

  const { data: supplyData } = useDealSupplySuspenseQuery({ variables: { id: supplyId.toString() } })
  const supply = supplyData.dealSupply
  const frameMode = supply?.mode === DealSupplyMode.Frame

  const supplyDocs = useEntityDocuments({
    entityId: `${supply?._id}`,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SUPPLY_FOLDER_NAME
  })
  const specificationDocs = useEntityDocuments({
    entityId: `${supply?._id}`,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SPECIFICATIONS_FOLDER_NAME
  })

  const companyDadata = deal?.customerCompany?.dadata ? deal.customerCompany.dadata.data : {}
  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)

  const supplyCompanyDadata = supply?.supplierCompany?.dadata ? supply.supplierCompany.dadata.data : {}
  const supplyIP: boolean = isIP(supplyCompanyDadata)
  const supplyCompanyName = supplyIP
    ? capitalizeFirstInWords(supplyCompanyDadata.name?.full)
    : capitalizeFirst(supplyCompanyDadata.name?.full)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SupplyFormValues>({
    defaultValues: {
      supplyDoc: frameMode
        ? `${supply?.supplierCompany?.frameContractDocumentNumber} от ${supply?.supplierCompany?.frameContractDocumentDate && dateFormatter.format(new Date(supply.supplierCompany.frameContractDocumentDate))} ${specificationDocs
            .filter((d) => d.mimeType === 'application/pdf')
            .map((d) => getFileNameWithoutExtension(d?.originalName))
            .join(' ')}`.trim()
        : supplyDocs
            .filter((d) => d.mimeType === 'application/pdf')
            .map((d) => getFileNameWithoutExtension(d?.originalName))
            .join(' ')
            .trim()
    }
  })

  const onSubmit: SubmitHandler<SupplyFormValues> = async (data) => {
    if (loading) return

    setLoading(true)

    const { totalAmount, totalVat } = getSpecTotals(supply?.nomenclature || [])

    try {
      const createTransferActResult = await createTransferAct({
        variables: {
          input: {
            transferDate: getDateFormattedString(new Date(data.transferDate)),
            transferDoc: data.supplyDoc,
            contractN: getFormattedContractNumber(deal?.contractNumber),
            contractDate: deal?.contractDate ? getDateFormattedString(new Date(deal.contractDate)) : '',
            companyName: `${deal?.customerCompany?.fullWithOpf ? getCompanyForm(deal.customerCompany.fullWithOpf, companyName, true) : ''} ${IP ? companyName : '«' + companyName + '»'} (ИНН ${deal?.customerCompany?.inn})`,
            supplierName: `${supply?.supplierCompany?.shortWithOpf ? getCompanyForm(supply.supplierCompany.shortWithOpf, supplyCompanyName) : ''} ${supplyIP ? supplyCompanyName : '«' + supplyCompanyName + '»'} (ИНН ${supply?.supplierCompany?.inn})`,
            currency: supply?.currency || 'RUB',
            totalVat: formatDecimal(totalVat),
            totalAmount: formatDecimal(totalAmount),
            items: supply?.nomenclature?.map((n) => ({
              name: n.name,
              amount: `${n.count}`,
              units: n.unit,
              price: formatDecimal(n.pricePerUnitCents),
              vat: formatDecimal(n.VATCents),
              total: formatDecimal(n.totalPriceCents)
            }))
          }
        }
      })

      const transferActUrl = createTransferActResult?.data?.createTransferAct?.url
      if (!transferActUrl) throw new Error('Не удалось получить ссылку на файл')

      await downloadFile(
        transferActUrl,
        `${getDateFormattedInDocumentName(new Date())} Акт приема передач к договору лизинга ${getFormattedContractNumber(deal?.contractNumber)} от ${deal?.contractDate ? getDateFormattedString(new Date(deal.contractDate)) : ''}.docx`
      )
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <section className='w-[338px] overflow-x-hidden p-12'>
      <h1 className='mb-12 font-display text-h200'>Акт приема-передачи</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Input
            label='Дата акта'
            type='date'
            {...register('transferDate', { required: true })}
            error={errors.transferDate}
          />
          <Input
            type='text'
            label='Договор поставки'
            {...register('supplyDoc', { required: true })}
            error={errors.supplyDoc}
          />
        </div>
        <SubmitButton loading={loading}>Сгенерировать</SubmitButton>
      </form>
    </section>
  )
}

export default TransferActForm
