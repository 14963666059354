import { FC, ComponentProps, ReactNode } from 'react'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { Transition } from '@headlessui/react'
import c from 'clsx'

interface SubmitButtonProps {
  children: ReactNode
  className?: string
  loading?: boolean
}

const SubmitButton: FC<SubmitButtonProps & ComponentProps<'button'>> = ({
  children,
  loading,
  className,
  ...otherProps
}) => {
  return (
    <button
      type='submit'
      className={c(
        'px-20 h-27 bg-red-100 hover:bg-red-50 active:bg-red-150 focus-visible:outline-red-100 focus-visible:outline-2 focus-visible:outline ring-red-100 rounded-xl text-white-0 text-p200 font-semibold leading-full w-full overflow-hidden relative',
        className,
        // у кнопки есть три стейта - обычный, disabled при невыполнении условий нажатия, тогда она будет серая с серым текстом, и disabled во время loading -
        // тогда из-за тернарного оператора стили loading будут приоритетнее, и при сабмите кнопка будет и disabled, и красного цвета
        loading
          ? 'bg-red-100 text-white-0'
          : 'disabled:bg-grayscale-450 disabled:text-grayscale-250 disabled:cursor-not-allowed'
      )}
      {...otherProps}
      disabled={otherProps.disabled || loading}
    >
      <Transition
        show={!loading}
        className='relative transition-all transform duration-300 flex items-center justify-center'
        enterFrom='-translate-x-1/2 opacity-0'
        enterTo='translate-x-0 opacity-100'
        leaveTo='translate-x-1/2 opacity-0'
      >
        {children}
      </Transition>
      <Transition
        show={loading}
        className='absolute transition-all transform inset-0 flex justify-center items-center duration-300'
        enterFrom='-translate-x-1/4 opacity-0'
        enterTo='translate-x-0 opacity-100'
        leaveTo='translate-x-1/4 opacity-0'
      >
        <LoadingIcon className='animate-spin' />
      </Transition>
    </button>
  )
}

export default SubmitButton
