import { FC, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import {
  CommentTargetType,
  TaskTargetType,
  useRefreshFromDadataSupplierCompanyMutation,
  useRemoveCompanyBranchMutation,
  useSupplierByIdQuery,
  useSuppliesBySupplierCompanyIdQuery,
  useUpdateSupplierEdOsMutation,
  useUpdateSupplierVatMutation,
  VatKind
} from '../../graphql/schema'
import { Address, CompanyData } from '../../types/dadata'
import { isIP } from '../../utils/contractUtils'
import useNodes from '../../hooks/useNodes'
import Modal from '../../components/Modal'
import CategoriesForm from './CategoriesForm'
import CompanyInfo from '../../components/CompanyInfo'
import RadioButtonsGroup from '../../components/RadioButtonsGroup'
import EDO, { EDOCallback } from '../../components/EDO'
import Comments from '../../components/Comments/Comments'
import AccreditationStatusSwitcher from './AccreditationStatusSwitcher.tsx'
import CompanyCheckFinmon from '../../components/Finmon/Company/CompanyCheckFinmon'
import useAccessCheck from '../../hooks/useAccessCheck'
import Tasks from '../../components/Tasks/index'
import DropdownButton from '../../components/Dropdown/Button'
import Dropdown from '../../components/Dropdown/index'
import CompanyBranchForm, { CompanyBranch } from './CompanyBranchForm'
import AddButton from '../../components/AddButton/index'
import ConfirmationForm from '../../components/ConfirmationForm'
import ContactInfo, { ContactInfoKind } from '../../components/ContactInfo/ContactInfo'
import SourcesList from '../../components/SourcesList/index'
import { vatKindDict } from '../../utils/dictionaries'
import Requisites from '../../components/Requisites/index'
import Avatar from '../../components/Avatar'
import getFullName from '../../utils/getFullName.ts'
import ManagersForm from './ManagersForm.tsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../components/Card.tsx'
import LeasingSubjectCategoriesList from '../../components/LeasingSubjectCategoriesList.tsx'
import GridView, { GridViewConfig } from '../../components/GridView'
import AvatarTooltip from '../../components/Avatar/AvatarTooltip.tsx'
import { formatDecimal } from '../../utils/formatNumber.ts'

type Supply = {
  _id: number
  shippingDone: boolean
  amount: number
  shippingDate?: string
  factShippingDate?: string
  deal?: {
    _id: number
    user?: {
      _id: number
    }
  }
}

const daysDiff = (target: string, current?: string) => {
  const targetDate = new Date(target)
  const currDate = current ? new Date(current) : new Date()

  if (targetDate > currDate) return 0

  const differenceInTime = currDate.getTime() - targetDate.getTime()
  return Math.floor(differenceInTime / (1000 * 3600 * 24))
}

const Info: FC = () => {
  const [formOpen, setFormOpen] = useState(false)
  const [companyBranchFormOpen, setCompanyBranchFormOpen] = useState(false)
  const [deletionFormOpen, setDeletionFormOpen] = useState(false)
  const [deletingEntity, setDeletingEntity] = useState<CompanyBranch>()
  const [editEntity, setEditEntity] = useState<CompanyBranch>()
  const [managersFormOpen, setManagersFormOpen] = useState(false)

  const { id } = useParams<'id'>()
  const { data, refetch, loading } = useSupplierByIdQuery({ variables: { id: `${id}` }, skip: !id })
  const supplier = data?.supplierCompany

  const selectedEDOs = useNodes(supplier?.edoTypes?.edges)
  const categories = useNodes(supplier?.leasingSubjectCategories?.edges)
  const companyBranches = useNodes(supplier?.companyBranches?.edges)
  const contactInfos = useNodes(supplier?.contactInfos?.edges)

  const requisites = useNodes(supplier?.bankDetails?.edges) || []
  const dadata: CompanyData = data?.supplierCompany?.dadata ? data?.supplierCompany.dadata.data : {}
  const address: Address = dadata?.address

  const IP = isIP(dadata)

  const { data: suppliesData, error } = useSuppliesBySupplierCompanyIdQuery({
    variables: {
      id: parseInt(id!)
    },
    skip: !id
  })
  const supplies = useNodes(suppliesData?.dealSupplies?.edges).filter((s) => {
    if (!s?.shippingDate) return false
    if (s?.factShippingDate) return new Date(s.shippingDate) < new Date(s.factShippingDate)
    return new Date(s.shippingDate) < new Date()
  })

  const [refreshDadataMutation, { loading: loadingRefreshDadata }] = useRefreshFromDadataSupplierCompanyMutation()
  const [updateSupplierEDOs] = useUpdateSupplierEdOsMutation()
  const [updateSupplierVAT] = useUpdateSupplierVatMutation()

  const [removeCompanyBranch] = useRemoveCompanyBranchMutation()

  const hasAccessVatManage = useAccessCheck('supplier_company.vat_kind.manage')
  const hasAccessEdoManage = useAccessCheck('supplier_company.edo_type.manage')

  async function refreshDadata() {
    await refreshDadataMutation({ variables: { input: { inn: supplier?.inn as string } } })
    refetch()
  }

  const onVATChange = useCallback(
    (vatKind: string) => {
      if (!supplier?._id) return

      updateSupplierVAT({
        variables: {
          input: {
            id: `${supplier._id}`,
            vatKind: vatKind as VatKind
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSupplierCompany: {
            __typename: 'updateSupplierCompanyPayload',
            supplierCompany: {
              __typename: 'SupplierCompany',
              _id: supplier?._id,
              vatKind: vatKind as VatKind
            }
          }
        }
      })
    },
    [supplier?._id, updateSupplierVAT]
  )

  const onEDOChange = useCallback<EDOCallback>(
    (data) => {
      if (!supplier?._id) return

      const edos = data.edos === undefined ? selectedEDOs : data.edos
      const customEDO = data.customEDO === undefined ? supplier?.edoCustomValue : data.customEDO
      const edoStatus = data.enabled === undefined ? supplier?.edoStatus : data.enabled

      updateSupplierEDOs({
        variables: {
          input: {
            id: `${supplier._id}`,
            edoStatus,
            edoTypes: edos && edos.map((o) => `${o._id}`),
            edoCustomValue: customEDO
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSupplierCompany: {
            __typename: 'updateSupplierCompanyPayload',
            supplierCompany: {
              __typename: 'SupplierCompany',
              _id: supplier?._id,
              //@ts-ignore
              edoStatus,
              edoTypes: {
                __typename: 'EdoTypeConnection',
                edges: edos?.map((o) => ({
                  __typename: 'EdoTypeEdge',
                  node: {
                    ...o,
                    __typename: 'EdoType'
                  }
                }))
              },
              //@ts-ignore
              edoCustomValue: customEDO
            }
          }
        }
      })
    },
    [selectedEDOs, supplier?._id, supplier?.edoCustomValue, supplier?.edoStatus, updateSupplierEDOs]
  )

  function removeCompanyBranchFunc() {
    if (!deletingEntity) {
      return
    }
    return removeCompanyBranch({ variables: { input: { id: deletingEntity._id.toString() } } }).then(() => refetch())
  }

  const suppliesTableConfig = useMemo<GridViewConfig<Supply>>(
    () => ({
      grid: 'grid-cols-auto-5',
      columns: [
        { title: 'ID', value: '_id', numeric: true },
        { title: 'Сумма поставки', value: (s) => formatDecimal(s?.amount), numeric: true },
        { title: 'Статус отгрузки', value: (s) => (s?.shippingDone ? 'Полностью отгружено' : 'Ожидается') },
        {
          title: 'Дней проср.',
          value: (s) => (s?.shippingDate ? daysDiff(s.shippingDate, s?.factShippingDate) : 0),
          numeric: true
        },
        {
          title: 'МЛ',
          padding: false,
          value: (s) => <AvatarTooltip className='mx-auto' userId={s?.deal?.user?._id} />
        }
      ],
      rows: {
        link: (s) => `/deals/${s?.deal?._id}/supplies`
      }
    }),
    []
  )

  if (!supplier) return null

  return (
    <div className='grid grid-cols-2 gap-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Просроченные отгрузки</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <GridView
              config={suppliesTableConfig}
              data={supplies as Supply[]}
              loading={loading}
              error={error?.message}
            />
          </div>
        </Card>

        <ContactInfo
          onDone={() => refetch()}
          contactInfos={contactInfos}
          targetId={supplier?._id}
          targetType={ContactInfoKind.SupplierCompany}
          abilityCreate='supplier_company.contact_info.create'
          abilityEdit='supplier_company.contact_info.edit'
          title='Контакты поставщика'
        />

        <CompanyInfo
          dadata={dadata}
          companyName={data?.supplierCompany?.dadata?.value}
          refreshDadata={refreshDadata}
          loading={loadingRefreshDadata || loading}
          lastUpdateName={
            supplier.lastDadataRequestUser?.surname &&
            supplier.lastDadataRequestUser?.name &&
            supplier.lastDadataRequestUser.surname + ' ' + supplier.lastDadataRequestUser.name
          }
          lastUpdateDate={supplier.lastDadataRequestDate}
        />

        <Card>
          <CardHeader>
            <CardTitle>Категории поставщика</CardTitle>
            <CardMenu>
              <CardIconButton onClick={() => setFormOpen(true)}>
                <EditIcon />
              </CardIconButton>
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <LeasingSubjectCategoriesList categories={categories} />
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Адреса</CardTitle>
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='w-70 rounded-l-xl p-6'>Юридический адрес</td>
                    <td className='rounded-r-xl p-6'>{address?.unrestricted_value}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        <Requisites
          targetId={supplier?._id}
          targetType='supplierCompany'
          requisites={requisites}
          onDone={() => refetch()}
        />

        <Card>
          <CardHeader>
            <CardTitle>Филиалы (КПП)</CardTitle>
          </CardHeader>

          {IP ? (
            <p className='mb-8 px-8'>У ИП не может быть филиалов</p>
          ) : (
            <div className='px-5 pb-5'>
              {!!companyBranches?.length && (
                <table className='mb-8 w-full rounded-md bg-white-0 p-4 text-left shadow-card'>
                  <tbody>
                    {companyBranches.map((companyBranch) => (
                      <tr className='group border-b-1 border-separators-primary last:border-0' key={companyBranch?._id}>
                        <td className='rounded-l-xl p-6 text-grayscale-150'>{companyBranch?.name}</td>
                        <td className='p-6'>{companyBranch?.kpp}</td>
                        <td className='w-30 rounded-r-xl p-6'>
                          <Dropdown>
                            <DropdownButton
                              action='edit'
                              onClick={() => {
                                setEditEntity(companyBranch)
                                setCompanyBranchFormOpen(true)
                              }}
                            />
                            <DropdownButton
                              action='delete'
                              onClick={() => {
                                setDeletingEntity(companyBranch)
                                setDeletionFormOpen(true)
                              }}
                            />
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <AddButton
                onClick={() => {
                  setEditEntity(undefined)
                  setCompanyBranchFormOpen(true)
                }}
              >
                Новый филиал
              </AddButton>
            </div>
          )}
        </Card>
      </div>

      <div className='col-span-1 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Менеджеры Fera</CardTitle>
            <CardMenu>
              <CardIconButton onClick={() => setManagersFormOpen(true)}>
                <EditIcon />
              </CardIconButton>
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='p-6'>Менеджер по привлечению</td>
                    <td className='p-6'>
                      <div className='flex items-center'>
                        <Avatar className='mr-5' userId={supplier?.engagePartnerBdmUser?._id} />
                        {getFullName(supplier?.engagePartnerBdmUser) || '—'}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        <Tasks targetId={supplier?._id} targetType={TaskTargetType.Supplier} />

        <Comments targetId={supplier?._id} targetType={CommentTargetType.SupplierCompany} />

        <CompanyCheckFinmon inn={supplier?.inn} />

        <AccreditationStatusSwitcher supplier={supplier} onDone={() => refetch()} />

        <Card>
          <CardHeader>
            <CardTitle>Источники поставщика</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <SourcesList inn={supplier?.inn} />
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>
              НДС
              {!hasAccessVatManage && (
                <div className='mt-5 text-p100 text-grayscale-0'>
                  {supplier?.vatKind ? vatKindDict[supplier?.vatKind] : 'Не выбрана'}
                </div>
              )}
            </CardTitle>
          </CardHeader>
          {hasAccessVatManage && (
            <div className='px-5 pb-5'>
              <div className='rounded-md bg-surface-primary p-6 shadow-card'>
                <RadioButtonsGroup
                  onChange={onVATChange}
                  options={[
                    ['ОСНО', VatKind.Osno],
                    ['УСН', VatKind.Usn],
                    ['Медицина', VatKind.Medicine]
                  ]}
                  checkedValue={supplier?.vatKind}
                />
              </div>
            </div>
          )}
        </Card>

        <EDO
          enabled={supplier?.edoStatus}
          selectedEDOs={selectedEDOs}
          customEDO={supplier?.edoCustomValue}
          onChange={onEDOChange}
          hasAccess={hasAccessEdoManage}
        />
      </div>

      <Modal open={formOpen} setOpen={setFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <CategoriesForm
            onDone={() => {
              refetch()
              setFormOpen(false)
            }}
            entity={supplier}
          />
        </div>
      </Modal>
      <Modal open={companyBranchFormOpen} setOpen={setCompanyBranchFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <CompanyBranchForm
            supplierCompany={supplier?._id}
            supplierCompanyInn={supplier?.inn}
            onDone={() => {
              setCompanyBranchFormOpen(false)
              refetch()
            }}
            entity={editEntity}
          />
        </div>
      </Modal>
      <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление филиала'
            onDone={() => {
              setDeletionFormOpen(false)
              removeCompanyBranchFunc()
            }}
            onDismiss={() => setDeletionFormOpen(false)}
          >
            Вы действительно хотите удалить филиал?
          </ConfirmationForm>
        </div>
      </Modal>
      <Modal open={managersFormOpen} setOpen={setManagersFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ManagersForm
            supplierId={id!}
            managerId={supplier.engagePartnerBdmUser?._id}
            onDone={() => {
              refetch()
              setManagersFormOpen(false)
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Info
