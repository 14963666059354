import { FC, useEffect, useMemo, useState } from 'react'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import type { CompanyData } from '../../types/dadata'
import Input from '../../components/Input'
import Select from '../../components/Select'
import { useCompanySuggestions } from '../../hooks/useSuggestions'
import useThrottledState from '../../hooks/useThrottledState'
import {
  useAddApplicationMutation,
  useCategoriesQuery,
  useSourcesQuery,
  useUpdateApplicationMutation,
  LeasingSubjectCategory,
  useApplicationFormDataQuery,
  useUsersQuery,
  UserRoles
} from '../../graphql/schema'
import { EMAIL_PATTERN, PHONE_MASK } from '../../utils/constants'
import Suggestions from '../../components/Suggestions'
import { ApolloError } from '@apollo/client'
import useNodes from '../../hooks/useNodes'
import MaskedInput from '../../components/MaskedInput'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import SubmitButton from '../SubmitButton'
import GroupSelectInput, { Option } from '../GroupSelectInput'
import Highlighted from '../Highlighted'
import getCompanyName from '../../utils/getCompanyName'
import c from 'clsx'
import ManagerInput from './Inputs/ManagerInput'
import useCurrentUser from '../../hooks/useCurrentUser.ts'
import getFullName from '../../utils/getFullName.ts'
import sortUsersByAcivation from '../../utils/sortUsersByActivation.ts'

type Inputs = {
  sourceId: string
  sourceName: string
  company: string
  phone: string
  fio: string
  email: string
  leasingSubjectCategories: LeasingSubjectCategory[]
  user?: number
  userFio?: string
  userOnVacation?: boolean
}

interface AppFormProps {
  onDone?: () => void
  id?: number
}

const BACKOFFICE_CHANNEL = 'backoffice'

const ApplicationForm: FC<AppFormProps> = ({ onDone, id }) => {
  const editMode = id !== undefined
  const { data, loading: dataLoading } = useApplicationFormDataQuery({
    variables: { id: id?.toString() as string },
    skip: !editMode
  })
  const application = data?.application

  const { data: usersData } = useUsersQuery({
    variables: { order: [{ name: 'asc' }], roles: UserRoles.RoleManager }
  })
  const users = useNodes(usersData?.users?.edges)
  users.sort(sortUsersByAcivation)

  const currentUser = useCurrentUser()

  const [addApplication] = useAddApplicationMutation()
  const [updateApplication] = useUpdateApplicationMutation()

  const { register, reset, handleSubmit, watch, setValue, formState, setError, control } = useForm<Inputs>({
    defaultValues: {
      sourceId: application?.source?._id?.toString() || '',
      sourceName: application?.source?.name || '',
      user: application?.user?._id
    }
  })

  const [company, setCompany] = useState<CompanyData>()
  const [selectedCategories, setSelectedCategories] = useState<Option[]>([])

  const companyQuery = watch('company')
  const sourceId = watch('sourceId')
  const user = watch('user')
  const userOnVacation = watch('userOnVacation')

  const [throttledCompanyQuery] = useThrottledState(companyQuery, 500)
  const suggestions = useCompanySuggestions(throttledCompanyQuery)

  const { data: sourcesData } = useSourcesQuery({ fetchPolicy: 'cache-and-network' })
  const sources = useNodes(sourcesData?.sources?.edges)

  const [querySource, setQuerySource] = useState('')
  const sourcesList = useMemo(() => {
    if (querySource) {
      const queryParts = querySource.toLowerCase().replace(/\s\s/g, ' ').trim().split(' ')
      return sources.filter((source) => queryParts.every((part) => source?.name.toLowerCase().includes(part)))
    }
    return sources
  }, [querySource, sources])

  const { data: categoriesData } = useCategoriesQuery()
  const categories = useNodes(categoriesData?.leasingSubjectCategories?.edges)
  const options = useMemo(
    () =>
      categories?.map((cat) => ({
        name: cat.name,
        value: cat._id,
        group: cat.categoryGroup?.name
      })) || [],
    [categories]
  )
  const defaultLeasingSubjectCategories = useMemo(
    () => application?.leasingSubjectCategories?.edges?.map((l) => l?.node) as LeasingSubjectCategory[],
    [application?.leasingSubjectCategories]
  )
  useEffect(() => {
    setSelectedCategories(
      options?.filter((option) => defaultLeasingSubjectCategories?.map((cat) => cat._id).includes(option.value)) || []
    )
  }, [options, defaultLeasingSubjectCategories])

  // при выборе компании устанавливаем её имя в поле компании
  useEffect(() => {
    if (!company) return
    setValue('company', getCompanyName(company), {
      shouldValidate: true
    })
  }, [company, setValue])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!editMode) {
      await addApplication({
        variables: {
          input: {
            fio: data.fio,
            advanceRate: undefined,
            durationMonths: undefined,
            inn: company?.inn as string,
            source: data?.sourceId,
            phone: '+' + data.phone.replace(/\D/g, ''),
            channel: BACKOFFICE_CHANNEL,
            leasingSubjectCategories: selectedCategories.map((lc) => lc.value.toString()),
            email: data?.email || undefined,
            user: data?.userOnVacation ? undefined : data?.user?.toString()
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    } else {
      if (!application) return
      await updateApplication({
        variables: {
          input: {
            id: application._id.toString(),
            channel: application.channel || undefined,
            comment: application.comment || undefined,
            source: data.sourceId,
            leasingSubjectCategories: selectedCategories.map((lc) => lc.value.toString()),
            user: data?.user?.toString() || undefined
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    }
  }

  return (
    <section className={c('w-[448px] p-12', !editMode && 'lg:w-[912px]')}>
      <h1 className='mb-12 font-display text-h200'>{id ? 'Редактирование заявки' : 'Новая заявка'}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {editMode ? (
          <div className='relative mb-12 flex flex-col gap-12'>
            <Select
              label='Источник'
              type='text'
              editable={true}
              autoComplete='off'
              throttleTime={500}
              onQuery={(query) => {
                setQuerySource(query)
              }}
              {...register('sourceName', { required: true })}
              error={formState.errors.sourceName?.message}
            >
              {sourcesList?.map((s) => (
                <li
                  key={s?._id}
                  onClick={() =>
                    reset({
                      sourceName: s?.name || '',
                      sourceId: s?._id?.toString() || ''
                    })
                  }
                  className={c(
                    sourceId === s?._id?.toString() && 'bg-grayscale-400',
                    'cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                  )}
                >
                  <div className='mb-1 text-grayscale-0'>
                    <Highlighted
                      classMarkName='text-red-100 bg-transparent'
                      text={s?.name || ''}
                      highlight={querySource}
                    />
                  </div>
                </li>
              ))}
            </Select>
            <div>
              <GroupSelectInput
                label='Категории лизинга'
                placeholder='Выбрать категории'
                options={options}
                selectedOptions={selectedCategories}
                onChange={setSelectedCategories}
                error={formState.errors.leasingSubjectCategories?.message}
              />
            </div>

            <Controller
              name='user'
              control={control}
              render={({ field }) => (
                <ManagerInput
                  selected={field.value ? [field.value] : []}
                  onChange={(selected) => field.onChange(selected[selected.length - 1])}
                  label='Менеджер по лизингу'
                  placeholder='Выберите менеджера'
                  error={formState.errors?.user}
                  disabled={
                    application?.status !== 'Закрыто и не реализовано' && application?.status !== 'Сделка'
                      ? false
                      : true
                  }
                />
              )}
            />
          </div>
        ) : (
          <div className='mb-12 grid grid-cols-1 gap-20 lg:grid-cols-2'>
            <div className='relative flex flex-col gap-12'>
              <Suggestions<CompanyData>
                suggestions={suggestions?.map((s) => ({
                  key: s._id,
                  title: getCompanyName(s),
                  subtitle: `${s.inn} ${s.address?.value || ''}`,
                  payload: s,
                  lineThrough: !!s.state.liquidation_date
                }))}
                select={(suggestion) => setCompany(suggestion.payload)}
              >
                <Input
                  label='Компания, ИП или ИНН'
                  type='text'
                  autoComplete='off'
                  {...register('company', {
                    required: true,
                    validate: {
                      // требуем выбора из предложенных вариантов только если апи подсказок работает
                      fromSuggested: (value) => {
                        return value !== getCompanyName(company) ? 'Требуется выбрать один из вариантов' : true
                      }
                    }
                  })}
                  error={formState.errors.company}
                />
              </Suggestions>
              <Input
                label='Email'
                type='text'
                placeholder='mail@example.com'
                {...register('email', {
                  pattern: {
                    value: EMAIL_PATTERN,
                    message: 'Некорректный E-mail'
                  }
                })}
                error={formState.errors.email}
              />
              <Select
                label='Источник'
                type='text'
                editable={true}
                autoComplete='off'
                throttleTime={500}
                onQuery={(query) => {
                  setQuerySource(query)
                }}
                {...register('sourceName', { required: true })}
                error={formState.errors.sourceName?.message}
              >
                {sourcesList?.map((s) => (
                  <li
                    key={s?._id}
                    onClick={() => {
                      reset({
                        sourceName: s?.name || '',
                        sourceId: s?._id?.toString() || '',
                        user: s.user?._id,
                        userFio: getFullName(s?.user),
                        userOnVacation: s.user?.onVacation
                      })
                    }}
                    className={c(
                      sourceId === s?._id?.toString() && 'bg-grayscale-400',
                      'cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                    )}
                  >
                    <div className='mb-1 text-grayscale-0'>
                      <Highlighted
                        classMarkName='text-red-100 bg-transparent'
                        text={s?.name || ''}
                        highlight={querySource}
                      />
                    </div>
                  </li>
                ))}
              </Select>

              <div>
                <div className='relative'>
                  <Select
                    type='text'
                    label='Менеджер'
                    placeholder='Выберите менеджера'
                    autoComplete='off'
                    {...register('userFio')}
                    error={formState.errors?.user}
                  >
                    {users.map((user) => (
                      <li
                        key={user._id}
                        onClick={() => {
                          if (!user.isActive) return
                          setValue('user', user?._id)
                          setValue('userFio', getFullName(user))
                          setValue('userOnVacation', user.onVacation)
                        }}
                        className='cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                      >
                        <div className={c(user.isActive ? 'text-grayscale-0' : 'text-grayscale-150')}>
                          {getFullName(user)} {!user.isActive ? ' (Деактивирован)' : ''}
                        </div>
                      </li>
                    ))}
                  </Select>
                  {user !== currentUser?._id && (
                    <button
                      type='button'
                      className='absolute right-10 top-24 text-sm text-grayscale-200 hover:text-red-100'
                      onClick={() => {
                        setValue('user', currentUser?._id)
                        setValue('userFio', getFullName(currentUser))
                        setValue('userOnVacation', undefined)
                      }}
                    >
                      Назначить мне
                    </button>
                  )}
                </div>
                {userOnVacation && <div className='mt-5 text-[11px] text-base-red'>Менеджер находится в отпуске</div>}
              </div>
            </div>

            <div className='relative flex flex-col gap-12'>
              <Input
                label='ФИО'
                type='text'
                placeholder='Иванов Иван'
                {...register('fio', { required: true })}
                error={formState.errors.fio}
              />
              <MaskedInput
                label='Телефон'
                inputMode='tel'
                type='text'
                mask={PHONE_MASK}
                placeholder='+7 987 654 32 10'
                {...register('phone', {
                  required: true,
                  minLength: {
                    value: 16,
                    message: 'Введите номер телефона полностью'
                  }
                })}
                error={formState.errors.phone}
              />
              <div>
                <GroupSelectInput
                  label='Категории лизинга'
                  placeholder='Выбрать категории'
                  options={options}
                  selectedOptions={selectedCategories}
                  onChange={setSelectedCategories}
                  error={formState.errors.leasingSubjectCategories?.message}
                />
              </div>
            </div>
          </div>
        )}
        <SubmitButton loading={dataLoading || formState.isSubmitting}>
          {!editMode ? (
            <>
              <PlusIcon className='mr-5' />
              Создать
            </>
          ) : (
            'Сохранить'
          )}
        </SubmitButton>
      </form>
    </section>
  )
}

export default ApplicationForm
