import { useReactiveVar } from '@apollo/client'
import { jwtVar } from './graphql/cache'

import Auth from './containers/Auth'
import Layout from './containers/Layout'
import { useEffect } from 'react'
import { monitorUserActivity, stopMonitoringUserActivity } from './utils/checkUserActivity'

function App() {
  const authenticated = !!useReactiveVar(jwtVar)

  useEffect(() => {
    // Начинаем отслеживание активности пользователя
    monitorUserActivity()

    // Очищаем при размонтировании компонента
    return () => {
      stopMonitoringUserActivity()
    }
  }, [])

  return <>{authenticated ? <Layout /> : <Auth />}</>
}

export default App
