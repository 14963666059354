import { FC, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

interface ModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  children?: React.ReactNode
}

const Modal: FC<ModalProps> = ({ children, open, setOpen }) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog className='fixed inset-0 z-50 overflow-y-auto' open={open} onClose={() => setOpen(false)}>
        <div className='flex items-center justify-center min-h-full'>
          <Transition.Child
            as={Fragment}
            enter='duration-500'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='duration-500'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-grayscale-0/30' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
