import { FC, Fragment, Suspense, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ScoringProcessStatus, useScoringProcessSuspenseQuery } from '../../../graphql/schema.tsx'
import { ReactComponent as LoadingIcon } from '../../../svg/icons/loading.svg'
import { ReactComponent as ChevronIcon } from './../../../svg/icons/chevron-fat.svg'
import { ReactComponent as InProgressIcon } from './../../../svg/icons/appScoringStatusInProgress.svg'
import { ReactComponent as PauseIcon } from './../../../svg/icons/appScoringStatusPause.svg'
import { ReactComponent as DoneIcon } from './../../../svg/icons/appScoringStatusDone.svg'
import { scoringProcessStatusDict } from '../../../utils/dictionaries.ts'
import useChangeStatus from './useChangeStatus.ts'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import WaitingForAdditionalInfoForm from './WaitingForAdditionalInfoForm.tsx'
import c from 'clsx'

const listStatuses: ScoringProcessStatus[] = Object.keys(scoringProcessStatusDict) as ScoringProcessStatus[]

const statusIcons = {
  [ScoringProcessStatus.InProgress]: <InProgressIcon />,
  [ScoringProcessStatus.WaitingForAdditionalInfo]: <PauseIcon />,
  [ScoringProcessStatus.Done]: <DoneIcon />
}

interface ScoringProcessStatusSwitcherProps {
  id: number
  userId?: number
}

interface ScoringProcessStatusSwitcherInnerProps {
  id: number
  changeStatus: (status: ScoringProcessStatus) => Promise<void>
}

const ScoringProcessStatusSwitcherInner: FC<ScoringProcessStatusSwitcherInnerProps> = ({ id, changeStatus }) => {
  const { data: scoringProcessData } = useScoringProcessSuspenseQuery({ variables: { applicationId: id } })
  const scoringProcess = scoringProcessData?.scoringProcess
  const scoringProcessStatus = scoringProcess?.status

  return (
    <>
      {listStatuses?.map((status) => (
        <Menu.Item key={status}>
          <div
            onClick={() => changeStatus(status)}
            className={c(
              'flex cursor-pointer items-center gap-4 px-8 py-5 text-grayscale-150 first:rounded-t-xl first:pt-9 last:rounded-b-xl last:pb-9 hover:bg-grayscale-400',
              status === scoringProcessStatus && 'pointer-events-none text-red-100'
            )}
          >
            {statusIcons[status]}
            {scoringProcessStatusDict[status]}
          </div>
        </Menu.Item>
      ))}
    </>
  )
}

// wrap ScoringProcessStatusSwitcherInner with suspense
const ScoringProcessStatusSwitcher: FC<ScoringProcessStatusSwitcherProps> = ({ id, userId }) => {
  const [formOpen, setFormOpen] = useState(false)
  const [changeStatus, loading] = useChangeStatus(id)

  const { data: scoringProcessData, refetch } = useScoringProcessSuspenseQuery({ variables: { applicationId: id } })
  const scoringProcess = scoringProcessData?.scoringProcess
  const scoringProcessStatus = scoringProcess?.status

  return (
    <div className='relative'>
      <Menu>
        <Menu.Button
          disabled={loading}
          className='flex h-16 items-center gap-4 rounded-md bg-surface-primary px-5 py-3 text-grayscale-150 shadow-card hover:bg-grayscale-450'
        >
          {scoringProcessStatus && statusIcons[scoringProcessStatus]}
          {scoringProcessStatus && scoringProcessStatusDict[scoringProcessStatus]}
          {loading ? (
            <LoadingIcon className='w-7 animate-spin text-red-100' />
          ) : (
            <ChevronIcon className='transition-transform duration-200 ui-open:-rotate-180' />
          )}
        </Menu.Button>

        <Suspense
          fallback={
            <Menu.Items
              unmount={true}
              className='absolute mt-4 w-full rounded-xl border-[1px] border-grayscale-400 bg-white-0 text-p200 font-medium'
            >
              <div className='py-10'>
                <LoadingIcon className='mx-auto animate-spin text-red-100' />
              </div>
            </Menu.Items>
          }
        >
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              unmount={true}
              className='absolute z-10 mt-4 w-80 rounded-xl border-[1px] border-grayscale-400 bg-white-0'
            >
              <ScoringProcessStatusSwitcherInner
                id={id}
                changeStatus={async (status) => {
                  if (status === ScoringProcessStatus.WaitingForAdditionalInfo) {
                    setFormOpen(true)
                    return
                  }

                  await changeStatus(status)
                  await refetch()
                }}
              />
            </Menu.Items>
          </Transition>
        </Suspense>
      </Menu>

      <SuspenseModal open={formOpen} setOpen={setFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <WaitingForAdditionalInfoForm
            applicationId={parseInt(`${id}`)}
            scoringProcessId={parseInt(`${scoringProcess?.id}`)}
            managerId={userId}
            onDone={async () => {
              await changeStatus(ScoringProcessStatus.WaitingForAdditionalInfo)
              await refetch()
              setFormOpen(false)
            }}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default ScoringProcessStatusSwitcher
