import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { CustomerAccountKind, useDealForCollectionProcessQuery } from '../../../graphql/schema'
import CustomerCompanyBalance from '../../../components/CustomerCompanyBalance'
import CollectionPayments from './CollectionPayments'
import { Card } from '../../../components/Card.tsx'
import Collector from './Collector.tsx'

const Collection: FC = () => {
  const { id } = useParams<'id'>()
  const { data } = useDealForCollectionProcessQuery({ variables: { id: `${id}` }, skip: !id })
  const deal = data?.deal

  if (!deal) return null

  return (
    <Card>
      <Collector dealId={deal._id} collectorId={deal?.collector?._id} />

      <div className='grid grid-cols-2 gap-5 px-5 pt-5'>
        <CustomerCompanyBalance
          customerId={deal?.customerCompany?._id}
          title='Баланс'
          kind={CustomerAccountKind.Main}
        />
        <CustomerCompanyBalance
          customerId={deal?.customerCompany?._id}
          title='Баланс пеней'
          kind={CustomerAccountKind.Penalty}
        />
      </div>

      <CollectionPayments dealId={deal?._id} />
    </Card>
  )
}

export default Collection
