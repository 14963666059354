import { FC } from 'react'
import { ReactComponent as CrossIcon } from '../../svg/icons/cross.svg'
import { ReactComponent as CheckmarkActualIcon } from '../../svg/icons/circleCheck.svg'
import { ReactComponent as CheckmarkNotActualIcon } from '../../svg/icons/circleEmpty.svg'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { TaskState } from '../../graphql/schema'
import Dropdown from '../Dropdown'
import Tooltip from '../Tooltip'
import TaskAvatar from './TaskAvatar'
import TaskTarget from './TaskTarget'
import useCurrentUser from '../../hooks/useCurrentUser'
import { dateTimeFormatter, timeFormatter } from '../../utils/dateFormatter'
import { taskKindColorDict, taskKindDict } from '../../utils/dictionaries'
import { Task } from './index'
import c from 'clsx'
import { useNavigate } from 'react-router-dom'
import { getEntityLink } from '../../utils/entityLink'
import { showPopup } from '../Toaster/showPopup.tsx'

interface TasksByDateProps {
  tasks: Task[]
  onDelete: (id: string) => void
  onPending: (id: string) => void
  onDone: (task: Task) => void
  onEdit: (task: Task) => void
  main?: boolean
}

const TasksByDate: FC<TasksByDateProps> = ({ tasks, onDelete, onDone, onPending, onEdit, main }) => {
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  return (
    <table className='w-full text-center'>
      <thead className='text-grayscale-200'>
        <tr>
          <th className='border-r border-grayscale-400' />
          {main && <th className='border-r border-grayscale-400 px-5 py-7'>Связь</th>}
          <th className='border-r border-grayscale-400 px-5 py-7'>Описание</th>
          <th className='border-r border-grayscale-400 px-5 py-7'>Тип задачи</th>
          <th className='border-r border-grayscale-400 px-5 py-7'>Время</th>
          <th className='border-r border-grayscale-400 px-5 py-7' />
          <th />
        </tr>
      </thead>
      <tbody>
        {!!tasks?.length &&
          tasks.map((task) => {
            const canManage = currentUser?._id === task?.authorId || currentUser?._id === task?.userId

            return (
              <tr key={task.id}>
                <td className='w-20 border-r border-t border-grayscale-400'>
                  {task.state === TaskState.Done ? (
                    <div
                      onClick={() => {
                        if (!canManage) return

                        onPending(task.id)
                      }}
                    >
                      <Tooltip target={<CheckmarkActualIcon className='mx-auto cursor-pointer text-green-600' />}>
                        <div className='flex items-center gap-4'>
                          {task?.doneAt && <div>Выполнена: {dateTimeFormatter.format(new Date(task.doneAt))}</div>}
                          {task?.doneByUserId && <TaskAvatar userId={task.doneByUserId} />}
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <CheckmarkNotActualIcon
                      className='mx-auto cursor-pointer'
                      onClick={() => {
                        if (!canManage)
                          return showPopup({
                            title: 'Ошибка',
                            subtitle: 'Вы не можете выполнить чужую задачу'
                          })

                        onDone(task)
                      }}
                    />
                  )}
                </td>
                {main && (
                  <td
                    onClick={() =>
                      task?.targetType && task?.targetId && navigate(getEntityLink(task.targetType, task.targetId))
                    }
                    className='group w-70 cursor-pointer border-r border-t border-grayscale-400 px-5 py-7'
                  >
                    {task?.targetId && task?.targetType ? (
                      <TaskTarget targetId={task.targetId} targetType={task.targetType} />
                    ) : (
                      <p className='text-grayscale-150'>Нет связи</p>
                    )}
                  </td>
                )}
                <td className='min-w-[285px] max-w-[285px] break-words border-r border-t border-grayscale-400 px-5 py-7'>
                  {task?.body}
                </td>
                <td className='w-60 border-r border-t border-grayscale-400'>
                  <div className='flex'>
                    <div className={c('mx-auto rounded-3xl px-5 py-3', taskKindColorDict[task?.kind])}>
                      {taskKindDict[task?.kind]}
                    </div>
                  </div>
                </td>
                <td
                  className={c(
                    'w-35 border-r border-t border-grayscale-400',
                    !!task.dueDate &&
                      task?.isDueDateTimeSpecified &&
                      new Date(task.dueDate) < new Date() &&
                      'text-red-100'
                  )}
                >
                  {!!task.dueDate && task?.isDueDateTimeSpecified ? timeFormatter.format(new Date(task.dueDate)) : '—'}
                </td>
                <td className='w-30 border-r border-t border-grayscale-400'>
                  <div className='mx-auto w-30'>
                    <TaskAvatar userId={task?.userId} authorId={task?.authorId} />
                  </div>
                </td>
                <td className='w-25 border-t border-grayscale-400'>
                  <Dropdown className='mx-auto'>
                    {canManage && task.state !== TaskState.Done ? (
                      <button
                        className='flex items-center py-5 pl-4 pr-10 text-grayscale-200 hover:text-red-100'
                        onClick={() => onEdit(task)}
                      >
                        <EditIcon height='18px' width='18px' className='mr-6' title='Редактировать' />
                        Редактировать
                      </button>
                    ) : (
                      <></>
                    )}
                    {task?.authorId === null || currentUser?._id === task?.authorId ? (
                      <button
                        className='flex items-center py-5 pl-5 pr-10 text-red-100'
                        onClick={() => onDelete(task.id)}
                      >
                        <CrossIcon height='14px' width='14px' className='mr-7' title='Удалить' />
                        Удалить
                      </button>
                    ) : (
                      <></>
                    )}
                  </Dropdown>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

export default TasksByDate
