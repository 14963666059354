import { FC, useCallback, useMemo, useState } from 'react'
import { dateFormatter } from '../../../utils/dateFormatter.ts'
import { formatDecimal } from '../../../utils/formatNumber.ts'
import AddButton from '../../../components/AddButton'
import {
  CustomerAccountKind,
  DealInsuranceKind,
  DealStatus,
  Kind,
  PaymentAttributes,
  PaymentKind,
  PaymentsQuery,
  PaymentState,
  PaymentTargetType,
  useCancelPaymentTransactionsMutation,
  useCreatePaymentMutation,
  useCreatePaymentsMutation,
  useCustomerAccountQuery,
  useDealByIdQuery,
  useDealCalculationsQuery,
  useDealPenaltyAmountLazyQuery,
  useDealRedemptionScheduleQuery,
  useDeletePaymentMutation,
  useDeletePaymentsMutation,
  usePaymentsQuery,
  usePayPaymentMutation,
  UserRoles,
  useScheduleByDealParamsLazyQuery
} from '../../../graphql/schema.tsx'
import useNodes from '../../../hooks/useNodes.ts'
import Modal from '../../../components/Modal.tsx'
import PaymentForm from './PaymentForm.tsx'
import { paymentKindDict } from '../../../utils/dictionaries.ts'
import GridView, { GridViewConfig } from '../../../components/GridView'
import { NodeType } from '../../../types'
import { throttle } from 'throttle-debounce'
import ConfirmationForm from '../../../components/ConfirmationForm.tsx'
import { REGULAR_VAT_RATE } from '../../../utils/constants.ts'
import PaymentActions, { PaymentActionType, PaymentActionsIcon } from './PaymentActions.tsx'
import { ReactComponent as WarningIcon } from '../../../svg/ui/warning.svg'
import Dropdown from '../../../components/Dropdown'
import { ReactComponent as DeleteIcon } from '../../../svg/ui/delete.svg'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import TransactionsDateForm from './TransactionsDateForm.tsx'
import PenaltyForm from './PenaltyForm.tsx'
import usePaymentsAccessCheck from './usePaymentsAccessCheck.ts'
import parseDecimal from '../../../utils/parseDecimal.ts'
import useCurrentUser from '../../../hooks/useCurrentUser.ts'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../../components/Card.tsx'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'

interface PaymentsProps {
  dealId: number
}

export type Payment = NodeType<PaymentsQuery['payments']> & { penaltyPayments?: Payment[] }

const Payments: FC<PaymentsProps> = ({ dealId }) => {
  const currentUser = useCurrentUser()
  const isAdmin = currentUser?.roles.includes(UserRoles.RoleAdmin)

  const { data: dealData } = useDealByIdQuery({ variables: { id: `${dealId}` }, skip: !dealId })
  const deal = dealData?.deal

  const {
    canPaymentsManage,
    canServicePaymentsCreate,
    canDebtTransferServicePaymentsCreate,
    canPenaltyPaymentsCreate,
    canPenaltyPaymentsDelete,
    canPaymentsEditTransactionsDate,
    canPayPayment
  } = usePaymentsAccessCheck(deal?.status)

  const canLeasingPaymentsGraphDeleteAfterActivation = useAccessCheck(
    'deal.leasing_payments_graph.delete_after_deal_activation'
  )

  const { data: mainCustomerAccountData, refetch: refetchMainCustomerAccount } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${deal?.customerCompany?._id}`, kind: CustomerAccountKind.Main },
    skip: !deal?.customerCompany?._id
  })
  const mainCustomerAccount = mainCustomerAccountData?.customerAccount

  const { data: penaltyCustomerAccountData, refetch: refetchPenaltyCustomerAccount } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${deal?.customerCompany?._id}`, kind: CustomerAccountKind.Penalty },
    skip: !deal?.customerCompany?._id
  })
  const penaltyCustomerAccount = penaltyCustomerAccountData?.customerAccount

  const insuranceRate = deal?.insuranceAmount ? Math.round((deal?.insuranceAmount / deal?.amount) * 10000) / 100 : 0

  const { data: dealCalculationsData } = useDealCalculationsQuery({
    variables: {
      dealParams: {
        amount: deal?.amount / 100 || 0,
        advanceRate: deal?.advanceRate || 0,
        interestRate: deal?.interestRate || 0,
        durationMonths: deal?.durationMonths || 0,
        comissionRate: deal?.comissionRate || 0,
        insuranceRate: deal?.insuranceKind === DealInsuranceKind.WithoutInsurance ? 0 : insuranceRate,
        vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
        advancePaymentDate: deal?.advancePaymentDate || undefined
      }
    },
    skip:
      !deal ||
      deal.amount === null ||
      deal.advanceRate === null ||
      deal.durationMonths === null ||
      deal.comissionRate === null ||
      deal.advancePaymentDate === null
  })
  const dealCalculations = dealCalculationsData?.dealCalculations

  const {
    data: basePaymentsData,
    refetch: refetchBasePayments,
    loading: basePaymentsLoading,
    error: basePaymentsError
  } = usePaymentsQuery({
    variables: {
      kinds: [
        PaymentKind.Advance,
        PaymentKind.Commission,
        PaymentKind.Redemption,
        PaymentKind.Insurance,
        PaymentKind.Service,
        PaymentKind.Compensation,
        PaymentKind.DebtTransferService,
        PaymentKind.Buyout,
        PaymentKind.Body
      ],
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })
  const basePayments = useNodes(basePaymentsData?.payments.edges)
  const flatBasePayments = useMemo(
    () => basePayments.flatMap((payment) => [payment, ...payment.penaltyPayments] as Payment[]),
    [basePayments]
  )

  const kinds: PaymentKind[] = useMemo(() => {
    if (canPaymentsManage) {
      const baseKinds = [
        PaymentKind.Advance,
        PaymentKind.Commission,
        PaymentKind.Insurance,
        PaymentKind.Redemption,
        PaymentKind.Buyout,
        PaymentKind.Body
      ].filter((kind) => !basePayments.find((payment) => payment.kind === kind))

      if (!baseKinds.length) {
        return [PaymentKind.Service, PaymentKind.Compensation, PaymentKind.DebtTransferService]
      }
      return [...baseKinds, PaymentKind.Service, PaymentKind.Compensation, PaymentKind.DebtTransferService]
    }
    if (canServicePaymentsCreate && canDebtTransferServicePaymentsCreate) {
      return [PaymentKind.Service, PaymentKind.DebtTransferService]
    }
    if (canServicePaymentsCreate) {
      return [PaymentKind.Service]
    }
    if (canDebtTransferServicePaymentsCreate) {
      return [PaymentKind.DebtTransferService]
    }
    return []
  }, [canPaymentsManage, canServicePaymentsCreate, basePayments])

  const {
    data: leasingPaymentsData,
    refetch: refetchLeasingPayments,
    loading: leasingPaymentsLoading,
    error: leasingPaymentsError
  } = usePaymentsQuery({
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })
  const leasingPayments = useNodes(leasingPaymentsData?.payments.edges)
  const flatLeasingPayments = useMemo(
    () => leasingPayments.flatMap((payment) => [payment, ...payment.penaltyPayments] as Payment[]),
    [leasingPayments]
  )

  const { data: dealRedemptionScheduleData } = useDealRedemptionScheduleQuery({
    variables: { dealId: dealId.toString() }
  })
  const dealRedemptionSchedules = dealRedemptionScheduleData?.dealRedemptionSchedule

  // для создания графика
  const [createPayments] = useCreatePaymentsMutation()

  // для создания пеней автоматически, без открытия формы
  const [createPayment] = useCreatePaymentMutation()

  const [penaltyForDeal] = useDealPenaltyAmountLazyQuery()

  const [deletePayment] = useDeletePaymentMutation()

  const [deletePayments] = useDeletePaymentsMutation()

  const [payPaymentMutation] = usePayPaymentMutation()

  const [deletionFormOpenByPaymentId, setDeletionFormOpenByPaymentId] = useState<string>()
  const [disableBasePayments, setDisableBasePayments] = useState(false)
  const [disableSchedule, setDisableSchedule] = useState(false)
  const [basePaymentFormOpen, setBasePaymentFormOpen] = useState(false)
  const [leasingPaymentFormOpen, setLeasingPaymentFormOpen] = useState(false)
  const [penaltyFormOpenByPaymentId, setPenaltyFormOpenByPaymentId] = useState<string>()
  const [leasingPaymentsDeletionFormOpen, setLeasingPaymentsDeletionFormOpen] = useState(false)
  const [editTransactionsFormOpenByPaymentId, setEditTransactionsFormOpenByPaymentId] = useState<string>()
  const [penaltyCalculationLoading, setPenaltyCalculationLoading] = useState(false)

  const readyPaymentsCreate = Boolean(
    deal?.contractNumber &&
      deal?.contractDate &&
      deal?.advancePaymentDate &&
      deal?.supplyingStatus &&
      deal?.amount >= 0 &&
      deal?.durationMonths >= 0 &&
      deal?.advanceRate >= 0 &&
      deal?.interestRate >= 0 &&
      deal?.comissionRate >= 0 &&
      ((deal?.insuranceKind === DealInsuranceKind.WithInsurance && deal.insuranceAmount >= 0) ||
        deal?.insuranceKind === DealInsuranceKind.WithoutInsurance) &&
      deal?.vatRate >= 0 &&
      deal?.leasingSubjectCategories?.edges?.length &&
      deal?.status &&
      deal?.kind &&
      deal?.user
  )

  async function removeLeasingPayments() {
    if (!leasingPayments.length) {
      return
    }
    await deletePayments({
      variables: {
        input: {
          ids: leasingPayments.map((p) => p.id)
        }
      }
    })

    await Promise.allSettled([refetchBasePayments(), refetchLeasingPayments()])
  }

  const [cancelPaymentTransactions] = useCancelPaymentTransactionsMutation({
    onCompleted: () => {
      refetchMainCustomerAccount()
      refetchPenaltyCustomerAccount()
    }
  })

  const [scheduleByDealParams] = useScheduleByDealParamsLazyQuery()

  const cancelPaymentTransactionsCallback = useCallback(
    async (payment: Payment) => {
      await cancelPaymentTransactions({
        variables: {
          input: {
            paymentId: payment.id
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          cancelPaymentTransactions: {
            __typename: 'CancelPaymentTransactionsPayload',
            payment: {
              ...payment,
              state: PaymentState.Pending
            }
          }
        }
      })
    },
    [cancelPaymentTransactions]
  )

  const payPayment = useCallback(
    async (payment: { id: string }) => {
      if (!payment || !mainCustomerAccount?.id) return

      await payPaymentMutation({
        variables: {
          input: {
            customerAccountId: mainCustomerAccount?.id,
            paymentId: payment.id
          }
        }
      })

      await Promise.allSettled([refetchBasePayments(), refetchLeasingPayments(), refetchMainCustomerAccount()])
    },
    [
      mainCustomerAccount?.id,
      payPaymentMutation,
      refetchBasePayments,
      refetchMainCustomerAccount,
      refetchLeasingPayments
    ]
  )

  const payPenaltyPayment = useCallback(
    async (payment: { id: string }) => {
      if (!payment || !penaltyCustomerAccount?.id) return

      await payPaymentMutation({
        variables: {
          input: {
            customerAccountId: penaltyCustomerAccount.id,
            paymentId: payment.id
          }
        }
      })

      await Promise.allSettled([refetchLeasingPayments(), refetchPenaltyCustomerAccount()])
    },
    [penaltyCustomerAccount?.id, payPaymentMutation, refetchPenaltyCustomerAccount, refetchLeasingPayments]
  )

  const recountPenalty = useCallback(
    async (payment: { id: string; targetId: string }) => {
      if (!payment || !mainCustomerAccount?.id) return

      try {
        setPenaltyCalculationLoading(true)

        const { data: newPenaltyAmount } = await penaltyForDeal({ variables: { paymentId: payment.targetId } })

        await deletePayment({
          variables: {
            input: {
              id: payment.id
            }
          }
        })

        await createPayment({
          variables: {
            input: {
              amount: parseDecimal((newPenaltyAmount?.penaltyAmountForPayment.amount || '').toString()),
              date: new Date(),
              kind: PaymentKind.Penalty,
              targetId: payment.targetId,
              targetType: PaymentTargetType.Payment
            }
          }
        })

        await Promise.allSettled([refetchBasePayments(), refetchLeasingPayments()])
      } catch (e) {
        console.error(e)
      } finally {
        setPenaltyCalculationLoading(false)
      }
    },
    [mainCustomerAccount?.id, penaltyForDeal, deletePayment, createPayment, refetchBasePayments, refetchLeasingPayments]
  )

  const canPayBasePaymentId = basePayments.find((payment) => payment.state !== PaymentState.Paid)?.id
  const canPayLeasingPaymentId = leasingPayments.find(
    (payment) => payment.kind === PaymentKind.Leasing && payment.state !== PaymentState.Paid
  )?.id

  const basePaymentsConfig = useMemo<GridViewConfig<Payment>>(
    () => ({
      grid: 'grid-cols-payment-base',
      columns: [
        { title: 'Тип', value: (p) => paymentKindDict[p.kind] },
        { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)) },
        {
          title: 'Итого, ₽',
          value: (p) => (
            <div className='flex w-full justify-between gap-x-16'>
              {formatDecimal(p.amount * 100)}
              <PaymentActionsIcon payment={p} />
            </div>
          )
        },
        {
          value: (p) => {
            return (
              <div className='flex items-center justify-end gap-8'>
                {(canPaymentsManage || canPaymentsEditTransactionsDate) && (
                  <Dropdown className='empty:hidden'>
                    <PaymentActions
                      payment={p}
                      hasPenalties={Boolean(p.penaltyPayments.length)}
                      canPaymentsManage={canPaymentsManage}
                      canEditTransactionsDate={canPaymentsEditTransactionsDate}
                      canPayPayment={canPayPayment && p.id === canPayBasePaymentId}
                      triggerAction={async (type) => {
                        switch (type) {
                          case PaymentActionType.CancelPaymentTransactions:
                            await cancelPaymentTransactionsCallback(p)
                            break
                          case PaymentActionType.EditPaymentTransactions:
                            setEditTransactionsFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenPenaltyModal:
                            setPenaltyFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenDeletingEntityModal:
                            setDeletionFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.PayPayment:
                            await payPayment(p)
                            break
                        }
                      }}
                    />
                  </Dropdown>
                )}
              </div>
            )
          }
        }
      ]
      // rows: {
      //   className: (p) => {
      //     if (p.penaltyType === 'isOverdued') {
      //       return '!bg-red-200'
      //     } else if (p.penaltyType === 'partlyPaid') {
      //       return '!bg-yellow-50'
      //     }
      //     return ''
      //   }
      // }
    }),
    [
      canPaymentsManage,
      canPaymentsEditTransactionsDate,
      canPayPayment,
      canPayBasePaymentId,
      cancelPaymentTransactionsCallback,
      payPayment
    ]
  )

  const leasingPaymentsConfig = useMemo<GridViewConfig<Payment>>(
    () => ({
      grid: 'grid-cols-payment',
      columns: [
        {
          title: '№',
          value: (p) => {
            if (p.kind === PaymentKind.Penalty) {
              return ' '
            }
            return leasingPayments.findIndex((payment) => payment.id === p.id) + 1
          }
        },
        {
          title: '',
          value: (p) => {
            if (p.kind === PaymentKind.Penalty) {
              return 'Пени'
            }
            return ' '
          }
        },
        { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)) },
        {
          title: 'Итого, ₽',
          value: (p) => <div className='flex w-full justify-between gap-x-16'>{formatDecimal(p.amount * 100)}</div>
        },
        {
          title: 'Выкупная стоимость, ₽',
          value: (p) => {
            const paymentId = parseInt(p.id)
            const paymentRedemption = dealRedemptionSchedules?.find((redemption) => redemption.paymentId === paymentId)

            return (
              <div className='flex w-full justify-between gap-x-16'>
                {isAdmin && paymentRedemption ? formatDecimal(paymentRedemption.redemption * 100) : '-'}
                <PaymentActionsIcon payment={p} />
              </div>
            )
          }
        },

        {
          value: (p) => {
            return (
              <div className='flex items-center justify-end gap-8'>
                {(canPaymentsManage ||
                  canPenaltyPaymentsCreate ||
                  canPenaltyPaymentsDelete ||
                  canPaymentsEditTransactionsDate) && (
                  <Dropdown className='empty:hidden'>
                    <PaymentActions
                      payment={p}
                      hasPenalties={Boolean(p?.penaltyPayments?.length)}
                      canPaymentsManage={canPaymentsManage}
                      canPenaltyCreate={canPenaltyPaymentsCreate}
                      canPenaltyDelete={canPenaltyPaymentsDelete}
                      canEditTransactionsDate={canPaymentsEditTransactionsDate}
                      canPayPayment={canPayPayment && p.id === canPayLeasingPaymentId}
                      triggerAction={async (type) => {
                        switch (type) {
                          case PaymentActionType.CancelPaymentTransactions:
                            await cancelPaymentTransactionsCallback(p)
                            break
                          case PaymentActionType.EditPaymentTransactions:
                            setEditTransactionsFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenPenaltyModal:
                            setPenaltyFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenDeletingEntityModal:
                            setDeletionFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.PayPenaltyPayment:
                            await payPenaltyPayment(p)
                            break
                          case PaymentActionType.PayPayment:
                            await payPayment(p)
                            break
                          case PaymentActionType.RecountPenalty:
                            await recountPenalty(p)
                            break
                        }
                      }}
                    />
                  </Dropdown>
                )}
              </div>
            )
          }
        }
      ]
      // rows: {
      //   className: (p) => {
      //     if (p.penaltyType === 'isOverdued') {
      //       return '!bg-red-200'
      //     } else if (p.penaltyType === 'partlyPaid') {
      //       return '!bg-yellow-50'
      //     }
      //     return ''
      //   }
      // }
    }),
    [
      leasingPayments,
      canPaymentsManage,
      canPenaltyPaymentsCreate,
      canPenaltyPaymentsDelete,
      canPaymentsEditTransactionsDate,
      canPayPayment,
      canPayLeasingPaymentId,
      cancelPaymentTransactionsCallback,
      payPayment,
      recountPenalty
    ]
  )

  const createSchedule = useCallback(
    throttle(1000, async () => {
      if (
        !deal ||
        !deal.amount ||
        !deal.durationMonths ||
        !deal.advancePaymentDate ||
        !(deal.advanceRate >= 0) ||
        !(deal.interestRate >= 0) ||
        !(deal.comissionRate >= 0)
      )
        return

      if (deal.insuranceKind === DealInsuranceKind.WithInsurance && !deal.insuranceAmount) return

      setDisableSchedule(true)
      try {
        const { data } = await scheduleByDealParams({
          variables: {
            dealParams: {
              amount: deal.amount / 100,
              advanceRate: deal.advanceRate,
              interestRate: deal.interestRate,
              durationMonths: deal.durationMonths,
              comissionRate: deal.comissionRate,
              insuranceRate: insuranceRate,
              vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
              advancePaymentDate: deal.advancePaymentDate
            }
          }
        })
        if (data) {
          await createPayments({
            variables: {
              input: {
                payments: data.scheduleByDealParams.map((s) => ({
                  amount: s.amount,
                  date: new Date(s.date),
                  kind: PaymentKind.Leasing,
                  targetId: dealId.toString(),
                  targetType: PaymentTargetType.Deal
                }))
              }
            }
          })
          await refetchLeasingPayments()
        }
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        setDisableSchedule(false)
      }
    }),
    [createPayments, deal, dealId, refetchLeasingPayments, scheduleByDealParams]
  )

  async function createBasePayments() {
    if (!deal || !dealCalculations || !deal.advancePaymentDate) return

    const newPayments: Array<PaymentAttributes> = []

    newPayments.push({
      amount: dealCalculations.advanceAmount,
      date: deal.advancePaymentDate,
      kind: PaymentKind.Advance,
      targetId: dealId.toString(),
      targetType: PaymentTargetType.Deal
    })

    newPayments.push({
      amount: dealCalculations.comissionAmount,
      date: deal.advancePaymentDate,
      kind: PaymentKind.Commission,
      targetId: dealId.toString(),
      targetType: PaymentTargetType.Deal
    })

    if (deal?.insuranceKind === DealInsuranceKind.WithInsurance) {
      newPayments.push({
        amount: deal.insuranceAmount / 100,
        date: deal.advancePaymentDate,
        kind: PaymentKind.Insurance,
        targetId: dealId.toString(),
        targetType: PaymentTargetType.Deal
      })
    }

    const d = new Date(deal.advancePaymentDate)
    d.setDate(d.getDate() - 3)
    d.setMonth(d.getMonth() + deal.durationMonths)

    newPayments.push({
      amount: 1000,
      date: new Date(d),
      kind: PaymentKind.Redemption,
      targetId: dealId.toString(),
      targetType: PaymentTargetType.Deal
    })

    setDisableBasePayments(true)
    try {
      await createPayments({ variables: { input: { payments: newPayments } } })
      await refetchBasePayments()
    } finally {
      setDisableBasePayments(false)
    }
  }

  if (!deal) return null

  return (
    <div className='flex flex-col gap-6'>
      {/* Если таблица платежей в процессе загрузки, или она загружена и имеет хотя бы один платеж, выводится таблица */}
      <Card>
        {basePaymentsLoading || basePayments.length ? (
          <>
            <CardHeader>
              <CardTitle>Платежи</CardTitle>
            </CardHeader>
            <div className='px-5 pb-5'>
              <GridView
                data={flatBasePayments}
                config={basePaymentsConfig}
                loading={basePaymentsLoading}
                error={basePaymentsError?.message}
              />
              {(canPaymentsManage || canServicePaymentsCreate) && (
                <AddButton className='mt-6' onClick={() => setBasePaymentFormOpen(true)}>
                  Добавить платеж
                </AddButton>
              )}
            </div>
          </>
        ) : (
          <div className='p-5'>
            {/* Если таблица платежей загружена, у нее нет платежей и сделка не в статусе "Подписание", выводится сообщение о невозможности создать платеж */}
            {!basePayments.length && deal?.status !== DealStatus.Signing ? (
              <button
                className='flex h-25 w-full items-center justify-center rounded-xl border-1 border-dashed border-grayscale-300 text-grayscale-250 disabled:text-grayscale-250'
                disabled={true}
                title='Создание платежей возможно на статусе «Активация»'
              >
                <WarningIcon className='mr-8' height='18px' width='18px' />
                <span>На данном этапе невозможно создать платежи</span>
              </button>
            ) : (
              <>
                {/* Если таблица платежей загружена, у нее нет платежей, сделка активирована, но недостаточно данных, выводится соответствующее сообщение */}
                {!readyPaymentsCreate ? (
                  <button
                    className='flex h-25 w-full items-center justify-center rounded-xl border-1 border-dashed border-grayscale-300 text-red-150'
                    disabled={true}
                    title='Невозможно создать платежи - заполните данные по сделке'
                  >
                    <WarningIcon className='mr-8' height='18px' width='18px' />
                    <span>Недостаточно данных для создания платежей</span>
                  </button>
                ) : (
                  // Все условия для создания аванса и т.д. выполнены
                  <AddButton onClick={createBasePayments} disable={disableBasePayments}>
                    Создать аванс, комиссию, страховку и выкупной
                  </AddButton>
                )}
              </>
            )}
          </div>
        )}
      </Card>

      <Card>
        {/* Если график в процессе загрузки, или она загружена и имеет хотя бы один платеж, выводится таблица */}
        {leasingPaymentsLoading || leasingPayments.length ? (
          <>
            <CardHeader>
              <CardTitle>График</CardTitle>
              {(deal.status === DealStatus.Signing ||
                (deal.status === DealStatus.Activation && canLeasingPaymentsGraphDeleteAfterActivation)) && (
                <CardMenu>
                  <CardIconButton onClick={() => setLeasingPaymentsDeletionFormOpen(true)}>
                    <DeleteIcon />
                  </CardIconButton>
                </CardMenu>
              )}
            </CardHeader>
            <div className='px-5 pb-5'>
              <GridView
                data={flatLeasingPayments}
                config={leasingPaymentsConfig}
                loading={leasingPaymentsLoading || penaltyCalculationLoading}
                error={leasingPaymentsError?.message}
              />
              {canPaymentsManage && (
                <AddButton className='mt-6' onClick={() => setLeasingPaymentFormOpen(true)}>
                  Добавить платеж
                </AddButton>
              )}
            </div>
          </>
        ) : (
          <div className='p-5'>
            {/* Если график загружен, у него нет платежей и сделка не в статусе "Подписание", выводится сообщение о невозможности создать график */}
            {!basePayments.length && deal?.status !== DealStatus.Signing ? (
              <button
                className='flex h-25 w-full items-center justify-center rounded-xl border-1 border-dashed border-grayscale-300 text-grayscale-250 disabled:text-grayscale-250'
                disabled={true}
                title='Создание графика возможно на статусе «Активация»'
              >
                <WarningIcon className='mr-8' height='18px' width='18px' />
                <span>На данном этапе невозможно создать график</span>
              </button>
            ) : (
              <>
                {/* Если график загружен, у него нет платежей, сделка активирована, но недостаточно данных, выводится соответствующее сообщение */}
                {!readyPaymentsCreate ? (
                  <button
                    className='flex h-25 w-full items-center justify-center rounded-xl border-1 border-dashed border-grayscale-300 text-red-150'
                    disabled={true}
                    title='Невозможно создать график - заполните данные по сделке'
                  >
                    <WarningIcon className='mr-8' height='18px' width='18px' />
                    <span>Недостаточно данных для создания графика</span>
                  </button>
                ) : (
                  // Все условия для создания графика выполнены
                  <AddButton onClick={createSchedule} disable={disableSchedule}>
                    Создать график
                  </AddButton>
                )}
              </>
            )}
          </div>
        )}
      </Card>

      <Modal open={basePaymentFormOpen} setOpen={setBasePaymentFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentForm
            dealId={dealId}
            onDone={() => {
              refetchBasePayments()
              setBasePaymentFormOpen(false)
            }}
            kinds={kinds}
          />
        </div>
      </Modal>
      <Modal open={leasingPaymentFormOpen} setOpen={setLeasingPaymentFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentForm
            dealId={dealId}
            onDone={() => {
              refetchLeasingPayments()
              setLeasingPaymentFormOpen(false)
            }}
          />
        </div>
      </Modal>
      <SuspenseModal open={!!penaltyFormOpenByPaymentId} setOpen={() => setPenaltyFormOpenByPaymentId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PenaltyForm
            paymentId={penaltyFormOpenByPaymentId as string}
            onDone={() => {
              refetchLeasingPayments()
              setPenaltyFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <Modal open={!!deletionFormOpenByPaymentId} setOpen={() => setDeletionFormOpenByPaymentId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление платежа'
            onDone={async () => {
              await deletePayment({ variables: { input: { id: deletionFormOpenByPaymentId as string } } })
              await Promise.allSettled([refetchBasePayments(), refetchLeasingPayments()])
              setDeletionFormOpenByPaymentId(undefined)
            }}
            onDismiss={() => setDeletionFormOpenByPaymentId(undefined)}
          >
            Вы действительно хотите удалить платеж?
          </ConfirmationForm>
        </div>
      </Modal>
      <Modal open={leasingPaymentsDeletionFormOpen} setOpen={setLeasingPaymentsDeletionFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление графика'
            onDone={async () => {
              setLeasingPaymentsDeletionFormOpen(false)
              await removeLeasingPayments()
            }}
            onDismiss={() => setLeasingPaymentsDeletionFormOpen(false)}
          >
            Вы действительно хотите удалить график?
          </ConfirmationForm>
        </div>
      </Modal>
      <SuspenseModal
        open={!!editTransactionsFormOpenByPaymentId}
        setOpen={() => setEditTransactionsFormOpenByPaymentId(undefined)}
      >
        <div className='z-10 rounded-xl bg-white-0'>
          <TransactionsDateForm
            paymentId={editTransactionsFormOpenByPaymentId as string}
            onDone={() => {
              refetchBasePayments()
              refetchLeasingPayments()
              setEditTransactionsFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Payments
