import { FC, useMemo, useRef, useState } from 'react'
import { ReactComponent as AddIcon } from '../../svg/icons/add.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as CheckmarkActualIcon } from '../../svg/icons/circleCheck.svg'
import {
  TaskConnection,
  TaskKind,
  TasksDocument,
  TasksQuery,
  TasksQueryVariables,
  TaskState,
  TaskTargetType,
  useDeleteTaskMutation,
  useDoneTaskMutation,
  usePendingTaskMutation,
  useTasksQuery
} from '../../graphql/schema'
import Modal from '../Modal'
import TaskForm from './TaskForm'
import TasksByDate from './TasksByDate'
import ConfirmationForm from '../ConfirmationForm'
import useScrollPagination from '../../hooks/useScrollPagination'
import useNodes from '../../hooks/useNodes'
import useCurrentUser from '../../hooks/useCurrentUser'
import { TASK_QUOTES } from '../../utils/constants'
import c from 'clsx'
import cache from '../../graphql/cache'
import useTasksUpdates from './hooks/useTasksUpdates'
import CurrentUserFilter from '../../containers/Applications/CurrentUserFilter'

const dateFormatter = new Intl.DateTimeFormat('ru-RU', {
  month: 'long',
  day: 'numeric'
})
const dateFormatterWithYear = new Intl.DateTimeFormat('ru-RU', {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
})

interface TasksProps {
  targetId?: number
  targetType?: TaskTargetType
  userId?: number
  managerId?: number
  main?: boolean
  childTargetId?: number
  childTargetType?: TaskTargetType
}

export interface Task {
  id: string
  body: string
  state: TaskState
  doneAt?: string
  doneByUserId?: number
  targetId?: number
  targetType?: TaskTargetType
  kind: TaskKind
  dueDate?: string | Date
  userId?: number
  isDueDateTimeSpecified: boolean
  isNotificationActivated: boolean
  authorId?: number
  originalTaskId?: string
  childTargetId?: number
  childTargetType?: TaskTargetType
}

function readTasksFromCache(variables: TasksQueryVariables): TasksQuery | null {
  return cache.readQuery<TasksQuery, TasksQueryVariables>({
    query: TasksDocument,
    variables
  })
}

function writeTasksCache(tasks: TaskConnection, variables: TasksQueryVariables): void {
  cache.writeQuery<TasksQuery, TasksQueryVariables>({
    query: TasksDocument,
    variables,
    data: {
      __typename: 'Query',
      tasks
    }
  })
}

const Tasks: FC<TasksProps> = ({ targetId, targetType, childTargetId, childTargetType, userId, managerId, main }) => {
  const [taskFormOpen, setTaskFormOpen] = useState(false)
  const [entity, setEntity] = useState<Task>()
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [deleteTaskId, setDeleteTaskId] = useState<string>()
  const [pendingFormOpen, setPendingFormOpen] = useState(false)
  const [pendingTaskId, setPendingTaskId] = useState<string>()
  const [isActiveCurrentUser, setIsActiveCurrentUser] = useState<boolean>(false)
  const [isActiveDone, setIsActiveDone] = useState<boolean>(false)
  const [quoteId] = useState(Math.floor(Math.random() * TASK_QUOTES.length))

  const currentUser = useCurrentUser()
  const currentDate = new Date()
  currentDate.setSeconds(0, 0)

  const skipping = !userId && !targetId && !targetType

  const { data, fetchMore, subscribeToMore, loading } = useTasksQuery({
    variables: {
      input: {
        userId,
        targetId,
        targetType,
        childTargetId,
        childTargetType,
        states: [TaskState.Pending]
      }
    },
    skip: skipping,
    fetchPolicy: 'cache-and-network'
  })
  const tasks = useNodes(data?.tasks?.edges)

  const [tasksGroupedByDate, hasOverdueTask, hasTodayTask] = useMemo(() => {
    let hasOverdueTask = false
    let hasTodayTask = false

    const tasksGrouped = tasks?.reduce(
      (acc, task) => {
        const lastTaskGroup = acc[acc.length - 1]

        if (lastTaskGroup && lastTaskGroup.date.setHours(0, 0, 0, 0) === new Date(task?.dueDate).setHours(0, 0, 0, 0)) {
          lastTaskGroup.data?.push(task)
        } else {
          acc.push({
            date: new Date(task?.dueDate),
            data: [task]
          })
        }

        if (!hasOverdueTask && new Date(task?.dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
          hasOverdueTask = true
        }
        if (!hasTodayTask && new Date(task?.dueDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
          hasTodayTask = true
        }

        return acc
      },
      [] as { date: Date; data?: typeof tasks }[]
    )

    if (main && !hasOverdueTask && !hasTodayTask) {
      tasksGrouped?.unshift({
        date: new Date()
      })
    }

    return [tasksGrouped, hasOverdueTask, hasTodayTask] as const
  }, [tasks, main])

  useTasksUpdates(subscribeToMore, userId, targetId, targetType)

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(fetchMore, data?.tasks?.pageInfo)

  const [deleteTask] = useDeleteTaskMutation()
  const [doneTask] = useDoneTaskMutation()
  const [pendingTask] = usePendingTaskMutation()

  function openDeleteModal(id: string): void {
    setDeleteTaskId(id)
    setDeleteFormOpen(true)
  }

  function openPendingModal(id: string): void {
    setPendingTaskId(id)
    setPendingFormOpen(true)
  }

  function remove() {
    if (!deleteTaskId) {
      return
    }

    return deleteTask({
      variables: {
        id: parseInt(deleteTaskId)
      }
    }).then(() => {
      const variables = {
        input: {
          userId,
          targetId,
          targetType,
          childTargetId,
          childTargetType
        }
      }
      writeTasksCache(
        {
          ...readTasksFromCache(variables)?.tasks,
          edges: (readTasksFromCache(variables)?.tasks?.edges || []).filter((edge) => edge?.node?.id !== deleteTaskId)
        } as TaskConnection,
        variables
      )
    })
  }

  async function done(task: Task) {
    if (!task.id) return

    await doneTask({
      variables: {
        id: parseInt(task.id)
      },
      optimisticResponse: {
        __typename: 'Mutation',
        done: {
          __typename: 'Task',
          id: task.id,
          state: TaskState.Done
        }
      }
    })
  }

  function pending() {
    if (!pendingTaskId) return

    return pendingTask({
      variables: {
        id: parseInt(pendingTaskId)
      },
      optimisticResponse: {
        __typename: 'Mutation',
        pending: {
          __typename: 'Task',
          id: pendingTaskId,
          state: TaskState.Pending
        }
      }
    })
  }

  const todayTasksRef = useRef<HTMLDivElement>(null)
  const goTodayTasks = () => {
    if (todayTasksRef.current) {
      todayTasksRef.current.focus()
      todayTasksRef.current.scrollIntoView()
      setTimeout(() => todayTasksRef?.current?.blur(), 1000)
    }
  }

  return (
    <div className='rounded-xl bg-surface-secondary p-5 pt-4' ref={triggerRef}>
      <div className='mb-4 flex h-14 items-center'>
        <h2 className='mr-auto pl-3 text-title-m font-semibold'>
          Задачи
          {main && hasOverdueTask && hasTodayTask && (
            // TODO: может возникать ситуация, когда старых задач больше 30,
            //  тогда эта кнопка не отрендерится и придется рекрсивно подгружать следующие 30 задач и так далее,
            //  пока не догрузим задачи с датой на сегодня или позднее.
            //  Решили пока с этим ничего не делать, так как ситуация маловероятна
            <span className='ml-10 cursor-pointer text-p100 text-red-50' onClick={goTodayTasks}>
              На сегодня
            </span>
          )}
        </h2>
      </div>
      <div className='rounded-lg bg-surface-primary shadow-card'>
        <div className='flex items-center px-6'>
          <CurrentUserFilter
            userId={isActiveCurrentUser ? currentUser?._id : undefined}
            onChange={async () => {
              if (loading) return

              await fetchMore({
                variables: {
                  input: {
                    userId: isActiveCurrentUser ? userId : currentUser?._id,
                    authorId: isActiveCurrentUser ? undefined : currentUser?._id,
                    targetId,
                    targetType,
                    childTargetId,
                    childTargetType,
                    states: isActiveDone ? [TaskState.Done] : [TaskState.Pending]
                  }
                }
              })
              setIsActiveCurrentUser((value) => !value)
            }}
          />
          <span className='mx-6 h-22 w-1 border-r-1 border-grayscale-400' />
          <button
            className='cursor-pointer'
            onClick={async () => {
              if (loading) return

              await fetchMore({
                variables: {
                  input: {
                    userId: isActiveCurrentUser ? currentUser?._id : userId,
                    authorId: isActiveCurrentUser ? currentUser?._id : undefined,
                    targetId,
                    targetType,
                    childTargetId,
                    childTargetType,
                    states: isActiveDone ? [TaskState.Pending] : [TaskState.Done]
                  }
                }
              })
              setIsActiveDone((value) => !value)
            }}
          >
            <CheckmarkActualIcon className={c(isActiveDone ? 'text-green-600' : 'text-grayscale-250')} />
          </button>
          <span className='mx-6 h-22 w-1 border-r-1 border-grayscale-400' />
          <button
            className='flex cursor-pointer items-center gap-4 text-grayscale-200 hover:text-red-100'
            onClick={() => {
              setEntity(undefined)
              setTaskFormOpen(true)
            }}
          >
            <AddIcon title='Новая задача' />
            Новая задача
          </button>
        </div>

        {!!tasksGroupedByDate?.length &&
          tasksGroupedByDate.map((tasksByDate) => (
            <div
              key={tasksByDate.date.getTime()}
              tabIndex={tasksByDate.date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ? 0 : undefined}
              ref={tasksByDate.date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ? todayTasksRef : null}
              className='outline-none focus:ring-1 focus:ring-red-100'
              onClick={(event) => event.currentTarget.blur()}
            >
              <div
                className={c(
                  'border-y-1 border-grayscale-400 bg-grayscale-450 px-10 py-4 text-p100 text-grayscale-200',
                  tasksByDate.date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) && '!text-red-100'
                )}
              >
                {(() => {
                  const today = new Date()

                  const tomorrow = new Date()
                  tomorrow.setDate(tomorrow.getDate() + 1)

                  if (tasksByDate.date.getFullYear() !== today.getFullYear()) {
                    return dateFormatterWithYear.format(tasksByDate.date)
                  } else if (tasksByDate.date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
                    return `Сегодня, ${dateFormatter.format(tasksByDate.date)}`
                  } else if (tasksByDate.date.setHours(0, 0, 0, 0) === tomorrow.setHours(0, 0, 0, 0)) {
                    return `Завтра, ${dateFormatter.format(tasksByDate.date)}`
                  } else {
                    return dateFormatter.format(tasksByDate.date)
                  }
                })()}
              </div>

              {loading || skipping ? (
                <div className='p-10 text-center'>
                  <LoadingIcon className='mx-auto animate-spin self-center text-grayscale-200' />
                </div>
              ) : tasksByDate.data?.length ? (
                <TasksByDate
                  tasks={tasksByDate.data}
                  onDelete={(id) => openDeleteModal(id)}
                  onPending={(id) => openPendingModal(id)}
                  onDone={(task) => done(task)}
                  onEdit={(task) => {
                    setEntity(task)
                    setTaskFormOpen(true)
                  }}
                  main={main}
                />
              ) : (
                <div className='p-10 text-center'>{TASK_QUOTES[quoteId]}</div>
              )}
            </div>
          ))}
        <div className='flex items-center justify-center py-10 empty:hidden'>
          {isFetching && <LoadingIcon className='animate-spin self-center text-grayscale-200' />}
        </div>
      </div>

      <Modal open={taskFormOpen} setOpen={setTaskFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <TaskForm
            targetId={targetId}
            targetType={targetType}
            childTargetId={childTargetId}
            childTargetType={childTargetType}
            onCreateTask={(task) => {
              setTaskFormOpen(false)
              setIsActiveCurrentUser(false)
              setIsActiveDone(false)

              const variables = {
                input: {
                  userId,
                  targetId,
                  targetType,
                  childTargetId,
                  childTargetType,
                  states: [TaskState.Pending]
                }
              }
              const existTaskEdge = (readTasksFromCache(variables)?.tasks?.edges || []).find(
                (edge) => edge?.node?.id === task?.id
              )
              if (existTaskEdge || (main && userId !== task?.userId)) return

              writeTasksCache(
                {
                  ...readTasksFromCache(variables)?.tasks,
                  edges: [...(readTasksFromCache(variables)?.tasks?.edges || []), { cursor: '', node: task }].sort(
                    (a, b) => (new Date(a?.node?.dueDate) > new Date(b?.node?.dueDate) ? 1 : -1)
                  )
                } as TaskConnection,
                variables
              )
            }}
            onMoveTask={(task) => {
              setTaskFormOpen(false)

              const variables = {
                input: {
                  userId,
                  targetId,
                  targetType,
                  childTargetId,
                  childTargetType,
                  states: [TaskState.Pending]
                }
              }
              writeTasksCache(
                {
                  ...readTasksFromCache(variables)?.tasks,
                  edges: (readTasksFromCache(variables)?.tasks?.edges || [])
                    .map((edge) =>
                      edge?.node?.id === task?.originalTaskId ? { ...edge, node: { ...edge?.node, ...task } } : edge
                    )
                    .sort((a, b) => (new Date(a?.node?.dueDate) > new Date(b?.node?.dueDate) ? 1 : -1))
                } as TaskConnection,
                variables
              )
            }}
            onUpdateTask={() => {
              setTaskFormOpen(false)
            }}
            entity={entity}
            managerId={managerId}
          />
        </div>
      </Modal>

      <Modal open={deleteFormOpen} setOpen={setDeleteFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление задачи'
            onDone={() => {
              setDeleteFormOpen(false)
              remove()
            }}
            onDismiss={() => setDeleteFormOpen(false)}
          >
            Вы действительно хотите удалить задачу?
          </ConfirmationForm>
        </div>
      </Modal>

      <Modal open={pendingFormOpen} setOpen={setPendingFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            onDone={() => {
              setPendingFormOpen(false)
              pending()
            }}
            onDismiss={() => setPendingFormOpen(false)}
          >
            Вы действительно хотите вернуть задачу в работу?
          </ConfirmationForm>
        </div>
      </Modal>
    </div>
  )
}

export default Tasks
