import { useSwitcherState, StateDefinition, StateChecker } from '../../hooks/useSwitcherState'
import { createQueryChecker, createObservableChecker } from '../../utils/checkQuery'
import {
  DealStatus,
  RetroBonusKind,
  DealStatusSelectQuery,
  DealStatusSelectQueryVariables,
  DealStatusSelectDocument,
  DealFundingSourceIdsQuery,
  DealFundingSourceIdsQueryVariables,
  DealFundingSourceIdsDocument,
  DealGuarantorsQuery,
  DealGuarantorsQueryVariables,
  DealGuarantorsDocument,
  DealContactsQuery,
  DealContactsQueryVariables,
  DealContactsDocument,
  DealRetrobonusesCountDocument,
  DealRetrobonusesCountQuery,
  DealRetrobonusesCountQueryVariables,
  DocumentsQuery,
  DocumentsQueryVariables,
  DocumentsDocument,
  DocumentTypeEntity,
  PaymentsQuery,
  PaymentsQueryVariables,
  PaymentsDocument,
  DealInsuranceKind,
  PledgeKind,
  PaymentKind,
  PaymentTargetType,
  PaymentState,
  GuarantorStatus
} from '../../graphql/schema'
import { ApolloClient, QueryResult, useApolloClient } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { BehaviorSubject, of, combineLatest, map, from, Observable } from 'rxjs'
import useAccessCheck from '../../hooks/useAccessCheck'
import { dealStatusDict } from '../../utils/dictionaries'

const LIST_STATUSES = Object.keys(dealStatusDict) as DealStatus[]
const isRollback = (currentState: DealStatus, nextState: DealStatus) => {
  return LIST_STATUSES.indexOf(nextState) < LIST_STATUSES.indexOf(currentState)
}

interface DealSwitcherStateContext {
  dealId: string
  canRollback: boolean
  client: ApolloClient<unknown>
}

const watchDeal = ({ client, dealId }: DealSwitcherStateContext) => {
  return client.watchQuery<DealStatusSelectQuery, DealStatusSelectQueryVariables>({
    query: DealStatusSelectDocument,
    variables: { id: dealId }
  })
}

const watchRetrobonusesCount = ({ client, dealId }: DealSwitcherStateContext) => {
  return client.watchQuery<DealRetrobonusesCountQuery, DealRetrobonusesCountQueryVariables>({
    query: DealRetrobonusesCountDocument,
    variables: { deal: dealId }
  })
}

// TODO: сделать удобную обёртку для использования нескольких запросов в одной проверке
// принимать на входе массив observableQuery и функцию-маппер для результата
// состояния loading и error обрабатывать автоматически
const watchDealAndRetrobonuses = (context: DealSwitcherStateContext) => {
  const deal$ = from(watchDeal(context)) as Observable<QueryResult<DealStatusSelectQuery>>
  const retrobonus$ = from(watchRetrobonusesCount(context)) as Observable<QueryResult<DealRetrobonusesCountQuery>>

  const result = combineLatest([deal$, retrobonus$]).pipe(
    map(([dealResult, retrobonusResult]) => {
      return {
        data: {
          retrobonusesCount: retrobonusResult?.data?.dealRetrobonuses?.totalCount,
          retrobonusKind: dealResult?.data?.deal?.retroBonusKind
        },
        loading: dealResult.loading || retrobonusResult.loading,
        error: dealResult.error || retrobonusResult.error
      }
    })
  )

  return result
}

const watchDealFundingSources = ({ client, dealId }: DealSwitcherStateContext) => {
  return client.watchQuery<DealFundingSourceIdsQuery, DealFundingSourceIdsQueryVariables>({
    query: DealFundingSourceIdsDocument,
    variables: { dealId }
  })
}

const watchDealGuarantors = ({ client, dealId }: DealSwitcherStateContext) => {
  return client.watchQuery<DealGuarantorsQuery, DealGuarantorsQueryVariables>({
    query: DealGuarantorsDocument,
    variables: { id: dealId }
  })
}

const watchDealContacts = ({ client, dealId }: DealSwitcherStateContext) => {
  return client.watchQuery<DealContactsQuery, DealContactsQueryVariables>({
    query: DealContactsDocument,
    variables: { dealId: parseInt(dealId) }
  })
}

const watchContractDocuments = ({ client, dealId }: DealSwitcherStateContext) => {
  return client.watchQuery<DocumentsQuery, DocumentsQueryVariables>({
    query: DocumentsDocument,
    variables: {
      filter: {
        entityId: `${dealId}`,
        entityType: DocumentTypeEntity.Deal,
        type: 'Договор лизинга'
      }
    }
  })
}

const watchBasePayments = ({ client, dealId }: DealSwitcherStateContext) => {
  return client.watchQuery<PaymentsQuery, PaymentsQueryVariables>({
    query: PaymentsDocument,
    variables: {
      kinds: [
        PaymentKind.Advance,
        PaymentKind.Commission,
        PaymentKind.Redemption,
        PaymentKind.Insurance,
        PaymentKind.Service,
        PaymentKind.Compensation,
        PaymentKind.DebtTransferService
      ],
      targetType: PaymentTargetType.Deal,
      targetId: dealId
    }
  })
}

const watchMinimalBasePayments = ({ client, dealId }: DealSwitcherStateContext) => {
  return client.watchQuery<PaymentsQuery, PaymentsQueryVariables>({
    query: PaymentsDocument,
    variables: {
      kinds: [PaymentKind.Advance, PaymentKind.Commission, PaymentKind.Insurance],
      targetType: PaymentTargetType.Deal,
      targetId: dealId
    }
  })
}

const watchLeasingPayments = ({ client, dealId }: DealSwitcherStateContext) => {
  return client.watchQuery<PaymentsQuery, PaymentsQueryVariables>({
    query: PaymentsDocument,
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: dealId
    }
  })
}

const hasRetrobonus = createObservableChecker(watchDealAndRetrobonuses, ({ retrobonusKind, retrobonusesCount }) => {
  // тип ретробонуса должен быть указан
  if (!retrobonusKind) return { passed: false, message: 'Указать, есть ли ретробонус' }
  // если тип "с ретробонусом", то должен быть указан хотя бы один ретробонус
  if (retrobonusKind === RetroBonusKind.WithRetrobonus && !retrobonusesCount)
    return { passed: false, message: 'Указать ретробонус' }
  return { passed: true }
})

const hasFeraBankAccount = createQueryChecker(watchDeal, (data) => {
  if (data?.deal?.feraBankAccountId) return { passed: true }
  return { passed: false, message: 'Указать реквизиты Fera' }
})

const hasFundingSources = createQueryChecker(watchDealFundingSources, (data) => {
  if (data?.dealFundingSources?.edges?.length) return { passed: true }
  return { passed: false, message: 'Указать источник финансирования' }
})

const signedGuarantee = createQueryChecker(watchDealGuarantors, ({ deal }) => {
  // если нет поручителей, то не нужно подписывать поручительства
  if (deal?.guarantors?.edges?.length) return { passed: true }
  // если есть поручители, то нужно проверить, что все поручительства подписаны
  if (deal?.guarantors?.edges?.every((edge) => edge?.node?.status === GuarantorStatus.Signed)) return { passed: true }
  return { passed: false, message: 'Подписать поручительство' }
})

const hasContactForNotifications = createQueryChecker(watchDealContacts, (data) => {
  if (data?.dealContacts?.length) return { passed: true }
  return { passed: false, message: 'Добавить контакт для уведомлений' }
})

const hasContractDocument = createQueryChecker(watchContractDocuments, (data) => {
  if (data?.documents?.edges?.length) return { passed: true }
  return { passed: false, message: 'Подгрузить договор лизинга' }
})

const hasInsurance = createQueryChecker(watchDeal, ({ deal }) => {
  // сначала нужно указать наличие страховки
  if (!deal?.insuranceKind) return { passed: false, message: 'Заполнить, нужна ли страховка' }
  // если страховка не требуется, то проверка пройдена
  if (deal?.insuranceKind === DealInsuranceKind.WithoutInsurance) return { passed: true }
  // если страховка требуется, то нужно указать номер и дату полиса
  if (!deal?.insuranceContract || !deal?.insuranceContractDate)
    return { passed: false, message: 'Указать номер и дату полиса страхования' }

  return { passed: true }
})

const hasPledge = createQueryChecker(watchDeal, ({ deal }) => {
  // сначала нужно указать наличие залога
  if (!deal?.pledgeKind) return { passed: false, message: 'Заполнить информацию о залоге' }
  // если залог не требуется, то проверка пройдена
  if (deal?.pledgeKind === PledgeKind.WithoutPledge) return { passed: true }
  // если залог требуется, то нужно указать залогодержателя
  if (!deal?.pledgeHolder) return { passed: false, message: 'Указать залогодержателя' }

  return { passed: true }
})

const hasBasePayments = createQueryChecker(watchBasePayments, ({ payments }) => {
  if (payments?.edges?.length) return { passed: true }
  return { passed: false, message: 'Создать платежи' }
})

const hasMinimalBasePaymentsPaid = createQueryChecker(watchMinimalBasePayments, ({ payments }) => {
  if (payments?.edges?.length && payments?.edges?.every((p) => p?.node?.state === PaymentState.Paid))
    return { passed: true }
  return { passed: false, message: 'Провести оплату' }
})

const hasLeasingPayments = createQueryChecker(watchLeasingPayments, ({ payments }) => {
  if (payments?.edges?.length) return { passed: true }
  return { passed: false, message: 'Создать график платежей' }
})

// проверяет, можно ли вернуться в предыдущее состояние
const canRollback: StateChecker<DealSwitcherStateContext, DealStatus> = ({ canRollback }, currentState, nextState) => {
  // если не передано состояние, то проверка не пройдена
  if (!currentState) return of({ passed: false })
  // если переход является откатом, то проверка пройдена, если есть право на откат
  if (isRollback(currentState, nextState)) return of({ passed: canRollback })
  // если переход не является откатом, то проверка пройдена
  return of({ passed: true })
}

const dealStateDefinitions: StateDefinition<DealStatus, DealSwitcherStateContext>[] = [
  {
    state: DealStatus.Preparing,
    nextStates: [DealStatus.Signing, DealStatus.Cancelled],
    checks: [canRollback]
  },
  {
    state: DealStatus.Signing,
    nextStates: [DealStatus.Preparing, DealStatus.Activation, DealStatus.Cancelled, DealStatus.Terminated],
    checks: [canRollback, hasRetrobonus, hasFeraBankAccount, hasFundingSources]
  },
  {
    state: DealStatus.Activation,
    nextStates: [DealStatus.Signing, DealStatus.PaymentReception, DealStatus.Terminated],
    checks: [
      canRollback,
      signedGuarantee,
      hasContactForNotifications,
      hasContractDocument,
      hasInsurance,
      hasPledge,
      hasBasePayments,
      hasLeasingPayments
    ]
  },
  {
    state: DealStatus.PaymentReception,
    nextStates: [DealStatus.Closed, DealStatus.Terminated],
    checks: [canRollback, hasMinimalBasePaymentsPaid, hasRetrobonus]
  },
  {
    state: DealStatus.Closed,
    nextStates: [DealStatus.PaymentReception]
  },
  {
    state: DealStatus.Cancelled,
    nextStates: []
  },
  {
    state: DealStatus.Terminated,
    nextStates: []
  }
]

export function useDealSwitcherState(dealId: string, dealStatus: DealStatus | undefined) {
  const client = useApolloClient()
  const canRollback = useAccessCheck('deal.status.rollback')

  const context$ = useRef(
    new BehaviorSubject<DealSwitcherStateContext>({
      client,
      dealId,
      canRollback
    })
  ).current

  useEffect(() => {
    context$.next({
      client,
      dealId,
      canRollback
    })
  }, [client, dealId, canRollback, context$])

  return useSwitcherState(dealStatus, dealStateDefinitions, context$)
}
