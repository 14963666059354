import { useCallback, useState } from 'react'
import { useClaimScheduleLazyQuery } from '../../../../graphql/schema.tsx'
import { dateCell, emptyCell, moneyCell, renderSheet, saveSheet, textCell } from '../../../../utils/XLSX.ts'
import { dateFormatter } from '../../../../utils/dateFormatter.ts'
import { Claim } from '../CollectionForm.tsx'

const useClaimSchedule = (dealId: number) => {
  const [claimScheduleQuery] = useClaimScheduleLazyQuery()
  const [saveClaimScheduleLoading, setSaveClaimScheduleLoading] = useState(false)

  const getClaimSchedule = useCallback(
    async (date: string) => {
      const { data: claimScheduleData } = await claimScheduleQuery({
        variables: { dealId: `${dealId}`, claimDate: new Date(date).toISOString().split('T').shift() }
      })
      return claimScheduleData?.claimSchedule
    },
    [dealId]
  )

  const saveClaimSchedule = useCallback(
    async (contractNumber: string, pretensionDate: string) => {
      setSaveClaimScheduleLoading(true)
      try {
        const claimSchedule = (await getClaimSchedule(pretensionDate)) || []
        const totalAmount = claimSchedule.reduce(
          (acc, claim, index) => (claimSchedule[index - 1]?.number === claim.number ? acc : acc + (claim?.amount || 0)),
          0
        )
        const totalPaidAmount = claimSchedule.reduce((acc, claim) => acc + (claim?.paidAmount || 0), 0)
        const diffAmount = totalAmount - totalPaidAmount
        const totalPenaltyAmount = claimSchedule.reduce((acc, claim) => acc + (claim?.penaltyAmount || 0), 0)
        const totalPaidPenaltyAmount = claimSchedule.reduce((acc, claim) => acc + (claim?.paidPenaltyAmount || 0), 0)
        const diffPenaltyAmount = totalPenaltyAmount - totalPaidPenaltyAmount

        const data: Claim[] = [
          ...claimSchedule,
          {
            number: 'Итого начислено',
            amount: totalAmount,
            penaltyAmount: totalPenaltyAmount
          },
          {
            number: 'Итого уплачено',
            paidAmount: totalPaidAmount,
            paidPenaltyAmount: totalPaidPenaltyAmount
          },
          {
            number: 'Сальдо',
            amount: diffAmount,
            penaltyAmount: diffPenaltyAmount
          }
        ]
        const ws = renderSheet({
          data,
          columns: [
            {
              title: '№ платежа',
              width: 10,
              render: (c, index) =>
                data[index - 1]?.number === c.number ? emptyCell : textCell(c.number === 0 ? 'Аванс' : `${c.number}`)
            },
            { title: 'Дата платежа', width: 12, render: (c) => dateCell(c.date) },
            {
              title: 'Сумма платежа, руб.',
              width: 15,
              render: (c) => (typeof c.amount === 'number' ? moneyCell(c.amount) : emptyCell)
            },
            {
              title: 'Сумма уплаты, руб.',
              width: 15,
              render: (c) => (typeof c.paidAmount === 'number' ? moneyCell(c.paidAmount) : emptyCell)
            },

            {
              title: 'Дата начала просрочки',
              width: 15,
              render: (c) => (c?.overdueFrom ? dateCell(c.overdueFrom) : emptyCell)
            },
            {
              title: 'Дата окончания просрочки / дата справки',
              width: 15,
              render: (c) =>
                c?.overdueEnd ? dateCell(c.overdueEnd) : c?.overdueDays ? dateCell(pretensionDate) : emptyCell
            },
            {
              title: 'Количество дней просрочки',
              width: 15,
              render: (c) => (c?.overdueDays ? textCell(`${c.overdueDays}`) : emptyCell)
            },
            {
              title: 'Ставка, %',
              width: 10,
              render: (c) => (typeof c.number === 'number' ? textCell('1%') : emptyCell)
            },
            {
              title: 'Сумма процентов, руб.',
              width: 15,
              render: (c) => (typeof c.penaltyAmount === 'number' ? moneyCell(c.penaltyAmount) : emptyCell)
            },
            {
              title: 'Уплаченная сумма процентов, руб.',
              width: 15,
              render: (c) => (typeof c.paidPenaltyAmount === 'number' ? moneyCell(c.paidPenaltyAmount) : emptyCell)
            }
          ],
          headerRows: [
            [
              'Лизинговые (в т.ч. авансовые) платежи',
              '',
              '',
              '',
              'Проценты за просрочку уплаты платежей',
              '',
              '',
              '',
              '',
              ''
            ]
          ]
        })

        saveSheet(
          ws,
          `Платежи по ДЛ${contractNumber} от ${dateFormatter.format(new Date(pretensionDate))}.xlsx`,
          'Таблица с платежами'
        )
      } catch (e) {
        console.error(e)
      } finally {
        setSaveClaimScheduleLoading(false)
      }
    },
    [getClaimSchedule]
  )

  return { getClaimSchedule, saveClaimSchedule, saveClaimScheduleLoading } as const
}

export default useClaimSchedule
